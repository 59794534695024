@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
  min-width: 100px;
  margin: 0;
}
.root button {
  width: 100%;
  border-radius: 5px;
  padding: 6px 24px 6px 13px;
  background: #34b06a;
  color: #fff;
  border: 0;
  font-weight: 600;
  box-shadow: -2px 6px 5px rgba(255, 255, 255, 0.3) inset;
  position: relative;
  text-align: left;
  &:after {
    content: '\f0d7';
    font-family: 'FontAwesome';
    position: absolute;
    font-size: 15px;
    top: 6px;
    right: 7px;
  }
}
.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  position: relative;

  &:hover {
    background: #4cb1b9;
  }
  &:focus {
    outline: none;
    background: #4cb1b9;
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  /* &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  } */
}
.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  min-width: 300px;
  margin-top: 7px;
  background-color: var(--matterColorLight);

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}

.popupSize {
  padding: 15px 30px 17px 30px;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  z-index: 3;
}
