@import '../../styles/customMediaQueries.css';

.root {
}

.tabs {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-end;
    height: 55px;
    align-items: flex-end;
    padding: 13px 24px 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}
.modaldiv {
  background: #fff;
  padding: 20px 0px 0;
  /* border-radius: 30px; */
  /* max-height: 700px; */
  overflow-y: auto;
  /* width: 500px; */
  @media (max-width: 767px) {
    margin-top: 60px;
    padding: 0 10px;
  }
  @media (max-width: 767px) {
    padding: 0;
    margin-top: 30px;
  }
}
.tsns {
  overflow-x: auto;
  margin: auto;
  max-width: 1440px;
  @media (max-width: 1919px) {
    max-width: 1440px;
  }
  @media (max-width: 1439px) {
    max-width: 1200px;
  }
  @media (max-width: 1199px) {
    max-width: 1024px;
  }
  @media (max-width: 1023px) {
    max-width: 992px;
  }
  @media (max-width: 991px) {
    max-width: 668px;
  }
  @media (max-width: 576px) {
    max-width: 320px;
  }
}

.tsns tbody {
  display: block;
  overflow: auto;
  /* max-height: 250px; */
  width: 100%;
}

.smcrd {
  max-width: 471px;
  background: #fff;
  margin: 0 auto;
  border: 2px solid #000;
  border-radius: 7px;
  position: relative;
  /* @media (max-width: 767px) {
    display: none;
  } */
}
.smcrd > div:first-child > div:first-child {
  display: none;
}
.smcrd > div:first-child {
}
.smcrd > div:last-child {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.mmdiv {
  border-radius: 30px;
  background: #fff;
  @media (max-width: 767px) {
    border-radius: 0px;
    padding: 0px;
    background: #fff;
  }
}
.bktbl {
  margin: auto;
}
.bktbl thead th:first-child,
th:nth-child(7),
th:nth-child(9),
th:nth-child(10) {
  width: 150px;
  max-width: 150px;
}
.bktbl thead th:nth-child(2),
th:nth-child(4) {
  width: 115px;
  max-width: 115px;
}
.bktbl thead th:nth-child(3),
th:nth-child(5) {
  width: 100px;
  max-width: 100px;
}
.bktbl thead th:nth-child(8),
th:nth-child(6) {
  width: 330px;
  max-width: 330px;
}
.bktbl tbody td:first-child,
.bktbl tbody td:nth-child(7),
.bktbl tbody td:nth-child(9),
.bktbl tbody td:nth-child(10) {
  width: 150px;
  max-width: 150px;
}
.bktbl tbody td:nth-child(2),
.bktbl tbody td:nth-child(4) {
  width: 115px;
  max-width: 115px;
}
.bktbl tbody td:nth-child(3),
.bktbl tbody td:nth-child(5) {
  width: 100px;
  max-width: 100px;
}
.bktbl tbody td:nth-child(6),
.bktbl tbody td:nth-child(8) {
  width: 330px;
  max-width: 330px;
}
/* .bktbl thead th, */
.bktbl tr {
  display: flex;
}
.bktbl tr:nth-child(even) {
  background-color: #ebebeb;
}
.bktbl thead th,
.bktbl tbody td {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  word-break: break-all;
  text-align: center;
  line-height: 22px;
  padding: 10px;
}
.bktbl tbody td {
  font-size: 15px;
  color: #000;
  font-weight: 500;
  & u {
    color: #979393;
  }
  @media (max-width: 575px) {
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.bktbl thead tr {
  background-color: #002435;
  color: #fff;
  & th {
    align-items: flex-start;
    word-break: break-word;
  }
}
.emgspn {
  display: flex;
  flex-direction: column;
  & span:nth-child(2) {
    color: #979393;
    text-decoration: underline;
  }
}
.waitingTable {
  min-width: 70vw;
}
.waitingTable thead tr,
.waitingTable tbody tr {
  justify-content: space-evenly;
}
.waitingTable thead th,
.waitingTable tbody td {
  width: 18% !important;
  max-width: unset !important;
}
.waitingTable thead th:last-child,
.waitingTable tbody td:last-child {
  width: 40% !important;
}
.btndv {
  background: #002435;
  padding: 10px;
  display: flex;
  width: 100%;
}
.btndv button {
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  color: #fff;
  border-radius: 8px;
  border: 0;
  font-size: 15px;
  padding: 2px 30px;
  cursor: pointer;
  margin: 0 10px;
  &:hover {
    background: linear-gradient(270deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  }
}
.cstmrow {
  background-color: #002435;
  justify-content: flex-start;
}
.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}
.tabsdiv {
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    justify-content: flex-start;
    max-width: calc(100vw - 20px);
    overflow: auto;
  }
}
.activeWrapper {
  border: 3px solid transparent !important;
}
.btnwrapper {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  margin: 2px 10px;
  border-radius: 5px;
  border: 2px solid #002435;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.btnwrapper button {
  background: #fff;
  color: #002435;
  border-radius: 5px;
  border: 0;
  padding: 2px 15px;
  cursor: pointer;
  white-space: nowrap;
  @media (max-width: 767px) {
    padding: 8px 15px;
  }
}
.listingPanel {
  width: 100%;
  margin: 0px auto 0 auto;
  padding-bottom: 40px;
}
.wlmsg {
  text-align: center;
  margin: 10px 0;
  font-family: 'Stardos Stencil', cursive;
}
.title {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 24px 48px 24px;
  }

  @media (--viewportLarge) {
    margin: 0 36px 48px 36px;
  }
}

.listingCards {
  padding: 0 24px 96px 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}
.lhead {
  margin: 35px 150px 7px;
  font-size: 26px;
  font-weight: 700;
  color: #c93b54;
  display: flex;
  align-items: center;
  @media (max-width: 575px) {
    margin: 35px auto 7px;
    text-align: center;
  }
}
.lhead svg {
  margin-left: 15px;
}
.errorspn {
  margin-left: 150px;
  font-weight: 700;
  color: red;
}
.listingCard {
  margin-bottom: 36px;
  flex-basis: 100%;
  max-width: 450px;
  width: 450px;
  margin: 0 auto;
  @media (max-width: 575px) {
    max-width: 450px;
    width: 60%;
  }
  @media (max-width: 475px) {
    max-width: 450px;
    width: 80%;
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

/* .listingCard:nth-of-type(2n) {
  @media screen and (min-width: 768px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
} */

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin: auto 24px 0 24px;

  @media (--viewportLarge) {
    margin: auto 36px 0 36px;
  }
}
.publishedListings {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.publishedListing {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
}
.draftListings {
  display: grid;
  gap: 20px;
  @media (min-width: 1920px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1920px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 475px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.listType {
  color: #c93b54;
  font-size: 19px;
  font-weight: 600;
  text-align: center;
  @media (max-width: 575px) {
    font-size: 17px;
  }
}
.noListings {
  margin: 35px 150px 7px;
  font-size: 18px;
  color: #000;
  font-weight: 600;
  @media (max-width: 575px) {
    margin: 35px auto 7px;
    text-align: center;
  }
}
