@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 38px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}
.additionalFee {
  display: flex;
  /* padding-top: 8px; */
  /* padding: 0 10px 0 30px; */
  border: 1.5px solid rgb(0 0 0 / 25%);
  /* background-color:transparent; */
  margin-bottom: 15px;
  border-radius: 10px;
  /* padding: 5px 10px; */
  align-items: center;
  /* & input {
    display: none;
  } */
  & input[type='checkbox'] {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
    min-width: 30px !important;
    border: 3px solid rgb(0 0 0 / 50%) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff !important;
    appearance: none !important;
    cursor: pointer;
    &:checked {
      background: rgb(146, 26, 152) !important;
      background: linear-gradient(
        198deg,
        rgba(146, 26, 152, 1) 0%,
        rgba(201, 58, 83, 1) 100%
      ) !important;
      /* border-color: transparent !important; */
      border: 0 !important;
      width: 30px;
      height: 30px;
      position: relative;
      &::after {
        content: '\f00c';
        font-family: 'Font Awesome 6 Free';
        font-weight: 900;
        color: #fff;
        font-size: 20px;
        position: absolute;
        top: 0;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  & > div {
    & > label {
      & > span {
        font-size: 17px;
        font-weight: 500;
        line-height: 24px;
        color: #000;
        & > span {
          padding-top: 6px;
          & span:last-child {
            color: rgb(35 35 35 / 50%);
          }
        }
      }
    }
  }

  &.erlydrpslct {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
    border-radius: 10px;
    border: 2px solid transparent;
  }
  &.latepckslct {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
    border-radius: 10px;
    border: 2px solid transparent;
  }
  & .latepickup {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #f2f1ef;
    padding: 5px 10px;
    border-radius: 10px;
  }
  & .earlyDropOff {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #f2f1ef;
    padding: 5px 10px;
    border-radius: 10px;
  }
}
.additionalFee .radiomainspn {
  width: 22px;
  height: 22px;
}
.additionalFee label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  margin: 0 0 4px;
  & span {
    cursor: pointer;
    & > span {
      display: block;
    }
  }
}
.chcdv {
  /* border-bottom: 2px solid #cecece; */
  padding-bottom: 10px;
  & p {
    margin: 0;
    line-height: 30px;
    font-weight: 700;
    color: #000;
    font-size: 15px;
    padding-left: 20px;
  }
}
.erldrpspn1 {
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  width: 30px;
  height: 30px;
}
.trllbl {
  cursor: pointer;
}
.standardError {
  margin: 0;
  color: red;
  font-size: 13px;
}
.priceBreakdownContainer {
  padding: 0 24px 10px;
  margin-top: 10px;
  margin-bottom: 24px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
  @media (max-width: 575px) {
    padding-right: 0;
    padding-left: 0;
  }
  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}
.priceBreakdownContainer > p {
  margin: 15px auto;
  font-weight: 700;
  color: #777777;
  line-height: 20px;
  font-size: 17px;
}
.useParentAccount {
  color: red;
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);
  font-size: 21px;
  color: #c93a53;
  text-align: center;
  font-weight: 700;
  line-height: 30px;
  margin-top: 15px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 15px;
    margin-bottom: 14px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}
.bkngoptin {
  display: none;
}
.child {
  margin: 20px 0;
}
.child h1 {
  font-size: 17px;
  margin: 0;
  line-height: 26px;
}
.child p {
  margin: 0;
}
.child > div {
  border: 2px solid black;
  padding: 20px;
}
.bookOtps {
  /* border-top: 1px solid #cecece; */
  /* padding: 10px; */
  border: 1.5px solid rgb(0 0 0 / 25%);
  margin-bottom: 15px;
  border-radius: 10px;
}
.actbookopt {
  /* border: 1px solid #c93b54; */
  background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  border-radius: 10px;
  border: 2px solid transparent;
}
.bookOtps label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f1ef;
  padding: 10px;
  border-radius: 10px;
}
.headspan {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #232323;
  font-weight: 600;
  & :nth-child(1) {
    margin-bottom: 2px;
  }

  & :nth-child(2) {
    color: #717171;
  }
}
.payoptmaindiv input {
  display: none;
}
.payoptmaindiv label {
  display: flex;
  justify-content: space-between;
  /* border: 2px solid #cecece; */
  min-height: 90px;
  /* margin: 4px 0; */
  align-items: center;
  padding: 10px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  display: inline-flex !important;
}
.payoptmaindiv {
  /* border: 1px solid #cecece; */
  /* padding: 10px; */
  margin: 10px 0 20px;
  & > p {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #232323 !important;
    text-align: left !important;
    & > span {
      color: #c93b54;
      margin-left: 4px;
      font-size: 17px;
    }
  }
}
.payoptmaindiv > p {
  margin: 30px 0 2px;
  text-align: center;
  font-size: 22px;
  color: #bebebe;
}
.selbl {
  /* border: 2px solid #c93b54 !important; */
  /* background: linear-gradient( 90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72% );
  border-radius: 10px;
  border: 2px solid transparent; */
  /* display: inline-block !important; */
}
.payoptlabel > span {
  display: flex;
  flex-direction: column;
  & > span {
    font-size: 15px;
    line-height: 22px;
    display: block;
  }
  & > span:first-child {
    color: #232323;
    /* text-decoration: underline; */
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
  & > span:last-child {
    color: #959595;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    padding-top: 5px;
  }
}
.depositOptionInfo {
  color: #4a4a4a;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  margin: 0 0 20px;
  text-align: center;
}
.limitReached {
  color: red !important;
  /* line-height: 16px; */
  /* font-size: 12px; */
  font-weight: 700;
}
.chldo {
  display: flex;
  flex-direction: column;
  text-align: left;
  & > span:nth-child(1) {
    font-size: 18px;
    line-height: 25px;
    color: #fff;
    font-weight: 600;
  }
  & > span:nth-child(2) {
    font-size: 16px;
    /* color: #4a4a4a; */
    color: #fff;

    /* & > span {
      color:#fff;
    } */
  }
  &:hover {
    background-color: g;
  }
}
.chldsctn {
  margin-bottom: 15px;
  width: 100%;
  /* margin-left: auto; */
  /* margin-right: auto; */
}
.chldoptn {
  display: flex !important;
  align-items: center;
}
.optnchk {
  flex-basis: 10%;
  width: 25px;
  height: 25px;
  margin-right: 16px;
  /* fill: #c93b54; */
}
.optnchk rect {
  stroke: #c93b54;
}
.selectedOption {
}
.addchildbtn {
  background-color: #002435;
  position: sticky;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  padding: 10px 0;
  transition: all 300ms ease-in-out;
  margin-bottom: 8px;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
  overflow: hidden;
  &:hover {
    background-color: rgb(217, 217, 217, 0.8);
    border-radius: 0;
  }
}
.addchld {
  border: none;
  background: transparent !important;
  width: 100%;
  /* padding: 10px 15px; */
  /* border-radius: 8px; */
  /* max-height: 35px; */
  /* display: flex; */
  display: inline-block;
  text-align: left;
  font-weight: 700;
  /* align-items: center; */
  background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  -webkit-text-fill-color: transparent !important;
  -webkit-background-clip: text !important;
  background-clip: text;
  color: transparent;
  cursor: pointer;
  font-size: 20px;
  margin-top: 0;
  font-weight: 600;
  background-color: #f2f2f2;
  /* justify-content: center; */
  /* position: sticky;
  top: 0; */
  &:hover {
    /* background:linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  -webkit-text-fill-color: transparent !important;
  -webkit-background-clip: text !important; */
  }
}
.addchld svg {
  /* fill: green; */
  margin-right: 8px;
}
.adChildTop {
  width: auto !important;
  margin: 30px auto 20px;
  font-weight: 700;
  & > span {
    font-size: 20px !important;
    font-weight: 700 !important;
  }
}
.ageButton {
  /* margin: 10px 10px; */
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #c93b54;
  min-width: 30px;
  padding: 0;
  @media (max-width: 1023px) {
    margin-top: 0 !important;
  }
  & svg {
    fill: #c93b54;
  }
}
.flexprice {
  text-align: center;
  display: flex;
  color: #000;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.mainPrice {
  display: flex;
  position: relative;
  margin: 0 20px;
  max-width: 120px;
  justify-content: center;
  align-items: center;
}
.mainPrice input {
  border: 2px solid #cecece !important;
  border-radius: 10px;
  min-height: 50px;
  padding: 8px 15px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.emgmain {
  & h2 {
    text-align: center;
    margin: 20px 0 0 0;
    border-bottom: 4px solid #c53b93;
    @media (max-width: 767px) {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      padding: 0 15px;
    }
  }
}
.ondiv {
  margin: 40px 0;
  & h3 {
    & span {
      font-weight: 400;
    }
  }
}
.emggntsdv {
  max-height: 348px;
  @media (max-width: 767px) {
    margin: 0 auto;
  }
}
.contdv {
  max-height: 320px;
  overflow: auto;
  margin: 10px 0;
}
.emggntsdv > label:first-child {
  margin-top: 20px;
  margin-bottom: 6px;
  font-size: 16px;
  text-align: center;
  color: #000;
  font-weight: 700;
  @media (max-width: 767px) {
    margin-top: 50px;
    margin-bottom: 15px;
    font-size: 17px;
  }
}
.emgcntlbl {
  display: flex;
  flex-direction: column;
  padding: 5px;
  border: 2px solid #cecece;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
}
.selemgcnt {
  border: 2px solid #c93b54;
}
.echemg {
  margin: 10px auto;
  width: 80%;
}
.echemg input {
  display: none;
}
.namediv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  & input {
    flex-basis: 45%;
    border: 2px solid #cecece;
    border-radius: 4px;
    padding-left: 4px;
  }
  @media (max-width: 575px) {
    flex-direction: column;
    gap: 10px;
  }
}
.phndiv {
  border: 2px solid #cecece;
  border-radius: 4px;
  padding-left: 4px;
}
.cntbtn {
  margin: 20px 0 0;
}
.subemgcnt {
  margin-top: 50px;
}

.chlddet {
  /* border: 1px solid #cecece; */
  background-color: #f2f1ef;
  border-radius: 10px !important;
  /* margin-left: auto;
  margin-right: auto; */
  & > div:first-child {
    /* border: 1px solid #cecece; */
    & p:first-child {
      /* text-align: center; */
      margin: 0px;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }
    & p {
      /* text-align: center; */
      margin: 0px;
      font-size: 17px;
      font-weight: 500;
      color: #232323;
    }
  }
  & .chldNameAge {
    /* padding-bottom: 10px; */
  }
}
.emgdet {
  /* background: #eee; */
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  border-radius: 10px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f2f1ef;
  padding: 15px;
  & > div {
    /* border: 1px solid #cecece; */
    & p:first-child {
      margin: 0px;
      font-size: 17px;
      font-weight: 700;
    }
    & p {
      line-height: 25px;
      margin: 0px;
    }
  }
}
.modalcros {
  border: 0;
  padding: 5px 15px;
  position: absolute;
  right: 0;
}
.nocrscont {
  display: flex;
  justify-content: space-between;
  padding: 0 2px;
  align-items: center;
  & > span {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    & > span {
      color: #c93b54;
      margin-left: 4px;
      font-size: 17px;
    }
  }
}

.contactProviderModal {
  border-radius: 30px;
  /* padding: 60px 15px 15px; */
  background-color: #fff;
  border: none;
  /* width: 100%;
  height:100%; */
  @media (min-width: 768px) {
    /* padding: 60px 30px; */
  }
}
.modalContainer {
  & > button {
    @media (max-width: 767px) {
      display: none;
    }
  }
}
.modalContent {
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  border-radius: 30px;
  padding: 3px;
  @media (max-width: 1023px) {
    margin: 0;
  }
  @media (max-width: 767px) {
    border-radius: 0px;
    padding: 0px;
    background: #fff;
    margin: auto;
    width: 96%;
    position: relative;
  }
}
.modalContainer {
  border: 0;
  margin: 100px auto;
  position: relative;
  @media (max-width: 767px) {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  & > button:first-child {
    position: absolute;
    /* margin-top: 60px; */

    & > span:first-child {
      /* display: none; */
    }
  }
}
.pfslideravatar {
  position: absolute;
  right: 42%;
  top: -50px;
  img {
    width: 100%;
    height: 100%;
    border: 3px solid #fff;
  }
}
.childrenbk {
}
.avimg {
  position: absolute;
  /* height: 110px; */
  height: 150px;
  width: 150px;
  left: 0;
  right: 0;
  display: table;
  margin: 0 auto;
  top: 0;
  transform: translateY(-50%);
}
.emgctspn {
  cursor: pointer;
}
.btautname {
  /* padding-bottom: 7px;
  padding-top: 15px; */
  position: relative;
  background-color: #f2f1ef;
  padding: 21px 15px 12px;
  border: 0 !important;
  border-radius: 10px !important;
  & > span:nth-child(2) {
    cursor: pointer;
  }
}
.dspecnt {
  padding: 15px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.emgdecoy {
  border: 2px solid transparent;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%) border-box;
  margin-bottom: 5px;
  border-radius: 10px;
}
.btautname svg {
  position: absolute;
  top: 0;
  right: 0;
  stroke: #c93b54;
  fill: #c93b54;
  width: 20px;
  height: 20px;
}
.dspecnt svg {
  position: relative !important;
  stroke: #000 !important;
  fill: #000 !important;
  width: 25px !important;
  height: 25px !important;
}
.ampradio {
  width: 30px;
  height: 30px;
  border: 3px solid rgb(0 0 0 / 50%) !important;
  border-radius: 30px;
}
.ampradioselect {
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  border: 0 !important;
  position: relative;
  &::after {
    content: '\f00c';
    font-family: 'Font Awesome 6 Free';
    font-weight: 900;
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.acrdbtn {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
}
.plus {
  color: #c93b54;
}
.acrdpanel {
  text-align: center;
}
.btnnorm {
  color: red !important;
}
.btngreen {
  color: green !important;
}
.acrditem {
  margin-bottom: 20px;
}
.modalWidth {
  /* width: 444px; */
  width: 644px;
  /* padding: 80px 30px 40px; */
  @media (max-width: 767px) {
    width: unset;
  }
}
.bday {
  display: flex !important;
}
.sdat {
  color: grey;
  line-height: 15px;
  font-size: 12px;
  & span {
    color: #c93b54;
  }
}
.emgcntmndv {
  /* border-top: 2px solid #cecece;
  border-bottom: 2px solid #cecece; */
  margin: 5px 0 0px;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
}
.emgcnhead {
  margin-top: 30px;
  line-height: 22px;
  font-weight: 600;
  font-size: 18px;
  text-transform: none;
  letter-spacing: 0;
  color: #000;
  /* margin-left: 20px; */
  display: block;
  & span {
    font-size: 17px;
    text-decoration: none;
    color: #c93b54;
    margin-left: 4px;
  }
}
.emgsPresent {
  border: 2px solid #cecece;
  padding: 15px;
  border-radius: 10px;
}
.radiomainspn {
  /* width: 20px;
  height: 20px; */
  /* background: linear-gradient(
    90deg,
    rgba(146, 27, 152, 1) 27%,
    rgba(201, 59, 84, 1) 72%
  ); */
  display: inline-flex;
  /* border-radius: 50%; */
  /* border-radius:4px; */
  /* padding: 2px; */
  cursor: pointer;
  /* border:3px solid #fff; */
  min-width: 20px;
  max-width: 15%;
  flex: 0 0 auto;
  background-color: transparent;
  & span {
    /* display: block;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 50%;
    padding: 3px; */
  }
}
.termslabel {
  background: #cecece;
  display: flex;
  padding: 13px 14px;
  justify-content: space-between;
  border-radius: 10px;
  display: flex;
  align-items: center;
  /* margin-bottom:30px; */
  & > span:first-child {
    /* max-width: 75%; */
    width: 85%;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    line-height: 22px;
    flex: 0 0 auto;
  }
}
.terms {
  margin-top: 30px;
  margin-bottom: 10px;
  padding-bottom: 15px;
  /* border-bottom: 2px solid #cecece; */
  width: 100%;
  /* @media (max-width: 767px) {
    margin-bottom: 60px;
    position: fixed;
    bottom: -5px;
    margin-left: -15px;
    background: #fff;
  } */
  & input[type='checkbox'] {
    /* display: none; */
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    /* min-width: 20px; */
    /* background-color: transparent !important; */
    border: 3px solid #c93b54;
    appearance: none;
    cursor: pointer;
    position: relative;
  }
  & input[type='checkbox']:checked {
    background: rgb(201, 58, 83) !important;
    background: linear-gradient(
      90deg,
      rgba(201, 58, 83, 1) 0%,
      rgba(146, 26, 152, 1) 100%
    ) !important;
    /* border-color: transparent !important; */
    border: 0 !important;
    width: 30px;
    height: 30px;
  }
  & input[type='checkbox']:checked::before {
    content: '\f00c';
    font-family: 'Font Awesome 6 Free';
    font-weight: 900;
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 50%;
    align-items: center;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  & > span:first-child {
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
    & > span {
      color: #c93a53;
      font-size: 17px;
      text-decoration: none;
    }
    & > span:first-child {
      letter-spacing: 0;
      color: #000;
      font-size: 18px;
      font-weight: 600;
      padding: 0 4px;
      text-decoration: underline 0.5px !important;

      &:hover {
        /* border-bottom: 2px solid transparent; */
        cursor: pointer;
      }
    }
  }
}
.inspan {
  /* background:linear-gradient( 90deg, rgba(146, 27, 152, 1) 27%,
  rgba(201, 59, 84, 1) 72%);
  border:3px solid transparent;
  width: 20px;
  height: 20px;
  min-width:20px;
  margin-top: -3px;
  margin-left: -2px; */
}
.readTerms {
  text-decoration: underline;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    text-decoration: none !important;
  }
}
.emgbtn {
  border: none;
  color: #fff;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  margin: 5px auto;
  padding: 8px 12px;
  border-radius: 5px;
  width: 200px;
  /* margin: 0 auto 0 0; */
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  }
  &:disabled {
    opacity: 0.7;
  }
}
.emgbtn {
  @media (max-width: 1024px) {
    margin-top: 15px !important;
  }
}
.eachtc {
  color: #000;
  margin-bottom: 15px;
  padding: 4px 15px;
  border: 2px solid #cecece;
  border-radius: 10px;
  @media (max-width: 575px) {
    padding: 5px 10px 22px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    padding: 5px 10px 22px;
  }
}
.eachtc p {
  margin: 4px 0 8px;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 17px;
  /* max-width: 80%; */
}
.eachtc label {
  text-decoration: underline;
  font-size: 13px;
  font-weight: 700;
  color: #c93b54;
}
.eachtc div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.tncdv {
  background: #fff;
  border-radius: 30px;
  @media (min-width: 768px) {
    max-width: 500px;
  }
}
.tncdv p {
  margin-top: 10px;
  font-size: 20px;
  color: #000;
  font-weight: 700;
}
.service p {
  font-size: 13px;
  color: unset;
  font-weight: 500;
  line-height: 17px;
  font-style: italic;
  margin-left: 10px;
}
.flnmdiv {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  & > div:nth-child(1) {
    width: 48%;
    flex: 0 0 auto;
    & .firstName {
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 15px;
    }
  }
  & > :nth-child(2) {
    width: 48%;
    flex: 0 0 auto;
    & .lastName {
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 15px;
    }
  }
  & input {
    font-size: 16px;
    padding: 2px 16px 2px;
    background-color: #fff;
    border: 2px solid rgb(0 0 0 / 25%);
    border-radius: 10px;
    height: 60px;
    font-weight: 600;
    color: #232323;
    text-transform: capitalize;
    &:hover,
    &:focus {
      border-bottom-color: rgb(0 0 0 / 25%) !important;
      outline: none !important;
    }
    &::placeholder {
      color: rgb(0 0 0 / 25%);
      text-transform: capitalize;
    }
  }
}
.dob {
  border: none !important;
  border-bottom: 1px solid transparent !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-bottom: 2px !important;
  padding-top: 2px !important;
  & input {
    /* padding-top: 2px; */
    font-size: 15px;
  }
  &:hover {
    border-bottom: 1px solid #000 !important;
  }
}
.dob input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.bnsdate {
  position: relative;
  /* border: 1px solid #cecece; */
  box-sizing: border-box;
  border-radius: var(--borderRadius8);
  display: flex;
  flex-direction: column;
  /* margin: 5px 0; */
  margin-bottom: 0;
  position: relative;
  & div::before {
    border: none !important;
    border-bottom: none !important;
    &:hover {
      border-bottom: 0px !important;
    }
  }
  & div::after {
    border: none;
    border-bottom: none;
    &:hover {
      border-bottom: none !important;
    }
  }
  & div {
    &:hover {
      border-bottom: none;
    }
  }
  & > div {
    & > div {
      position: relative;
      border: 0 !important;
      &::after {
        position: absolute;
        content: '';
        height: 43px;
        width: 1px;
        background-color: rgba(0, 0, 0, 0.2);
        top: 10px;
        bottom: 0;
        left: unset;
        right: 50px;
      }

      & > div {
        & > div {
          & > div {
            & > input {
              font-size: 16px !important;
              border: 2px solid #00000040 !important;
              border-radius: 10px !important;
              height: 60px;
              font-weight: 500 !important;
              background-color: #fff !important;
              /* color: rgb(0 0 0 / 25%); */
              color: #232323;
              border-bottom-width: 2px !important;
              background-image: url(../../assets/calender.svg) !important;
              background-position: top 19px right 12px !important;
              position: relative !important;
              &::placeholder {
                color: rgb(0 0 0 / 25%);
              }
            }
          }
        }
      }
    }
  }
}

.mdnts {
  margin-top: 20px;
  & span {
    background-color: #002435;
    width: 100%;
    display: flex;
    color: #fff;
    font-size: 15px;
    justify-content: center;
  }
  & label {
    font-size: 16px !important;
    font-weight: 700 !important;
    padding-bottom: 15px !important;
  }
  & textarea {
    resize: none;
    max-width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    margin-bottom: 20px;
    padding: 8px 16px;
    min-height: 92px;
    font-size: 16px;
    border: 2px solid rgb(0 0 0 / 25%);
    border-radius: 10px;
    font-weight: 600;
    color: #232323;
    &::placeholder {
      color: rgb(0 0 0 / 25%);
    }
    &:hover,
    &:focus {
      border-bottom-color: rgb(0 0 0 / 25%) !important;
      outline: none !important;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.redAstx {
  color: #c93b54;
}
.fmlydiv {
  max-height: 70vh;
  overflow: auto;
  padding-right: 5px;

  & button {
    border: none;
    background: linear-gradient(90deg, #921b98 27%, #c93b54 72%);
    &:hover {
      /* background: linear-gradient(90deg, #c93b54 27%, #921b98 72%); */
    }
    padding: 10px 15px;
    border-radius: 8px;
    /* /* min-height: 35px; */
    display: flex;
    align-items: center;

    border: none;
    background: transparent;
    width: 100%;
    padding: 10px 15px;
    border-radius: 8px;
    /* max-height: 35px; */
    display: flex;
    /* display: inline-block; */
    /* text-align: left; */
    /* align-items: center; */
  }
  & > p {
    font-size: 18px;
    line-height: 28px;
    margin: 15px 0;
    text-align: center;
    color: #232323;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
  & > span:first-child {
    color: #c93b54;
    display: flex;
    /* width: 70%; */
    /* border-bottom: 2px solid #cecece; */
    margin: 10px auto;
    justify-content: center;
    font-size: 32px;
    font-weight: 400;
    font-family: 'Stardos Stencil', cursive;
  }
  & label {
    font-size: 16px;
    font-weight: 600;
    color: #232323;
    padding-bottom: 0;
  }
}
.emgdv > span:first-child {
  color: #c93a53;
  display: flex;
  width: fit-content;
  border-bottom: 2px solid #cecece;
  margin: 10px auto;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
}
.emgdv > h3 {
  font-size: 16px;
  text-align: center;
  color: #000;
}
.cspn {
  /* text-decoration: underline; */
  font-size: 16px;
  /* line-height:26px; */
  font-weight: 600;
  color: #232323;
  padding-bottom: 12px;
}
.xpsn {
  text-decoration: none !important;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
}
.csbtn {
  /* margin-top: 20px !important; */
  max-width: 100% !important;
  justify-content: center;
  min-height: 48px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  border-radius: 100px !important;
  color: #fff !important;
  background: linear-gradient(
    90deg,
    rgba(146, 27, 152, 1) 27%,
    rgba(201, 59, 84, 1) 72%
  ) !important;
  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.7 !important;
  }
  &:hover {
    background: linear-gradient(
      90deg,
      rgb(146 26 152 / 70%) 0%,
      rgb(201 58 83 / 70%) 100%
    ) !important;
  }
}
.fld {
  /* max-height: 400px; */
  /* transition:all 0.03s ease-in-out; */
  @media (max-width: 767px) {
    /* max-height: 55vh; */
  }
  & > div > div:nth-child(even) {
    background-color: #e4e4e4;
    background: #e4e4e4;
    & .datepicker {
      & > div > div > div > div {
        background: #e4e4e4;
      }
    }
  }
  & > div {
    & > div {
      padding: 20px;
      @media (max-width: 767px) {
      }
      & > div {
        & > label {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
.ademgcntbtn {
  min-height: 100% !important;
  font-size: 13px;
  line-height: 14px;
  border-radius: 7px;
  text-align: center;
  color: #fff;
  padding: 0px 15px;
  border: none;
  cursor: pointer;
  background: #002435;
}
.addemgbtndv {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  border: 2px solid transparent;
  border-radius: 7px;
  width: fit-content;
  margin: 0 auto;
}
.customControl {
  border: 2px solid #000 !important;
  box-shadow: none !important;
  padding-left: 15px;
  border-radius: 10px !important;
  border: 2px solid rgb(0 0 0 / 25%) !important;
  /* border:2px solid rgba(0, 0, 0, 0.5); */
  & > div:nth-child(1) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  & > :nth-child(2) {
    & > div {
      & > svg {
        fill: rgb(0 0 0 / 50%) !important;
        width: 14px !important;
      }
    }
  }
}
.select input {
  border: none !important;
  padding: initial;
  border-radius: initial;
  margin-top: initial;
  min-height: 40px;
}
.customMenu {
  background: #002435 !important;
  border-radius: 10px !important;
  margin-top: 0 !important;
  /* padding-right: 10px; */
  padding-top: 10px;
  padding-bottom: 5px;
  & > div:first-child {
    /* padding-left: 10px;
    padding-right: 10px; */
    padding-top: 0 !important;
    /* border-radius: 10px !important; */
  }
}
.customOption {
  padding-left: 25px;
  background: #002435 !important;
  color: #fff !important;
  font-weight: 600;
  cursor: pointer;
  /* border-radius: 30px; */
  cursor: default !important;
  transition: all 300ms ease-in-out;
  &:hover {
    /* background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important; */
    color: #000 !important;
    cursor: pointer !important;
    background-color: #cecece !important;
    & .chldo {
      & > span:nth-child(1) {
        color: #fff;
      }
      & > span:nth-child(2) {
        /* color: #fff; */
        & > span {
          /* color: #fff; */
        }
      }
    }
  }
}
.disabledOption {
  & > div {
    & > span:nth-child(1) {
      color: #4a4a4a !important;
    }
    & > span:nth-child(2) {
      color: #4a4a4a !important;
    }
  }
}
.tncmodcont {
  & > button {
    @media (max-width: 575px) {
      margin-top: 0px !important;
    }
  }
}
.dvth,
.dvto {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  border-bottom: 1px solid #cecece;
  margin-bottom: 30px;
}
.dvth > div,
.dvfr > div,
.dvto > div {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  & span:first-child {
    color: #000;
  }
  & span:last-child {
    color: #000;
    text-align: right;
    font-size: 18px;
    font-weight: 700 !important;
    line-height: 22px;
  }
}
.dvcth > div {
  & > span {
    color: #000 !important;
    font-weight: 500 !important;
  }
}
.dvto > div:first-child {
  margin-top: 0 !important;
  & span:first-child {
    font-weight: 600;
    font-family: 'Stardos Stencil', cursive;
  }
}

.dvth > div:last-child,
.dvto > div:last-child {
  margin-bottom: 0 !important;
}
.dvth > div:first-child {
  margin-top: 0 !important;
}
.dvth > div > span:first-child {
  font-weight: 600;
  color: #232323;
  font-size: 18px;
  line-height: 24px;
  & span {
    font-weight: 600 !important;
    color: #858585;
    font-size: 18px;
    line-height: 24px;
  }
}
.dvth {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 20px !important;
  font-size: 14px;
  line-height: 20px;
}
.rembal {
  display: flex;
  justify-content: space-between;
  color: #7b7b7b;
  margin: 5px 0 30px;
  font-size: 18px;
  line-height: 34px;
  & span:last-child {
    font-weight: 700 !important;
  }
}
.dvfr {
  padding: 5px 0px !important;
  border-top: 1px solid rgb(0, 0, 0, 0.2);
  border-bottom: 1px solid rgb(0, 0, 0, 0.2);
}
.dvcfr {
  padding: 8px 15px;
  margin-bottom: 0;
}
.dvfr > div > span:first-child {
  color: #c93b54;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}
.dvcfr > div > span:first-child {
  color: #c93b54;
  font-weight: 700;
}
.dvfr > div > span:last-child {
  color: #c93a53;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}
.dvcfr > div > span:last-child {
  color: #c93b54;
}
.dvfv {
  border-top: 1px solid #cecece;
  padding-top: 20px;
}
.dvfv p {
  margin: 0;
}
.dvfv p:first-child {
  font-family: 'Stardos Stencil', cursive;
  color: #000;
  font-weight: 600;
  font-size: 20px;
}
.tooltipInfo {
  display: none;
}
.tooltip {
  position: absolute;
  top: -7px;
  left: 5px;
  cursor: pointer;
  &:hover + .tooltipInfo,
  &:focus + .tooltipInfo {
    display: block;
  }
}
.tooltipInfo {
  display: none;
  position: absolute;
  min-width: 180px;
  top: 0px;
  left: 5px;
  transform: translate(-50%, -100%);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  /* background-color: rgb(227 227 227 / 75%); */
  background-color: #002435;
  color: #fff !important;
  font-weight: 600 !important;
  border: none;
  font-size: 13px !important;
  pointer-events: none;
  padding: 10px 10px;
  text-align: left !important;
  &::after {
    content: '';
    position: absolute;
    bottom: -0.375rem;
    left: 50%;
    top: 100%;
    width: 12px;
    height: 12px;
    background-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg) translate(-50%, -50%);
    border-color: #002435;
    /* border-color: rgb(227 227 227 / 75%); */
    border-style: solid;
    border-width: 6px;
    z-index: 0;
  }
}
.tooltipContainer {
  position: relative;
  flex-grow: 1;
}
.years {
}
.customOptions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.childModalContainer {
  & > button {
    align-items: baseline !important;
    & > span {
      font-size: 14px;
      color: #000;
      line-height: 22px;
      font-weight: 700;
    }
  }
}
.DateField {
  margin-top: 5px;
  & > label {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 15px;
  }
}
.AddChildModal {
  /* overflow:auto !important; */
  & > div {
    overflow: auto !important;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.CrossIcon {
  & > svg {
    top: 15px;
    right: 15px;
    fill: #000 !important;
    stroke: none !important;
    width: 12px !important;
    height: 12px !important;
  }
}
.extendchldCareDiv {
  margin-top: 15px;
}
.months {
  padding-left: 5px;
  color: rgb(35 35 35 / 50%);
}
.EarlydropCheckbox {
  /* width:30px !important;
  height: 30px !important;
  bor */
}
.erlydrplbl {
}
.Fullpayment {
  border: 1.5px solid rgb(0 0 0 / 25%);
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 90px;
}
.Fullpaymntslct {
  background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  border-radius: 10px;
  border: 2px solid transparent;
}
.PartPayment {
  border: 1.5px solid rgb(0 0 0 / 25%);
  border-radius: 10px;
}
.PartPaymntSlct {
  background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  border-radius: 10px;
  border: 2px solid transparent;
}
.AddNewChild {
  background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  -webkit-text-fill-color: transparent !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  color: transparent !important;
  cursor: pointer !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  /* margin-top: 15px !important; */
  padding: 0 !important;
  & > svg {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
  & > span {
    /* text-decoration:underline; */
  }
  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.7 !important;
  }
}
.bookngCtgry {
  padding: 0 15px 25px;
}
.emgo {
  display: flex;
  justify-content: space-between;

  & p {
    margin: 0;
  }
}

.emgContCheck {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #c93b54;
  border-radius: 50%;
  background-color: #fff;
  margin: auto 0;
}
.emgContSelected {
  background: linear-gradient(
    198deg,
    rgba(146, 26, 152, 1) 0%,
    rgba(201, 58, 83, 1) 100%
  ) !important;
  border: none;
  & .tickSN {
    &:hover {
      stroke: #fff;
    }
  }
}
.customEmgOption {
  &:hover {
    & svg {
      stroke: #fff !important;
      color: #fff !important;
    }
  }
}
.moneyMult {
  display: flex !important;
  gap: 5px;
}
.ampradblck {
  width: 33px;
  height: 33px;
  fill: rgb(0 0 0 / 50%);
}
.trllbldisbl {
  cursor: not-allowed;
}
.trialfull {
  color: red;
  text-align: center;
  margin-bottom: 10px;
}
.custEmgCnt {
  border: 2px solid #000 !important;
  box-shadow: none !important;
  padding-left: 15px;
  border-radius: 10px !important;
  border: 2px solid rgb(0 0 0 / 25%) !important;
  /* border:2px solid rgba(0, 0, 0, 0.5); */
  & > :nth-child(2) {
    & > div {
      & > svg {
        fill: rgb(0 0 0 / 50%);
        width: 14px !important;
      }
    }
  }
  & > div:nth-child(1) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.contEleg {
  border-color: transparent !important;
  background: linear-gradient(
    198deg,
    rgba(146, 26, 152, 1) 0%,
    rgba(201, 58, 83, 1) 100%
  ) !important;
  & svg {
    fill: #fff !important;
  }
  & > div > div {
    color: #fff !important;
  }
}
.rctooltip {
  max-width: 320px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-color: #002435;
  color: #fff !important;
  font-weight: 600 !important;
  border: none;
  font-size: 13px !important;
  padding: 0px;
  text-align: left !important;
  white-space: normal;
  & :global(.rc-tooltip-arrow) {
    border-left-color: #002435;
    @media (max-width: 767px) {
      border-left-color: transparent;
      border-top-color: #002435;
    }
  }
}
.modalCtn {
  @media (max-width: 767px) {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
.addChlbtndv {
  display: flex;
}
