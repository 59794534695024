@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.card {
  @apply --marketplaceInputStyles;

  border: 1px solid #cecece !important;
  color: #000;
  height: 50px;
  border-radius: 6px;
  font-size: 16px;

  /* Layout */
  padding: 11px 25px;

  /* Border */
  border-bottom-color: var(--attentionColor);
}
.card > div {
  height: 100%;
  display: flex !important;
  align-items: center;
}

.cardSuccess {
  /* border-bottom-color: var(--successColor); */
}

.cardError {
  /* border-bottom-color: var(--failColor); */
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.paymentHeading {
  margin: 0 0 14px 0;
  color: var(--matterColorAnti);

  padding-top: 8px;
  padding-bottom: 0px;

  @media (--viewportMedium) {
    margin: 0 0 26px 0;
  }
}

.billingHeading {
  margin: 0 0 14px 0;
  color: var(--matterColorAnti);
  text-align: center;
  padding-top: 3px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 26px 0;
  }
}

.paymentLabel {
  margin: 0;

  @media (--viewportMedium) {
    margin-bottom: 2px;
  }
  @media (--viewportLarge) {
    margin-bottom: 4px;
  }
}

.messageHeading {
  color: var(--matterColorAnti);
  margin: 40px 0 14px 0;

  padding-top: 4px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    margin: 41px 0 26px 0;
  }
  @media (--viewportLarge) {
    margin: 40px 0 26px 0;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: 30px;
}
.submitContainer button {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  font-size: 16px;
  border-radius: 8px;
  min-height: 40px;
  padding: 3px 15px;
  color: #fff;
  cursor: pointer;
  border: none;
  width: 50%;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
}

.infoText {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  padding: 0;
  margin: 14px 0 0 0;
  font-family: 'Open Sans', sans-serif !important;
}

.submitButton {
  margin-top: 22px;

  @media (--viewportMedium) {
    margin-top: 26px;
  }
  @media (--viewportLarge) {
    margin-top: 17px;
  }
}

.missingStripeKey {
  color: var(--failColor);
}

.paymentAddressField {
  padding-top: 38px;
}

.formRow {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 24px;
}

.postalCode {
  margin-top: 24px;
  width: calc(40% - 9px);
}

.city {
  margin-top: 24px;
  width: calc(60% - 9px);
}

.field {
  margin-top: 24px;
}
.field input {
  border: 1px solid #cecece !important;
  color: #000;
  min-height: 50px;
  border-radius: 6px;
  padding: 0 25px;
  font-size: 16px;
  margin-top: 5px;
}
.spa input {
  border: 1px solid #cecece !important;
  color: #000;
  min-height: 50px;
  border-radius: 6px;
  padding: 0 25px;
  font-size: 16px;
  margin-top: 5px;
}
.spa select {
  margin-top: 5px;
  border: 1px solid #cecece !important;
  padding: 0 25px;
  font-size: 16px;
  color: #000;
  min-height: 50px;
  border-radius: 6px;
  background-position: 95%;
}
