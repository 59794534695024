.curved {
  position: relative;
  margin: auto 0 0px 0px;
  svg {
    position: absolute;
    bottom: 0;
    height: 174px;
    width: 100%;
    background-size: cover;
    @media (max-width: 1199px) {
      bottom: -2px;
    }
  }
  // path {
  //   width: 100%;
  // }
  // img {
  //   width: 100%;
  //   object-fit: cover;
  //   position: absolute;
  //   top: -108px;
  //   max-height: 110px;
  //   object-position: top;
  // }
}
