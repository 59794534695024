.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}
.exitButton {
  border-radius: 50px;
  font-weight: 500;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 7px 20px;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  line-height: 18px;
  margin: 0 0 0 auto;
  width: 115px;
  min-height: 42px;
  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  }
  &:disabled {
    cursor: not-allowed;
  }
}
.svexbtnsec {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.buttonDiv {
  display: flex;
  align-items: flex-end;
  padding-bottom: 25px;
}
.backButton {
  display: inline-flex;
  align-items: flex-end;
  font-weight: 900;
  font-size: 16px;
  text-decoration: underline;
  color: #676767 !important;
  background: none !important;
  text-align: left;
  max-width: 50px;
  &:hover {
    box-shadow: none;
  }
}

.formlistbtmsec {
  margin: auto 0 0;
  @media (max-width: 767px) {
    margin: 0;
    padding: 0 15px;
    /* position: sticky; */
    bottom: 0;
    background: #fff;
  }
}
.submitButton {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  border-radius: 8px;
  min-height: auto;
  padding: 10px 15px;
  max-width: 150px;
  margin: 0 0 0 auto;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}
.noOfDaysContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  & label {
    flex-basis: 30%;
  }
  & input {
    display: flex;
    border: 2px solid black;
    max-width: 100px;
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
  }
}
.calendarWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  & input {
    display: none;
  }
  & :global(.DateInput) {
    display: none;
  }
  & :global(.SingleDatePicker_picker) {
    position: static;
  }
  & :global(.DayPickerNavigation_button) {
    position: absolute;
    width: 28px;
    height: 28px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    & svg {
      width: 24px;
      height: 44px;
      fill: #fff;
    }
  }
  & :global(.CalendarDay__selected) {
    background: linear-gradient(
      45deg,
      rgba(201, 59, 84, 1) 27%,
      rgba(146, 27, 152, 1) 72%
    ) !important;
    font-weight: 800;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.5);
  }
  & :global(.DayPickerNavigation__horizontal) {
    & > div:last-child {
      top: 10px;
      right: 12px;
    }

    & > div:first-child {
      top: 10px;
      left: 12px;
      align-items: flex-start;
    }
  }
  /* & :global(.CalendarMonth_caption){
    background-color: var(--marketplaceColor)
  } */
}

.calendarWrapper label {
  color: #232323;
  font-size: 20px;
  margin: 0 0 20px;
}
.noOfDays {
  display: inline-flex;
  flex-basis: 70%;
  padding: 5px 5px;
  align-items: center;
  max-width: 70%;
  margin: 5px;
  & span {
    padding: 5px;
  }
}
.ageButton {
  /* margin: 10px 10px; */
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #000;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  color: #c93b54;
}
.extended {
  padding: 10px 25px;
  background: #fbfbfb;
  margin: 20px 15px 40px 0;
  border: 1px solid #eee;
  & input {
    display: none;
  }
}
.extended label {
  font-size: 18px;
  color: #232323;
  margin: 0 0 15px;
  @media (max-width: 991px) {
    font-size: 16px;
  }
}
.checkboxLabel {
  align-items: center;
  display: flex;
}
.checkboxLabel span {
  font-size: 16px;
}
.checkIcon {
  margin: 0;
}
.checkIcon svg {
  width: 24px;
  height: 24px;
  margin: 0 15px 0 0;
}
.heading {
  margin-bottom: 11px;
}

.radioButtons {
  padding-top: 3px;
  padding-bottom: 5px;
}

.openTillDiv {
  display: flex;
  padding: 10px;
  text-align: center;
  align-items: center;
}
.mainPrice {
  display: flex;
  border: 2px solid black;
  max-width: 100px;
  margin: 10px;
  padding: 15px;
  border-radius: 10px;
  & :nth-child(1) {
    text-align: right;
    border: none;
    font-size: 20px;
    font-weight: 600;
    padding-right: 1px;
  }
  & :nth-child(2) {
    text-align: left;
    border: none;
    font-size: 20px;
    font-weight: 600;
    padding-left: 1px;
  }
}
.innerDive {
  display: flex;
  align-items: center;
  max-width: 50%;
  flex-basis: 50%;
  @media (max-width: 575px) {
    max-width: 60%;
    flex-basis: 60%;
  }
}
.evntcon {
}
.formseclist {
  max-height: calc(100vh - 250px);
  min-height: calc(100vh - 250px);
  overflow-y: auto;
  margin: 20px 0 30px;
  padding: 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    padding: 0 15px;
    max-height: unset;
  }
}
.mpDiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  & > span {
    color: #000;
    font-size: 20px;
    margin: 0 0 30px;
    text-align: center;
  }
}
.flexprice {
  text-align: center;
  display: flex;
  color: #000;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding-right: 20px;
  @media (max-width: 575px) {
    margin: 20px 0 20px;
    flex-direction: column;
  }
}
.flexprice label {
  position: relative;
  font-size: 18px;
  flex-basis: 50%;
  text-align: left;
  max-width: 50%;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  & span {
    font-size: 15px;
    font-weight: 400;
  }
  @media (max-width: 1199px) {
    /* font-size: 14px; */
  }
  @media (max-width: 575px) {
    flex-basis: unset;
    max-width: unset;
    margin-bottom: 15px;
  }
}
.rowlbl label {
  flex-direction: row !important;
}
.tfspn {
  /* position: absolute; */
  color: #c93b54;
  right: 0;
  font-weight: 700 !important;
  font-size: 14px !important;
  margin-left: 10px;
  @media (max-width: 767px) {
    font-size: 12px !important;
    margin-left: 5px;
  }
}
.flexprice input {
  border: 1px solid #cecece !important;
  border-radius: 8px;
  min-height: 50px;
  padding: 5px 15px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  max-width: 170px;
  margin: 0 15px;
  color: #000;
  @media (max-width: 1199px) {
    font-size: 20px;
  }
}
.innerDive span {
  display: flex;
}
.idfst {
  justify-content: center;
}
.modind .mpi {
  border-right: 0 !important;
  border-radius: 8px 0 0 8px !important;
  margin-right: -5px;
  padding-right: 0;
  max-width: none !important;

  @media (max-width: 1199px) {
    padding: 5px 0 5px 5px;
  }
}
.modind .mpnu {
  border-left: 0 !important;
  border-radius: 0 8px 8px 0 !important;
  margin-left: -5px;
  max-width: none !important;
  padding-left: 0;
  @media (max-width: 1199px) {
    padding: 5px 5px 5px 0;
  }
}
.pdot {
  font-weight: 600;
  font-size: 30px;
  line-height: 10px;
  @media (max-width: 1199px) {
    font-size: 20px;
  }
}
.daytmecon {
  width: 80%;
  margin: 20px auto;
  & > label:first-child {
    color: #c93b54;
    margin-bottom: 7px;
    font-size: 17px;
    text-align: center;
    font-weight: 700;
  }
}
.echdaytme {
  border: 3px solid #c93b54;
  padding: 20px 0px 0px 20px;
  border-radius: 17px;

  & div:first-child {
    @media (max-width: 575px) {
      flex-direction: column !important;
    }
    @media (min-width: 575px) and (max-width: 767px) {
      flex-direction: row !important;
    }
    @media (max-width: 991px) {
      flex-direction: column;
    }
    & div {
      max-width: 80%;
    }
  }
  & > div:nth-child(2) {
    @media (max-width: 575px) {
      flex-direction: column !important;
    }
    @media (min-width: 575px) and (max-width: 767px) {
      flex-direction: row !important;
    }
    @media (max-width: 991px) {
      flex-direction: column;
    }
    & div {
      max-width: 80%;
    }
  }
}
.cphdiv {
  display: flex;
  flex-direction: column;
}
.pldiv {
  & > label:first-child {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    & span {
      color: #c93b54;
    }
  }
}
.fxdiv {
  width: 90%;
  margin: 20px auto 0;
  padding: 0 15px;
  border: 3px solid #c93b54;
  border-radius: 17px;
  min-height: 400px;
}
.description {
  padding: 15px 0;
  border: none;
  resize: none;
  width: 100%;
  min-height: 450px;
}
.hdngp {
  font-size: 18px;
  font-weight: 700;
}
.textdiv {
  & > p:last-child {
    margin: 0;
  }
}
.optnp1 {
  display: flex;
  justify-content: space-between;
  margin: 0;
  color: #8e8383;
}
