@import '../../styles/propertySets.css';
.root {
}
.title {
  /* @apply --marketplaceH1FontStyles; */
  font-family: 'Stardos Stencil', cursive;
  text-align: center;
  font-size: 45px;
  color: #232323;
  max-width: 74%;
  margin: 0 auto;
  line-height: 60px;

  @media (max-width: 991px) {
    line-height: 50px;
    font-size: 35px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
  @media (max-width: 575px) {
    line-height: 34px;
    font-size: 25px;
    font-weight: 600;
  }
}

.locations {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 33px;
  }
}
.redp {
  color: #c93b54 !important;
}
.location {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location {
  @media (--viewportMedium) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}
.youNoLonger {
  text-align: center;
  color: #232323;
  font-size: 22px;
  @media (max-width: 575px) {
    font-size: 18px;
  }
}
.midp {
  font-size: 22px;
  text-align: center;
  font-weight: 700;
  color: #c93b54;
  @media (max-width: 575px) {
    font-size: 18px;
  }
}
.images {
  max-width: 100%;
  border-radius: 30px;
  transition: transform 0.4s ease-in-out;
  background: linear-gradient(180deg, #921b98 27%, #c93b54 72%);
  padding: 2px;
  min-height: 196px;
  object-fit: cover;

  &:hover {
    transform: scale(1.02);
    /* box-shadow: var(--boxShadowSectionLocationHover); */
  }
  @media (max-width: 991px) {
    min-height: auto;
    /* max-height: 150px; */
  }
}
.imageButtons {
  flex-basis: 20%;
  padding: 20px 10px;
  border: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 991px) {
    flex-basis: 33%;
  }
  @media (max-width: 767px) {
    flex-basis: 50%;
  }
  @media (max-width: 575px) {
    flex-basis: 50%;
    padding-top: 0;
  }
}
.imageButtons span {
  font-size: 20px;
  color: #232323;
  margin: 10px 0 0;
  display: block;
  font-weight: 500;

  @media (max-width: 575px) {
    font-size: 16px;
  }
}
.discoverImages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1128px;
  margin-top: 30px;
  @media (max-width: 575px) {
  }
}
.italicEndTag {
  font-style: italic;
}
.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}
.sectionLocationCard {
  background-color: var(--matterColorLight);
  display: flex;
  border-radius: 30px 50px 50px 30px;
  padding-left: 30px;
  max-width: 100%;
  margin: 30px 0 60px 0;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    padding-bottom: 24px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 30px;
  }
}
.sectionLocationCard > div:last-child {
  @media (min-width: 768px) {
    padding: 10px 10px 10px 0;
  }
}
.sectionLocationCardText {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  align-items: center;
  /* min-width: 55%; */
  padding: 0 30px 0 0;
  @media (max-width: 767px) {
    padding: 18px;
  }
}
.sectionLocationCardImage {
  border-radius: 20px;
  min-height: auto;
  /* min-height: 400px; */
  object-fit: cover;
  max-width: 400px;
  width: 90%;
  margin: 0 auto;
  display: block;

  @media (min-width: 1200px) {
    max-width: 100%;
    min-width: 510px;
    margin: 0;
  }
  @media (max-width: 1199px) {
    min-height: 350px;
  }
  @media (max-width: 767px) {
    min-height: auto;
  }
}
.linkText {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}
.bulletLists {
  display: flex;
  flex-direction: column;

  align-items: center;
}
.points ul {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  list-style-position: inside;
  padding-left: 30px;
}
.points ul li {
  font-size: 18px;
  line-height: 33px;
  color: #232323;
  position: relative;
  margin: 0 0 5px;
}
.keepkds {
  font-weight: 600;
  margin-top: 20px;
}
.points ul li::before {
  content: '\2022';
  color: #d32227;
  font-size: 1.5em;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  position: absolute;
  left: -22px;
}
.locationName {
  color: var(--marketplaceColor);
}
.sectionIconsSlider {
  padding: 0 0 25px;
  text-align: center;
}
.sectionIcons {
  display: flex;
  /* justify-content: space-around; */
  padding: 15px 0 60px;
  text-align: center;
  @media (max-width: 767px) {
    /* flex-direction: column; */
    align-items: center;
    padding: 15px 0;
  }
  @media (max-width: 575px) {
    padding-top: 0;
    /* justify-content: center; */
  }
}
.mobicnt {
  display: flex;
  flex-direction: column;
}
.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
  flex-basis: 33%;
  max-width: 33%;
  @media (max-width: 767px) {
    flex-basis: 100%;
    max-width: 100%;
    margin: 25px 0px;
  }
  @media (max-width: 575px) {
    flex-basis: 100%;
    max-width: 100%;
    margin: 0 0 20px;
    flex-direction: column;
    width: 100%;
    padding: 0;
    justify-content: center;
  }
}
.iconImg {
  height: 70px;
  margin-bottom: 15px;
  @media (max-width: 575px) {
    height: 40px;
    margin: 0 0 20px !important;
    object-fit: contain;
    width: 40px;
  }
}
.iconContainer span {
  font-size: 22px;
  color: black;
  @media (max-width: 575px) {
    color: #232323;
    font-size: 15px;
    line-height: 24px;
    text-align: left;
    font-weight: 600;
  }
}
.inspired {
  color: #c93b54;
}
.organised {
  color: #c93b54;
}
.cardHeading {
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  color: #232323;
  @media (max-width: 991px) {
    font-size: 24px;
    line-height: 34px;
  }
}
.cartxtsm {
  font-size: 25px;
  color: #232323;
  padding: 0px 30% 0 0;
  @media (max-width: 991px) {
    padding: 0px 20px 0 0;
  }
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
.extraContentStyle {
  font-size: 18px;
  max-width: 100%;
  margin: 10px auto;
  color: #232323;
  @media (--viewportMedium) {
    max-width: 60%;
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.tssliderrow {
  width: 100%;
  padding: 30px;
  background-color: var(--matterColorLight);
  display: inline-flex !important;
  @media (max-width: 575px) {
    flex-direction: column;
  }

  .tsslidercol {
    padding: 0 5% 0 0;
    max-width: 60%;
    flex-basis: 60%;
    @media (max-width: 575px) {
      max-width: 100%;
      flex-basis: 100%;
    }
    img {
      height: 80px;
    }
    p {
      font-size: 18px;
      color: #232323;
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
    h2 {
      margin: 50px 0 0;
      color: #232323;
      font-weight: 700;
      @media (max-width: 767px) {
        font-size: 16px;
      }
      @media (max-width: 575px) {
        margin: 20px 0 0;
      }
    }
  }
  .tsslidercolimg {
    max-width: 40%;
    flex-basis: 40%;
    @media (max-width: 575px) {
      max-width: 100%;
      flex-basis: 100%;
      margin: 20px 0 0;
    }
    img {
      min-height: 350px;
      object-fit: cover;
      width: 100%;
      @media (max-width: 575px) {
        min-height: auto;
      }
    }
  }
}
.testslider {
  @media (max-width: 1199px) {
    margin: 60px 0 0;
  }
  .tstgrpbtm {
    display: flex;
    margin: 120px auto 0;
    justify-content: center;
    @media (max-width: 575px) {
      flex-direction: column;
    }
    .srchButton {
      border-radius: 5px;
      background: linear-gradient(90deg, rgb(201, 59, 84) 27%, rgb(146, 27, 152) 72%);
      padding: 5px 8px;
      min-width: 200px;
      border: 0;
      color: #fff;
      cursor: pointer;
      margin: 0 15px;
      text-align: center;
      @media (max-width: 575px) {
        margin: 0 auto 15px;
      }
    }
  }
  .tssliderrow {
    width: 100%;
    padding: 30px;
    background-color: var(--matterColorLight);
    display: inline-flex !important;
    @media (max-width: 575px) {
      flex-direction: column;
    }

    .tsslidercol {
      padding: 0 5% 0 0;
      max-width: 60%;
      flex-basis: 60%;
      @media (max-width: 575px) {
        max-width: 100%;
        flex-basis: 100%;
      }
      img {
        height: 80px;
      }
      p {
        font-size: 18px;
        color: #232323;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
      h2 {
        margin: 50px 0 0;
        color: #232323;
        font-weight: 700;
        @media (max-width: 767px) {
          font-size: 16px;
        }
        @media (max-width: 575px) {
          margin: 20px 0 0;
        }
      }
    }
    .tsslidercolimg {
      max-width: 40%;
      flex-basis: 40%;
      @media (max-width: 575px) {
        max-width: 100%;
        flex-basis: 100%;
        margin: 20px 0 0;
      }
      img {
        min-height: 350px;
        object-fit: cover;
        width: 100%;
        @media (max-width: 575px) {
          min-height: auto;
        }
      }
    }
  }
}
