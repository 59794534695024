.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}
.allowedAgeOptions {
  display: flex;
  flex-direction: row;
}
.ageButton {
  /* margin: 10px 10px; */
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #000;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.flexprice {
  text-align: center;
  display: flex;
  color: #000;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.age {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.exitButton {
  border-radius: 50px;
  font-weight: 500;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 7px 20px;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  line-height: 18px;
  margin: 0 0 0 auto;
  min-height: 42px;
  width: 115px;
  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  }
  &:disabled {
    cursor: not-allowed;
  }
}
.svexbtnsec {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.formlistbtmsec {
  margin: auto 0 0;
  @media (max-width: 767px) {
    margin: 0;
    padding: 0 15px;
    position: inherit;
    bottom: 0;
  }
}

.buttonDiv {
  display: flex;
  align-items: flex-end;
  padding-bottom: 25px;
  @media (max-width: 575px) {
    align-items: center;
  }
}
.backButton {
  display: inline-flex;
  align-items: flex-end;
  font-weight: 900;
  font-size: 16px;
  text-decoration: underline;
  color: #676767 !important;
  background: none !important;
  text-align: left;
  max-width: 50px;
  &:hover {
    box-shadow: none;
  }
  @media (max-width: 575px) {
    align-items: center;
  }
}

.submitButton {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  border-radius: 8px;
  min-height: auto;
  padding: 10px 15px;
  min-width: 230px;
  margin: 0 0 0 auto;
  white-space: nowrap;
  & img {
    width: 19px;
    height: 19px;
    margin-left: 5px;
    display: inline;
  }
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
  @media (max-width: 767px) {
    max-width: none;
    font-size: 13px;
    width: unset;
    min-width: 110px;
    padding: 10px 6px;
  }
}
.agefigure {
  margin: 0 25px;
  font-size: 18px;
}
.type {
  padding: 0 20px 0 0;
}
.noRestrictionMsg {
  color: var(--successColor);
}
.error {
  color: red;
  /* color: var(--failColor); */
}

.formseclist {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  margin: 20px 0 30px;
  padding: 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    padding: 0 15px;
    max-height: unset;
  }
}
.mobilescreen {
  min-width: 280px;
  max-width: 340px;
  border: 1px solid #d2d2d2;
  margin: 9px auto;
  /* min-height: 398px; */
  border-radius: 30px;
  padding: 8px;
  box-shadow: 4px 5px 8px 0px rgba(0, 0, 0, 0.3);
}
.msinner {
  border: 1px solid #d2d2d2;
  min-height: 378px;
  border-radius: 30px;
  overflow: hidden;
}
.imgmsinner {
  position: relative;
  background: #000;
  border-radius: 20px;
}
.imgmsinner img {
  width: 100%;
  height: 230px;
  border-radius: 20px;
  object-fit: contain;
}
.imgmsinner span {
  width: 30px;
  height: 30px;
  position: absolute;
  background: #fff;
  right: 10px;
  top: 10px;
  border-radius: 30px;
  color: #000;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobbody {
  max-height: 500px;
  padding: 0 20px;
  overflow-y: auto;
  margin: 5px 0 0;
}
.mobbody h2 {
  color: #232323;
  font-size: 14px;
  margin: 8px 0 5px;
  word-break: break-word;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-transform: capitalize;
  text-align: center;
}
.mobbody p {
  font-size: 13px;
  margin: 0 0 10px;
  color: #232323;
  line-height: 16px;
}
.redtxt {
  color: #c93b54 !important;
  text-align: center;
}
.mobbody p span {
  color: #000;
  font-weight: 800;
}
.pricetxt {
  font-size: 16px;
  color: #232323;
  margin-bottom: 5px;
}
.pricetxt span {
  font-weight: 800;
}
.formlistbtmsec > div {
  @media (max-width: 991px) {
    max-width: 98%;
  }
}
.infosdv {
  & label {
    color: #000;
    font-weight: 700;
  }
}
.toul {
  color: #000;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 10px 0 5px;

  & li {
    font-size: 13px;
    color: #333232ab;
    font-weight: 600;
    margin: 0 2px;
    & svg {
      width: 17px;
      height: 17px;
    }
  }
}
.bndv {
  display: flex;
  gap: 20px;
  margin-left: auto;
}
