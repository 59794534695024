.buttonDiv {
  display: flex;
  align-items: flex-end;
  padding-bottom: 25px;
}
.backButton {
  display: inline-flex;
  align-items: flex-end;
  font-weight: 900;
  font-size: 16px;
  text-decoration: underline;
  color: #676767 !important;
  background: none !important;
  text-align: left;
  max-width: 50px;
  &:hover {
    box-shadow: none;
  }
}

.submitButton {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  border-radius: 8px;
  min-height: auto;
  padding: 10px 15px;
  max-width: 150px;
  margin: 0 0 0 auto;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}
.exitButton {
  border-radius: 50px;
  font-weight: 500;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 7px 20px;
  cursor: pointer;
  background: linear-gradient(
    90deg,
    rgba(201, 59, 84, 1) 27%,
    rgba(146, 27, 152, 1) 72%
  );
  line-height: 18px;
  margin: 0 0 0 auto;
  min-height: 42px;
  width: 115px;
  &:hover {
    background: linear-gradient(
      90deg,
      rgba(146, 27, 152, 1) 27%,
      rgba(201, 59, 84, 1) 72%
    );
  }
  &:disabled {
    cursor: not-allowed;
  }
}
.othertxt {
  max-width: 80%;
  margin: 0 auto;
  @media (max-width: 1199px) {
    max-width: 100%;
  }
}
.othertxt label {
  color: #000;
}
.othertxt input {
  border: 1px solid #bbbaba !important;
  margin: 15px 0 0;
  min-height: 64px;
  background: #dfdfdf;
  border-radius: 8px;
  color: #000;
  padding: 0 15px;
  font-size: 16px;
  text-align: center;
}
.othertxt input::-moz-placeholder,
.othertxt input::-webkit-placeholder {
  color: #000;
}
.formseclist {
  max-height: calc(100vh - 210px);
  min-height: calc(100vh - 243px);
  overflow-y: auto;
  margin: 20px 0 30px;
  padding: 0 15px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: url('https://maps.google.co.in/maps?q=new+Delhi&hl=en&sll=28.572047,77.069178&sspn=0.02348,0.039482&hnear=Delhi&t=m&z=10');
  @media (max-width: 767px) {
    padding: 0 15px;
    min-height: calc(100vh - 380px);
  }
  @media (max-width: 575px) {
    padding: 0 15px;
    min-height: calc(100vh - 320px);
  }
}
.othcatnew {
  position: relative;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bggmaps {
  position: absolute;
  width: calc(100% - 1px);
  height: 100%;
  z-index: 0;
  @media (max-width: 575px) {
    /* height: 1110px; */
  }
}
.formlistbtmsec {
  margin: auto 0 0;
  @media (max-width: 767px) {
    margin: 0;
    padding: 0 15px;
    /* position: sticky; */
    bottom: 0;
    background: #fff;
  }
  @media (max-width: 767px) {
    position: unset;
  }
}
.svexbtnsec {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.categoriesCheckbox {
  border-radius: 25px;
  display: flex;
  justify-content: center;
  background: white !important;
  margin: 15px auto;
  border: 1px solid #bbbaba;
  min-height: 64px;
  width: 320px;
  position: relative;
  z-index: 1;
  @media (max-width: 1199px) {
    max-width: 100%;
  }
}
.selectedCategory {
  border-color: transparent;
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  border-width: 2px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.selectedCategory .checkboxLabel {
  background: #e3e3e3;
}
.keylbl {
  font-size: 22px;
  line-height: 55px;
  color: #232323;
  font-weight: 600;
  display: block;
}
.notsel {
  color: #808080;
}
.radioButton {
  display: none;
}
.checkboxLabel {
  display: flex;
  width: 100%;
  background: white;
  cursor: pointer;
  border-radius: 25px;
  padding: 11px 15px;
  justify-content: center;
  align-items: center;
}

.map {
  width: 100% !important;
  height: 65vh !important;
}
