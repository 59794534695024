@import '../../styles/customMediaQueries.css';

.inputRoot {
  /* Override react-dates default styles to match input styles */

  & :global(.SingleDatePicker) {
    display: block;
    border: 1px solid #cecece;
  }
  & :global(.SingleDatePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 44px !important;
    width: 100%;
    min-height: auto;
    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius10);
    box-shadow: var(--boxShadowPopup);
    max-width: 320px;

    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 44px !important;
      min-height: auto;
    }
  }

  & :global(.DateInput_input) {
    cursor: pointer;
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.1px;

    /* Dimensions */
    display: block;
    width: 100%;
    min-width: 280px;
    /* max-width: 320px; */
    margin: 0;
    padding: 8px 15px;

    /* Borders */
    border-bottom-width: 0px;
    border: 1px solid var(--matterColorDimGray);
    border-radius: var(--borderRadius10);

    transition: border-bottom-color var(--transitionStyle);
    transition: all 0.15s ease-out;
    background: none;

    background-image: url('../../assets/calendar-icon.svg');
    /* url('data:image/svg+xml;utf8,<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g stroke="%234A4A4A" transform="translate(1 1)"><rect stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" x=".292" y="1.459" width="13.417" height="12.25" rx="1"/><path d="M3.208.292V3.21M10.792.292V3.21M.292 4.376h13.416" stroke-linecap="round" stroke-linejoin="round"/><rect x="3.5" y="6.5" width="1" height="1" rx=".5"/><rect x="6.5" y="6.5" width="1" height="1" rx=".5"/><rect x="9.5" y="6.5" width="1" height="1" rx=".5"/><rect x="3.5" y="9.5" width="1" height="1" rx=".5"/><rect x="6.5" y="9.5" width="1" height="1" rx=".5"/><rect x="9.5" y="9.5" width="1" height="1" rx=".5"/></g></g></svg>'); */
    background-repeat: no-repeat;
    background-position: top 0px right 12px;
    padding-right: 36px;

    &::placeholder {
      color: var(--matterColorAnti);
    }
  }

  & :global(.SingleDatePickerInput) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background: none;
  }
  & :global(.DayPicker__horizontal) {
    margin: 0 auto;
    background-color: var(--matterColorLight);
    /* box-shadow: none; */
    border-radius: var(--borderRadius10);
  }

  & :global(.DayPickerNavigation__horizontal) {
    position: relative;
    width: 100%;
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    padding: 6px 9px;
    top: 16px;
    position: absolute;
    cursor: pointer;
    display: inline;

    @media (max-width: 767.99px) {
      top: 20px;
    }

    &:first-of-type {
      left: 24px;
    }

    &:last-of-type {
      right: 24px;
    }
  }

  & :global(.DayPickerNavigation_button) {
    color: var(--matterColorText);
    border: 0;
    display: none !important;
  }

  & :global(.CalendarMonth),
  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }
  & :global(.DateInput) {
    display: block;
    width: 100%;
  }
  & :global(.DayPicker_weekHeader) {
    color: var(--matterColorText);
    top: 63px;
  }

  & :global(.DayPicker_weekHeader_li) {
    font-weight: 400;
    text-transform: uppercase;
  }

  & :global(.CalendarMonth_caption) {
    font-weight: 400;
    font-size: 21px;
    line-height: 24px;
    color: var(--matterColorLight);
    padding-bottom: 32px;

    margin: 1px 0 8px;

    @media (--viewportMedium) {
      line-height: 32px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & :global(.CalendarDay__default) {
    background-color: var(--marketplaceColor);
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  & :global(.CalendarDay) {
    font-weight: var(--fontWeightMedium);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;
    background-color: transparent !important;

    padding: 2px;

    color: #000;
    border: 0;
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & :global(.CalendarDay .renderedDay) {
    display: flex;
    background-color: var(--matterColorLight) !important;
    width: 100%;
    min-height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--matterColorText);
    border: 1px solid var(--matterColorSliver);
    border-radius: var(--borderRadius10);
    &:hover {
      border: 2px solid #002435;
    }
  }

  /* Add an underline for '.renderedDay' */
  & :global(.CalendarDay__today .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-color: var(--matterColorMeadow);
    /* height: var(--DateInput_selectionHeight); */
    /* background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>");
    background-position: center 28px; */
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__hovered_span),
  & :global(.CalendarDay__selected_span) {
    background-image: transparent;
    background-color: transparent;
  }
  & :global(.CalendarDay__hovered_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: var(--DateInput_selectionHeight); */
    background-color: var(--DateInput_hoveredOverlayColor);
  }
  & :global(.CalendarDay__selected_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: var(--DateInput_selectionHeight); */
    background-color: var(--successColor);
    transition: all 0.2s ease-out;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected) {
    background-color: transparent;
    background-image: none;
  }
  & :global(.CalendarDay__selected .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: var(--DateInput_selectionHeight); */
    /* background-color: var(--successColor); */
    border: 2px solid #002435 !important;
    color: #c93b54 !important;
    background-color: #ebebeb !important;

    /* border-radius: calc(var(--DateInput_selectionHeight) / 2); */
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__after-hovered) {
    background-color: transparent;
  }
  & :global(.CalendarDay__after-hovered .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: var(--DateInput_selectionHeight); */
    background-color: var(--DateInput_hoveredOverlayColor);
  }
  & :global(.CalendarDay:hover .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: var(--DateInput_selectionHeight); */
    background-color: var(--DateInput_hoveredOverlayColor);
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_out_of_range),
  & :global(.CalendarDay__blocked_out_of_range:active),
  & :global(.CalendarDay__blocked_out_of_range:hover) {
    background-color: transparent;
    color: #002435;
    border: 0;
    opacity: 0.5;
    cursor: not-allowed;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_calendar),
  & :global(.CalendarDay__blocked_calendar:active),
  & :global(.CalendarDay__blocked_calendar:hover) {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
    opacity: 0.5;
    cursor: not-allowed;
  }
  & :global(.CalendarDay__blocked_out_of_range .CalendarDay__blocked_calendar .renderedDay) {
    background-color: transparent;
  }
  & :global(.DateInput_fang) {
    display: none;
  }
  & :global(.CalendarMonth_caption) {
    text-transform: capitalize;
  }

  & :global(.DateInput__disabled) {
    background: none;
  }
  & :global(.DateInput_input__disabled) {
    cursor: not-allowed;
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;

    /* Dimensions */
    display: block;
    width: 100%;
    min-width: 320px;
    max-width: 320px;
    margin: 0;
    padding: 8px 12px;

    /* Borders */
    border-bottom-width: 0px;
    border: 1px solid var(--matterColorDimGray);
    border-radius: var(--borderRadius10);

    opacity: 0.5;

    /* Effects */
    transition: border-bottom-color var(--transitionStyle);
    transition: all 0.15s ease-out;

    background: none;
    background-image: url('../../assets/calendar-icon.svg');

    background-repeat: no-repeat;
    background-position: top 0px right 12px;
    padding-right: 36px;

    &::placeholder {
      color: var(--matterColorDimGray);
    }
  }

  & :global(.DateInput_input__disabled::placeholder) {
    color: var(--matterColorNegative);
    font-style: normal;
  }

  & :global(.DayPicker_focusRegion) {
    position: relative;
    z-index: 5;
  }
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.withMobileMargins {
  & :global(.SingleDatePickerInput) {
    width: calc(100vw - 48px);
    margin: 0 24px;

    @media (--viewportMedium) {
      width: 100%;
      margin: 0;
    }
  }

  & :global(.SingleDatePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 34px !important;
    border-top: 2px solid var(--matterColorDark);
    min-height: calc(100vh - 252px + 2px);

    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 40px !important;
      border-top: 0;
      min-height: auto;
    }
  }

  /* Create gutter between inputs */
  & :global(.DateInput) {
    width: 100%;
    background: none;
  }
}

.dateRangeInputRoot {
  & :global(.CalendarMonth) {
    position: relative;
    z-index: 8;
  }
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  stroke: var(--matterColorText);
  fill: var(--matterColorText);
}

.dateRangeWrapper {
  display: flex;
  background: #002435;
  flex-direction: row;
  /* background-color: blue; */
  padding: 0px 0px 4px;
  /* padding-left: 20px; */
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--matterColorErrorInputBG);
  margin: 0px;
}

.monthWrapper {
  flex-grow: 1;

  &:first-child {
    padding-right: 4px;
  }

  & .monthContainer,
  & .yearContainer {
    background: #fff !important;
    position: absolute;
    z-index: 8;
    left: 0px;
    width: 100%;
    height: calc(100% - 72px);
    overflow-y: auto;
    /* max-height: 240px;
    overflow-y: auto; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid var(--matterColorAzure);
    border-radius: var(--borderRadius10);
    box-shadow: var(--boxShadowPopup);
    padding: 8px;
    top: 40px;
    @media (min-width: 768px) {
      height: calc(100% - 64px);
    }

    & .mnth,
    & .yr {
      flex-basis: 31.5%;
      max-width: 31.5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #c93b54;
      border: 1px solid #002435;
      margin-bottom: 6px;
      border-radius: 5px;
      background: #f6f6f6;
      &:hover {
        background: #002435;
      }
    }
  }
  & .yearContainer {
    flex-direction: column;
    flex-wrap: nowrap !important;
    & .yr {
      flex-basis: unset;
      max-width: unset;
    }
  }

  & .monthContainer {
    z-index: 7;
    height: calc(100% - 96px);

    @media (min-width: 768px) {
      height: calc(100% - 96px);
    }
  }

  & .monthContainer,
  & .yearContainer {
    & .optionName {
      min-width: 100%;
      color: var(--matterColorText);
      padding: 2px 10px;
      font-size: 14px;
      line-height: 24px;
      font-family: var(--fontFamilyBarlow);
      font-weight: 500;
      border: 1px solid var(--matterColorSliver);
      border-radius: var(--borderRadius10);
      cursor: pointer;
      &:active,
      &:focus,
      &:hover {
        color: #fff !important;
        border-color: var(--matterColorBermuda);
      }
    }
    & .selectedMnth .optionName,
    & .selectedYr .optionName {
      background-color: var(--matterColorMeadow);
      color: #002435;
      font-weight: 500;
      border-color: var(--matterColorMeadow);
    }
  }

  & .yearContainer {
    & .optionName {
      min-width: 90%;
    }
  }

  & .monthLabel,
  & .yearLabel {
    padding: 0px;
    margin: 3px 0px;
    cursor: pointer;
    color: var(--matterColorText);
    & input {
      display: none;

      & ~ .monthContainer {
        display: none;
      }
      & ~ .yearContainer {
        display: none;
      }
      & ~ .mnthActionContainer .downIcon {
        display: inline;
      }
      & ~ .mnthActionContainer .upIcon {
        display: none;
      }
      & ~ .yrActionContainer .downIcon {
        display: inline;
      }
      & ~ .yrActionContainer .upIcon {
        display: none;
      }
      &:checked ~ .monthContainer {
        display: flex;
      }
      &:checked ~ .yearContainer {
        display: flex;
      }
      &:checked ~ .mnthActionContainer .downIcon {
        display: none;
      }
      &:checked ~ .mnthActionContainer .upIcon {
        display: inline;
      }
      &:checked ~ .yrActionContainer .downIcon {
        display: none;
      }
      &:checked ~ .yrActionContainer .upIcon {
        display: inline;
      }
    }
    & .selctedMonthName,
    & .selctedYearName {
      font-size: 16px;
      line-height: 28px;
      display: block;
      margin-right: 8px;
      font-family: var(--fontFamilyBarlow);
      font-weight: 400;
      color: var(--matterColorText);
      flex-grow: 1;
      text-align: left;
      padding-left: 2px;
    }

    & .mnthActionContainer,
    & .yrActionContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 4px;
      border: 1px solid var(--matterColorWhiteSmoke2);
      padding: 2px 8px;
      border-radius: var(--borderRadius10);
      background-color: var(--matterColorWhiteSmoke2);
    }
    & .yrActionContainer {
      margin-right: 0px;
    }
  }
}

.yearWrapper {
  flex-grow: 0;
  flex-basis: 81px;
  max-width: 81px;
}
