.categoriesCheckbox {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  background: white !important;
  margin: 20px 0;
  border: 1px solid #bbbaba;
  &:hover {
    box-shadow: 3px 3px 5px #f2f2f2 !important;
  }
}
.disabledCategory {
  box-shadow: none !important;
  & .checkboxLabel {
    opacity: 0.7;
  }
  & .keylbl {
    font-weight: 600 !important;
  }
}
.isStarterDesign {
  box-shadow: 0px 0px 10px -3px #cecece;
  & .keylbl {
    font-weight: 700 !important;
  }
}
.lockedCategory {
  position: relative;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    content: 'Membership Required';
    background-color: #002435;
    color: #fff;
    font-weight: 700;
    border-top-right-radius: inherit;
    font-size: 12px;
    padding: 3px 6px;
    line-height: 15px;
  }
}
.selectedCategory {
  border-color: transparent;
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  border-width: 2px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  /* background: #f2f2f2 !important; */
}
.selectedCategory .checkboxLabel {
  background: #f2f2f2;
  opacity: 1 !important;
}
.checkbox {
  display: none;
  vertical-align: middle;
  width: 40%;
}
.checkboxLabel {
  vertical-align: middle;
  width: 100%;
  background: white;
  cursor: pointer;
  /* margin: 2px 0 10px 0; */
  border-radius: 8px;
  padding: 11px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.exprbklg {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > svg {
    width: 25px;
    height: 25px;
    fill: var(--successColor);
  }
  & span {
    color: var(--successColor);
    font-size: 10px;
    text-align: center;
  }
}
.buttonDiv {
  display: flex;
  align-items: flex-end;
  padding-bottom: 25px;
}
.backButton {
  display: inline-flex;
  align-items: flex-end;
  font-weight: 900;
  font-size: 16px;
  text-decoration: underline;
  color: #676767 !important;
  background: none !important;
  text-align: left;
  max-width: 50px;
  &:hover {
    box-shadow: none;
  }
}

.submitButton {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  border-radius: 8px;
  min-height: auto;
  padding: 10px 15px;
  max-width: 150px;
  margin: 0 0 0 auto;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}
.exitButton {
  border-radius: 35px;
  font-weight: 500;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 7px 20px;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  line-height: 18px;
  margin: 0 0 0 auto;
  min-height: 42px;
  width: 115px;
  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  }
  &:disabled {
    cursor: not-allowed;
  }
}
.formseclist {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  margin: 20px 0 30px;
  padding: 0 15px 0 0;
  @media (max-width: 767px) {
    padding: 0 15px;
    max-height: unset;
  }
}
.formlistbtmsec {
  margin: auto 0 0;
  @media (max-width: 767px) {
    margin: 0;
    padding: 0 15px;
    position: sticky;
    bottom: 0;
  }
}
.svexbtnsec {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.labelDiv span {
  font-size: 14px;
  color: #808080;
  display: block;
  font-weight: 400;
}
.keylbl {
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 600 !important;
  margin-bottom: 8px;
}
.progressSpan {
  color: #cecece;
}
