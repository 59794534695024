@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  /* flex-direction: column; */

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}
.atrow {
  text-align: left;
}
.favouriteButton {
  cursor: pointer;
}
.favouriteLoading {
  animation: favouriteHeartAnimation 0.2s linear alternate;
  cursor: pointer;
}
.favouriteFalse {
  cursor: pointer;
}
.favouriteTrue {
  color: red;
  cursor: pointer;
}
.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  /* transition: var(--transitionStyleButton); */
  max-width: 320px;
  flex-shrink: 0;

  @media (max-width: 575px) {
    max-width: 100%;
  }

  /* &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  } */
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 0;
  min-height: 250px;
  position: relative;
  flex-basis: 40%;
  max-width: 40%;
  flex-shrink: 0;
  min-width: 280px;
  /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.info {
  /* Layout */
  padding: 15px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 30px;
  @media (max-width: 575px) {
    margin-left: 0;
  }
}
.addinfo {
  font-size: 16px;
  color: #b9334b;
  line-height: 18px;
  margin: 5px 0 0;
}
.datetimeinfo {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;
  font-size: 15px;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 575px) {
    margin: 10px 0;
  }
}
.pricespn {
  font-weight: 500 !important;
}
.datetimeinfocol {
  @media (max-width: 1199px) {
    /* flex-basis: 100%; */
  }
}
.datetimeinfocol span {
  font-weight: 800;
}
.price {
  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 800;
  margin: 0 10px 0 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: #000;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.boldtext {
  font-weight: 700;
}
.mainInfo {
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    margin: 0 auto;
  }
}

.title {
  /* Font */
  color: #000;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  line-height: 24px;
  text-transform: capitalize;
  word-break: break-word;
  /* @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  } */
}

.certificateInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.htisgn {
  width: 40px;
  height: 40px;
  padding: 5px;
  background: #fff;
  position: absolute;
  right: 10px;
  z-index: 1;
  top: 10px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.htisgn svg {
  width: 22px;
  height: 22px;
}
.ratingstar {
  position: absolute;
  right: 10px;
  z-index: 0;
  bottom: 10px;
  background: rgba(255, 255, 255);
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
/* .ratingstar svg {
  width: 22px;
  height: 22px;
  fill: #ffb300;
  margin: 0 2px;
} */
@keyframes favouriteHeartAnimation {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1.5);
  }
}
.spdv {
  z-index: 1;
  position: relative;
}
.spcspn {
  position: absolute;
  top: 4px;
  left: 4px;
  color: #fff;
  padding: 1px 15px;
  font-size: 14px;
  line-height: 25px;
  &:after {
    content: '';
    background-color: #002435;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.7;
    border-radius: 5px;
    @media (max-width: 575px) {
      z-index: -1;
    }
  }
}
.dispn {
  display: none;
}
.ratestar {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 15px;
  font-size: 14px;
  margin-top: -3px;
  & svg {
    margin-right: 4px;
    fill: #ffb300;
  }
}

.outstr path {
  fill: #494840 !important;
}
.starOoverlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
}
.starCont {
  position: relative;
}
