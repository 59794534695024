.root {
}
.rootForPassword {
  position: relative;
}
.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}
.eye {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 0;
  border: 0;
  cursor: pointer;
  & svg {
    width: 20px;
    height: 20px;
  }
}
