@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}
.field {
  margin-top: 24px;
}
.field input {
  border: 1px solid #cecece !important;
  color: #000;
  min-height: 50px;
  border-radius: 6px;
  padding: 0 25px;
  font-size: 16px;
  margin-top: 5px;
}
.spa input {
  border: 1px solid #cecece !important;
  color: #000;
  min-height: 50px;
  border-radius: 6px;
  padding: 0 25px;
  font-size: 16px;
  margin-top: 5px;
}
.spa select {
  margin-top: 5px;
  border: 1px solid #cecece !important;
  padding: 0 25px;
  font-size: 16px;
  color: #000;
  min-height: 50px;
  border-radius: 6px;
  background-position: 95%;
}
.card {
  @apply --marketplaceInputStyles;

  border: 1px solid #cecece !important;
  color: #000;
  height: 50px;
  border-radius: 6px;
  font-size: 16px;

  /* Layout */
  padding: 11px 25px;

  /* Border */
  border-bottom-color: var(--attentionColor);
}
.card > div {
  height: 100%;
  display: flex !important;
  align-items: center;
}

.cardSuccess {
  border-bottom-color: var(--successColor);
}

.cardError {
  border-bottom-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.paymentHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 16px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentMethodSelector {
  margin-bottom: 36px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}

.saveForLaterUse {
  padding-top: 15px;
  margin-bottom: 36px;
  @media (--viewportMedium) {
    padding-top: 8px;
    margin-top: 0px;
    margin-bottom: 48px;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  display: none;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}

.saveForLaterUseLabel {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}
.validCard {
  & span > span:first-child {
    color: #000;
    font-weight: 700;
  }
}
.avlCheck {
  fill: var(--successColor);
}
.saveForLaterUseLegalInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  display: flex;
  padding: 0px 0 0px;
  gap: 15px;
  margin-top: -2px;
  margin-bottom: 2px;
  font-family: 'Open Sans', sans-serif;
  & svg {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
  }

  & > span {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & span {
      text-align: justify;
    }
  }
  @media (--viewportMedium) {
    margin-top: -1px;
    margin-bottom: 1px;
  }
}

.billingHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);
  text-align: center;
  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentLabel {
  margin: 0;

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 2px;
  }
}

.messageHeading {
  color: var(--matterColorAnti);
  margin: 36px 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: auto;

  @media (--viewportLarge) {
    margin-top: 30px;
  }
}

.paymentInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  padding: 0 42px;
  margin: 28px 0 0 0;

  @media (max-width: 575px) {
    padding: 0;
    margin: 20px 0;
  }

  @media (--viewportLarge) {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
    padding: 0;
  }
}

.submitButton {
  color: #fff;
  font-size: 15px;
  padding: 12px 20px;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  border-radius: 5px;
  min-height: auto;
  position: relative;
  margin-top: 26px;
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    margin-top: 17px;
  }
}
.submitButton svg {
  margin-right: 5px;
  /* position: absolute; */
  top: 14px;
  left: 170px;
  width: 20px;
  height: 20px;
}

.submitButton:hover {
  background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
}

.missingStripeKey {
  color: var(--failColor);
}
