@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}
.root input {
  border: 1px solid #cecece;
  padding: 5px 10px;
  border-radius: 6px;
  margin-top: 10px;
  min-height: 45px;
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.recoveryLink {
  @apply --marketplaceModalHelperLink;
}

.recoveryLinkInfo {
  @apply --marketplaceModalHelperText;
}
.infoBtnDiv {
  margin: 40px 0 0 0;
}
.infoBtn {
  background: linear-gradient(
    90deg,
    rgba(201, 59, 84, 1) 27%,
    rgba(146, 27, 152, 1) 72%
  );
  border: none;
  min-height: 42px;
  color: #fff !important;
  border-radius: 30px;
  padding: 6px 25px;
  text-align: center;
  width: 100%;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  &:hover {
    background: linear-gradient(
      90deg,
      rgba(146, 27, 152, 1) 27%,
      rgba(201, 59, 84, 1) 72%
    );
  }
}
.bycrt {
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 16px;
  & span {
    text-decoration: underline;
    cursor: pointer;
    color: #c93b54;
    &:hover {
      text-decoration: none;
    }
  }
}
