.divClass {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btnClass {
  color: var(--marketplaceColor);
  text-decoration: none;
  &:hover {
    & svg {
      /* margin-right: 4px;
    stroke-width: 1px; */
      & > g {
        & > path {
          stroke: var(--matterColorBlack);
          /* color: var(--matterColorBlack); */
        }
      }
    }
  }
}
.printBtnClass {
}
.dwnldSvg {
  height: 17px;
  & > g {
    & > path {
      stroke: var(--marketplaceColor);
    }
  }
}
