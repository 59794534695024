@import '../../styles/propertySets.css';

/* Animation for the hero elements */

.root {
  background: url(../../assets/footerTop.jpeg);

  display: flex;
  justify-content: center;

  width: 100%;
  height: 100%;
  background-size: cover;
  min-height: 700px;
  background-position: top;
  /* Text positioning is done with paddings */
  @media (max-width: 575px) {
    min-height: 600px;
    margin-top: 35px;
  }
}

.footerTopContent {
  margin: 10px 0 0 0;
  text-align: center;
  flex-basis: 60%;
  @media (max-width: 1199px) {
    margin: 20px auto 0;
    flex-basis: 90%;
  }
  @media (max-width: 1199px) {
    margin: 20px auto 0;
    flex-basis: 90%;
  }
  @media (max-width: 575px) {
    margin: 0 auto 0;
    flex-basis: 100%;
    padding: 0 15px;
  }
}

.footerTopMainTitle {
  max-width: 100%;
  font-family: 'Stardos Stencil', cursive;
  text-align: center;
  font-size: 38px;
  color: #000;
  line-height: 50px;
  font-weight: 500;
  margin: 0px auto 60px;
  /* @apply --marketplaceHeroTitleFontStyles; */
  /* color: var(--matterColorDark);

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  } */

  @media (max-width: 991px) {
    line-height: 36px;
    font-size: 30px;
  }
  @media (max-width: 575px) {
    line-height: 34px;
    font-size: 22px;
    margin-bottom: 30px;
  }
}

.footerTopSubTitle {
  @apply --marketplaceH4FontStyles;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.ATcolored {
  color: rgba(201, 59, 84, 1);
}
.subsfooter {
  max-width: 600px;
  background: rgba(0, 35, 54, 0.8);
  margin: 0 auto;
  padding: 30px;
  border-radius: 10px;
  @media (max-width: 767px) {
    max-width: 100%;
  }
  & h2 {
    color: #fff;
    margin: 0 0 40px;
    font-size: 34px;
    font-weight: 500;
    font-family: 'Stardos Stencil', cursive;
    @media (max-width: 575px) {
      font-size: 28px;
      line-height: 34px;
    }
  }
}
.subsinputrow {
  display: flex;
  max-width: 85%;
  margin: 0 auto;
  @media (max-width: 767px) {
    max-width: 100%;
  }
  @media (max-width: 575px) {
    flex-direction: column;
  }
  & input {
    background: #fff;
    padding: 8px 15px;
    border-radius: 8px;
    margin: 0 10px 0 0;
    border: 0;
    font-size: 14px;
    @media (max-width: 575px) {
      margin-bottom: 5px;
    }
  }
  & button {
    background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
    border: none;
    color: var(--matterColorLight);
    border-radius: 8px;
    cursor: pointer;
    padding: 6px 25px;
    min-width: 160px;
  }
}
.sbbtn {
}
.sentsbbtn {
  background-color: green !important;
}
