.cardsContainer{
    display: flex;
    flex-direction: row;
    
}
.card{
    margin-left: 10px;
    margin-top:10px;
    border: 2px solid black;
    border-radius: 6px;
}