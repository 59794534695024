.categoriesCheckbox {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  background: white !important;
  margin: 15px auto;
  border: 1px solid #bbbaba;
  min-height: 64px;
  max-width: 80%;

  @media (max-width: 1199px) {
    max-width: 100%;
  }
}
.pltovsttitle {
  flex-grow: 1;
}
.selectedCategory {
  border-color: #c4385a;
  background: #f2f2f2 !important;
}
.selectedCategory .checkboxLabel {
  background: #f2f2f2;
}
.checkbox {
  display: none;
  vertical-align: middle;
  width: 40%;
}
.checkboxLabel {
  display: flex;
  width: 100%;
  background: white;
  cursor: pointer;
  border-radius: 8px;
  padding: 11px 15px;
  justify-content: center;
  align-items: center;
}
.buttonDiv {
  display: flex;
  align-items: flex-end;
  padding-bottom: 25px;
}
.backButton {
  display: inline-flex;
  align-items: flex-end;
  font-weight: 900;
  font-size: 16px;
  text-decoration: underline;
  color: #676767 !important;
  background: none !important;
  text-align: left;
  max-width: 50px;
  &:hover {
    box-shadow: none;
  }
}

.submitButton {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  border-radius: 8px;
  min-height: auto;
  padding: 10px 15px;
  max-width: 150px;
  margin: 0 0 0 auto;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}
.forsctr {
  padding: 30px !important;
}
.addtl {
  color: #000;
  line-height: 24px;
  margin: 20px 0;
}
.exitButton {
  border-radius: 50px;
  font-weight: 500;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 7px 20px;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  line-height: 18px;
  margin: 0 0 0 auto;
  min-height: 42px;
  width: 115px;
  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  }
  &:disabled {
    cursor: not-allowed;
  }
}
.formseclist {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  margin: 20px 0 30px;
  padding: 0 15px 0 0;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
}
.formlistbtmsec {
  margin: auto 0 0;
  @media (max-width: 767px) {
    margin: 0;
    padding: 0 15px;
    position: sticky;
    bottom: 0;
  }
}
.wordLimit {
  color: #cecece;
}
.svexbtnsec {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.labelDiv span {
  font-size: 14px;
  color: #808080;
  display: block;
  font-weight: 400;
}
.keylbl {
  font-size: 16px !important;
  color: #232323 !important;
  font-weight: 600 !important;
}
.othertxt {
  max-width: 80%;
  margin: 0 auto;
  @media (max-width: 1199px) {
    max-width: 100%;
  }
}
.othertxt label {
  color: #000;
}
.othertxt input {
  border: 1px solid #bbbaba !important;
  margin: 15px 0 0;
  min-height: 64px;
  background: #dfdfdf;
  border-radius: 8px;
  color: #000;
  padding: 0 15px;
  font-size: 16px;
  text-align: center;
}
.othertxt input::-moz-placeholder,
.othertxt input::-webkit-placeholder {
  color: #000;
}
.titleContainer {
  padding: 20px 0;
}
.titleContainer label {
  color: #232323;
  font-size: 16px;
}
.stampwinput {
  display: flex;
  align-items: center;
  padding: 60px 30px;
  border: 1px solid #cecece;
  margin: 15px 0 0;
  border-radius: 6px;

  @media (max-width: 575px) {
    padding: 40px 10px;
  }
}
.stampwinput span {
  color: #232323;
  font-size: 20px;
  margin: 0;
  padding: 0 0 0 10px;
  line-height: 25px;
  @media (max-width: 575px) {
    font-size: 16px;
  }
}
.staptext {
  flex-grow: 1;
  flex-basis: calc(100% - 230px);
  @media (max-width: 575px) {
    flex-basis: calc(100% - 124px);
  }
}
.staptext input {
  border-bottom-color: #b3b3b3;
  color: #000;
  font-size: 20px;
  @media (max-width: 575px) {
    font-size: 16px;
  }
}
.suggestion {
  text-align: right;
}
.suggestion label {
  color: #c93b54;
  font-size: 18px;
  margin: 0 0 15px;
}
.suggestion p {
  color: #808080;
  font-size: 16px;
  margin: 0 0 8px;
}
.suggestion p span {
  color: #232323;
  margin: 0 5px 0 0;
}
.emailWrapper {
}
.emailWrapper {
  margin: 15px 0;
  color: #000;
}
.emailWrapper label {
  font-size: 18px;
  text-align: left;
  line-height: 20px;
  color: #000;
  margin-bottom: 15px;
}
.ptvtitle input {
  text-transform: capitalize;
}
.emailWrapper input {
  border: 1px solid #cecece !important;
  border-radius: 8px !important;
  min-height: 50px;
  padding: 5px 15px;
  color: #232323;
  font-size: 16px;
}
.customControl {
  box-shadow: none !important;
  border: 0 !important;
  position: relative;
  & .astx {
    color: #c93b54;
    position: absolute;
    right: 0px;
    top: -2px;
  }
}
.customValueContainer {
  align-items: flex-end !important;
  min-width: 90px;
  padding: 0 !important;
}
.classTypeSelect {
}
.classsuggestion {
  text-align: left !important;
}
