.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}
.allowedAgeOptions {
  display: flex;
  flex-direction: row;
}
.ageButton {
  /* margin: 10px 10px; */
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #000;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #c93b54;
}
.onplbl {
  margin-top: 50px;
}
.plprcdiv {
  display: flex;
  justify-content: space-between;
  & > div:nth-child(2) {
    /* background-color: red; */
    margin-bottom: 0 !important;
  }
}
.plselect {
  flex-basis: 45%;
  max-width: 45%;
  color: #000;
}
.flexprice {
  text-align: center;
  display: flex;
  color: #000;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.age {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.tktpr {
  color: #c93b54;
  text-align: center;
  margin-bottom: 30px;
}
.exitButton {
  border-radius: 50px;
  font-weight: 500;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 7px 20px;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  line-height: 18px;
  margin: 0 0 0 auto;
  min-height: 42px;
  width: 115px;
  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  }
  &:disabled {
    cursor: not-allowed;
  }
}
.svexbtnsec {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.unsetwdh {
  & label {
    width: unset !important;
  }
}
.formlistbtmsec {
  margin: auto 0 0;
  @media (max-width: 767px) {
    margin: 0;
    padding: 0 15px;
    position: sticky;
    bottom: 0;
  }
}
.buttonDiv {
  display: flex;
  align-items: flex-end;
  padding-bottom: 25px;
}
.backButton {
  display: inline-flex;
  align-items: flex-end;
  font-weight: 900;
  font-size: 16px;
  text-decoration: underline;
  color: #676767 !important;
  background: none !important;
  text-align: left;
  max-width: 50px;
  &:hover {
    box-shadow: none;
  }
}

.submitButton {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  border-radius: 8px;
  min-height: auto;
  padding: 10px 15px;
  max-width: 150px;
  margin: 0 0 0 auto;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}
.agefigure {
  margin: 0 25px;
  font-size: 18px;
}
.type {
  padding: 0 20px 0 0;
}
.noRestrictionMsg {
  color: var(--successColor);
}
.error {
  color: red;
  /* color: var(--failColor); */
}

.formseclist {
  max-height: calc(100vh - 210px);
  min-height: calc(100vh - 245px);
  overflow-y: auto;
  margin: 20px 0 30px;
  padding: 0 15px 0 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    padding: 0 15px;
    min-height: unset;
    max-height: unset;
  }
}
.agecon {
  margin: auto;
}
.rowprice {
  margin: 0 0 30px;
}
.mainoutrow label {
  color: #c93b54;
  text-align: center;
  font-size: 18px;
  margin: 0 0 20px;
  font-weight: 700;
}
.mainoutrow label span {
  color: #000;
}
.txtpr {
  color: #000;
  font-weight: 600;
  text-align: center;
  margin: 0 0 45px;
}
.mainPrice {
  display: flex;
  position: relative;
  margin: 0 20px;
  max-width: 150px;
  justify-content: center;
  align-items: center;
}
.cntspn {
  color: #cecece;
}
.mainPrice input {
  border: 2px solid #cecece !important;
  border-radius: 8px;
  min-height: 50px;
  padding: 5px 15px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.mainPrice span {
  position: absolute;
  left: auto;
  right: auto;
  margin: 0 0 0 -30px;
  top: 9px;
  font-size: 24px;
  font-weight: 600;
  padding: 0 8px 0 0;
  @media (max-width: 767px) {
    top: 12px;
  }
}
.siblingDiscountCheck {
  display: flex;
  align-items: center;
  margin: 0 0 50px;
  justify-content: center;

  @media (max-width: 575px) {
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 15px;
  }
}
.siblingDiscountCheck input {
  width: 20px;
  margin: 0 15px 0 0;
  height: 20px;
  display: none;
}
.siblingDiscountCheck label {
  margin: 0;
  padding: 0;
  position: relative;
  text-align: left !important;
  line-height: 24px;
  width: 250px;
  white-space: nowrap;
  font-weight: 700;
  & span {
    font-weight: 600;
  }
  @media (max-width: 575px) {
    text-align: left;
    font-size: 16px;
    width: unset;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 360px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-left: 28px;
  }
  @media (min-width: 992px) {
    width: 320px;
  }
  @media (min-width: 1199px) {
    width: 360px;
  }
}
.siblingDiscountCheck .checkicon {
  margin-right: 10px;
}
.siblingDiscountCheck .checkicon svg {
  width: 25px;
  height: 25px;
  @media (max-width: 575px) {
    width: 20px;
    height: 20px;
  }
}
.sbfstrow {
  margin: 0 15px;
}
.description {
  min-height: 200px;
  line-height: 32px;
  border: 1px solid #cecece !important;
  padding: 20px;
  box-sizing: border-box;
  color: #000;
  border-radius: 8px;
  resize: none;
  font-size: 18px;
  font-weight: 600;
  @media (max-width: 575px) {
    min-height: 400px;
  }
}
.calendarWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  & input {
    display: none;
  }
  & :global(.DateInput) {
    display: none;
  }
  & :global(.SingleDatePicker_picker) {
    position: static;
  }
  & :global(.DayPickerNavigation_button) {
    position: absolute;
    width: 28px;
    height: 28px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    & svg {
      width: 24px;
      height: 44px;
      fill: #fff;
    }
  }
  & :global(.CalendarDay__selected) {
    background: linear-gradient(
      45deg,
      rgba(201, 59, 84, 1) 27%,
      rgba(146, 27, 152, 1) 72%
    ) !important;
    font-weight: 800;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.5);
  }
  & :global(.DayPickerNavigation__horizontal) {
    & > div:last-child {
      top: 10px;
      right: 12px;
    }

    & > div:first-child {
      top: 10px;
      left: 12px;
      align-items: flex-start;
    }
  }
  /* & :global(.CalendarMonth_caption){
    background-color: var(--marketplaceColor)
  } */
}
.trialMsg {
  margin: 0 auto 50px auto;
}
.trialMsg textarea {
  margin: 0 auto;
  border: 2px solid #cecece;
  border-radius: 8px;
  padding: 10px;
  max-width: 300px;
  min-height: 80px;
  color: black;
  font-size: 14px;
  resize: none;
  box-sizing: border-box;
}
.trialMsg p {
  color: #cecece;
  margin: 0 auto;
  max-width: 300px;
  font-size: 15px;
}
.dywto {
  @media (max-width: 575px) {
    padding: 0 0px 0 24px;
  }
}
.btnchkm {
  margin-bottom: 24px;
}
.pvpricelabel {
  color: #000;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  & span {
    color: #c93b54;
  }
}
.prroelbl label {
  color: #000;
  text-align: center;
  font-size: 18px;
  margin: 0 0 20px;
  & span {
    color: #c93b54;
    font-weight: 700;
  }
}
.mainoutinp {
  display: flex;
  flex-wrap: wrap;
}
.mainoutinp .siblingDiscountCheck {
  flex-basis: 33%;
}
.priceType {
  display: flex;
  justify-content: space-evenly;
}
.pltvphstpn {
  margin-bottom: 10px;
  font-weight: 700;
}
.pltvphspn {
  display: flex !important;
  max-width: 50%;
  margin: 0 auto 0 0;
  justify-content: space-between;
}
.fivecats {
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  padding-left: 40px;
  margin-bottom: 20px;
  justify-content: flex-start !important;
  & > div {
    margin: 0 0 3px;
    flex-basis: 170px;
    justify-content: flex-start !important;
    padding-left: 25px;
    /* @media (max-width: ) */
  }
}
.opncls {
  color: #c93b54;
  font-weight: 700;
  top: 1px;
  position: relative;
}
.opnclsdiv {
  display: flex;
  border: 1px solid #cecece !important;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 30px;
  align-items: center;
  & input {
    border: none;
    padding-left: 10px;
    color: #000;
  }
}
.opnclsmaindiv {
  & label {
    margin-bottom: 20px;
    font-weight: 700;
    & span {
      font-weight: 600;
    }
  }
}
.openTillDiv {
  & label {
    font-weight: 700;
  }
}
.labelSpan {
  font-weight: 600;
}
.phdv {
  min-height: 200px;
  line-height: 20px;
  border: 1px solid #cecece !important;
  padding: 20px;
  box-sizing: border-box;
  color: #cecece;
  border-radius: 8px;
  resize: none;
  font-size: 16px;
  & span {
    display: block;
  }
}
.tbspn {
  margin-left: 15px;
}
.optdisbl div:nth-child(3) > div label,
.optdisbl div:nth-child(4) > div label {
  color: #bfaeae !important;
  & span {
    color: #bfaeae !important;
  }
  & rect {
    stroke: #bfaeae !important;
  }
}
.tooltip {
  position: absolute;
  /* bottom: -0px;
  left: 15px; */
  cursor: pointer;
  color: #000;
  top: 50%;
  left: 15px;
  transform: translate(0%, -100%);
  @media (max-width: 767px) {
    left: 10px;
    transform: translate(0%, -50%);
  }
  & svg {
    width: 20px;
    height: 20px;
  }
}
.ttt {
  max-width: 320px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-color: #002435;
  color: #fff !important;
  font-weight: 600 !important;
  border: none;
  font-size: 13px !important;
  padding: 0px;
  text-align: left !important;
  white-space: normal;
  & :global(.rc-tooltip-arrow) {
    border-left-color: #002435;
  }
}
.tooltipContainer {
  position: relative;
}
.withafter {
  position: relative;
  &::after {
    position: absolute;
    content: 'Over 30% of trials turn in full bookings';
    left: 35px;
    top: 23px;
    color: #7b7b7b;
    font-weight: 400;
    font-size: 15px;
    @media (max-width: 575px) {
      left: 30px;
      font-size: 13px;
    }
  }
}
.zeroPrcInfo {
  border: 1px solid #cecece;
  border-radius: 10px;
  color: #000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  & p {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
  }
}
.iUnderStand {
  border-radius: 50%;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  width: 35px;
  height: 35px;
  border: 0;
  padding: 4px;
  cursor: pointer;
  & > span {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: inherit;
    background: #fff;
    & svg {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.iUnderStandDiv {
  color: #c93b54;
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 15px;
  font-weight: 700;
  font-size: 17px;
}
.secContent {
  justify-content: space-between;
}
