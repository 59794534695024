.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border: 0;
  /* padding: 11px 24px 0 24px; */
  background-color: #fff;
}

.form {
  flex-grow: 1;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 19px;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}
.modalContainer {
  border: 0;
  margin: 50px 10px;
  position: relative;
  width: 100%;
  height: 100%;
  /* padding: 30px 30px; */

  & > button {
    /* position: absolute; */
    z-index: 10000000000;
    top: -50px;

    & > span {
      /* display: none; */
      z-index: 10000000000;
    }

    & svg {
      display: none;
    }
  }
}
.modalContent {
  background-color: #fff;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.sectionMiddle {
  display: flex;
  align-items: center;
  /* padding: 50px 50px; */
  background-color: var(--matterColorNegative);
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}
/* .imgSection {
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);

  flex-basis: 50%;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  color: #fff;
  font-size: 50px;
  font-weight: 900;
  padding-left: 60px;
} */
.rightSection {
  background-color: #fff;
  color: var(--matterColorBright);
  flex-basis: 50%;
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  position: relative;
  padding: 15px 30px;
  max-height: 100vh;
  @media (max-width: 767px) {
    min-height: auto;
    position: inherit;
    padding: 0 0 0;
    width: 100%;
  }
}
