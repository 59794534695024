.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}
.allowedAgeOptions {
  display: flex;
  flex-direction: row;
}
/* .ageButton { */
/* margin: 10px 10px; */
/* width: 50px;
  height: 50px;
  border-radius: 30px;
  border: 1px solid #000;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
} */
.ageButton {
  /* margin: 10px 10px; */
  width: 80px;
  height: 80px;
  border-radius: 40px;
  border: 1px solid #000;
  font-size: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #c93b54;
  @media (min-width: 767px) and (max-width: 991px) {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    font-size: 38px;
  }
  @media (max-width: 575px) {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    font-size: 38px;
  }
}
.minAgeContainer {
  margin-bottom: 70px;
}
.minAgeContainer,
.maxAgeContainer {
  text-align: center;
  display: flex;
  color: #000;
  align-items: center;
  @media (min-width: 767px) and (max-width: 991px) {
    flex-direction: column;
  }
  @media (max-width: 575px) {
    flex-direction: column;
  }
}
.otherCheckboxesLabel > div:first-child,
.minAgeContainer label,
.maxAgeContainer label {
  padding: 0;
  font-size: 25px;
  margin: 0 35px 0 0;
  @media (min-width: 767px) and (max-width: 991px) {
    margin: 0 25px 50px 0;
    font-size: 25px;
  }
  @media (max-width: 575px) {
    margin: 0 30px 50px 0;
    font-size: 25px;
  }
}
.age {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.exitButton {
  border-radius: 50px;
  font-weight: 500;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 7px 20px;
  cursor: pointer;
  background: linear-gradient(
    90deg,
    rgba(201, 59, 84, 1) 27%,
    rgba(146, 27, 152, 1) 72%
  );
  line-height: 18px;
  margin: 0 0 0 auto;
  min-height: 42px;
  width: 115px;
  &:hover {
    background: linear-gradient(
      90deg,
      rgba(146, 27, 152, 1) 27%,
      rgba(201, 59, 84, 1) 72%
    );
  }
  &:disabled {
    cursor: not-allowed;
  }
}
.svexbtnsec {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.formlistbtmsec {
  margin: auto 0 0;
  @media (max-width: 767px) {
    margin: 0;
    padding: 0 15px;
    position: sticky;
    bottom: 0;
  }
}
.buttonDiv {
  display: flex;
  align-items: flex-end;
  padding-bottom: 25px;
}
.backButton {
  display: inline-flex;
  align-items: flex-end;
  font-weight: 900;
  font-size: 16px;
  text-decoration: underline;
  color: #676767 !important;
  background: none !important;
  text-align: left;
  max-width: 50px;
  &:hover {
    box-shadow: none;
  }
}

.submitButton {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  border-radius: 8px;
  min-height: auto;
  padding: 10px 15px;
  max-width: 150px;
  margin: 0 0 0 auto;
  cursor: pointer;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}
.agefigure {
  margin: 0 25px;
  font-size: 25px;
  min-width: 50px;
  @media (min-width: 767px) and (max-width: 991px) {
    margin: 0 20px;
    min-width: 45px;
  }
  @media (max-width) {
    margin: 0 20px;
    min-width: 45px;
  }
}
.type {
  padding: 0 20px 0 0;
}
.noRestrictionMsg {
  color: var(--successColor);
}
.error {
  color: red;
  /* color: var(--failColor); */
}

.formseclist {
  min-height: calc(100vh - 250px);
  overflow-y: auto;
  margin: 0px 0 30px;
  padding: 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    padding: 0 15px;
    min-height: calc(100vh - 400px);
  }
}
.agecon {
  margin: auto;
}
.siblingDiscountCheck {
  display: flex;
  align-items: center;
  margin: 50px 0 0px;
  justify-content: center;

  @media (max-width: 575px) {
    align-items: flex-start;
  }
}
.siblingDiscountCheck input {
  width: 20px;
  margin: 0 15px 0 0;
  height: 20px;
  opacity: 0;
  display: none;
}
.siblingDiscountCheck label {
  margin: 0;
  display: flex;
  padding: 0;
  position: relative;
  padding: 0;
  font-size: 25px;
  color: #000;
  flex-grow: 1;
  & > div {
    margin: 0 35px 0 0;
    display: flex;
    align-items: center;
  }
  @media (max-width: 575px) {
    text-align: left;
    font-size: 14px;
  }
}
.siblingDiscountCheck .checkicon {
  /* position: absolute; */
  & svg {
    width: 34px;
    height: 34px;
  }
}

.siblingDiscountCheck label span {
  color: #000;
  font-size: 18px;
}
.butcon {
  display: flex;
  align-items: center;
}
