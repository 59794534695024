@import '../../styles/propertySets.css';

.root {
  margin-top: 24px;
}

@media (--viewportMedium) {
  .root {
    margin-top: 48px;
  }
}

.disabled {
  opacity: 0.5;
}

.sectionContainer {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
  }
}

.formRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.radioButtonRow {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
  white-space: nowrap;
}

.radioButtonRow > :first-child {
  margin-right: 36px;
}

.selectCountry {
  margin-bottom: 24px;
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.termsText {
  @apply --marketplaceModalHelperText;
  margin-bottom: 12px;
  text-align: center;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.termsLink {
  @apply --marketplaceModalHelperLink;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.bankDetailsStripeField p {
  @apply --marketplaceH4FontStyles;
}

.missingStripeKey {
  color: var(--failColor);
}

.accountInformationTitle {
  @apply --marketplaceH3FontStyles;
  margin: 0;
  font-size: 14px;
  padding: 0;
  line-height: 16px;

  /* @media (--viewportMedium) {
  .accountInformationTitle {
    padding-top: 4px;
    padding-bottom: 4px;
  } */
}

.savedCountry {
  border: 1px solid #cecece !important;
  color: #000;
  min-height: 50px;
  border-radius: 6px;
  padding: 0 25px;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 24px;
}

.savedInformation {
  margin-bottom: 24px;
}
.savedInformation > button:last-child {
  border: 1px solid #cecece !important;
  color: #000;
  min-height: 50px;
  border-radius: 6px;
  padding: 0 25px;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.savedBankAccount {
  width: 100%;
  border-bottom: 2px solid var(--successColor);
  margin-bottom: 24px;
  padding: 4px 0 10px 0;
  color: var(--matterColor);
  text-align: left;

  &:hover {
    cursor: text;
    text-decoration: none;
    border-color: var(--matterColor);
  }

  &:focus {
    outline: none;
    border-color: var(--matterColor);
  }
}
.submitButton {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  font-size: 16px;
  border-radius: 8px;
  min-height: 40px;
  padding: 3px 25px;
  color: #fff;
  cursor: pointer;
  border: none;
  width: 150px;
  font-weight: 600;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
  &:disabled {
    opacity: 0.7;
  }
}
