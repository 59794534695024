@import '../../styles/propertySets.css';

.root {
  position: relative;
  background: #cecece;
}
.bhspn {
  position: absolute;
  top: 27px;
  left: 110px;
  font-size: 22px;
  font-weight: 700;
  font-family: 'Stardos Stencil', cursive;
  color: #000;
}
.bhspn::after {
  content: '';
  width: 60%;
  height: 2px;
  background-color: #c93b54;
  position: absolute;
  bottom: 0;
  left: 0;
}
.parent {
  box-shadow: 0px 0px 11px 1px grey;
  width: 90%;
  margin: 100px auto;
  border-radius: 3px;
  max-width: 992px;
  @media (min-width: 1201px) {
    width: 70%;
  }
  @media (min-width: 576px) {
    width: 80%;
  }
}
.container {
  display: flex;
  flex-direction: column;
  padding: 20px 50px 30px;
  background: #fff;
  @media (max-width: 767px) {
    padding: 25px 35px;
  }
  @media (max-width: 575px) {
    padding: 20px 20px;
  }

  /* @media (--viewportLarge) {
    flex-direction: row;
    max-width: 1128px;
    margin: 0 auto 57px auto;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  } */
}
.dvone {
  display: flex;
  padding-bottom: 30px;
  border-bottom: 1px solid #cecece;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.dvone img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dvone > div:last-child {
  margin-left: 30px;
  min-width: 50%;
  @media (max-width: 767px) {
    flex-direction: column;
    margin-left: 0px;
    margin-top: 20px;
    padding: 0 20px;
  }
}
.dvone .listTitle {
  padding-right: 20px;
  letter-spacing: 1px;
  font-size: 30px;
  font-weight: 700;
  margin: 0 5px 0 0;
  color: #000;
  line-height: 36px;
  cursor: pointer;

  @media (max-width: 767px) {
    padding-right: 0px;
  }
}
.isPlink {
  &:hover {
    text-decoration: underline;
  }
}
.dvone > div:last-child > p:nth-child(2) {
  font-size: 22px;
  color: #c93b54;
  font-weight: 600;
}
.linfodv > span:first-child {
  color: #959595;
  font-weight: 600;
  display: inline-block;
  width: 80px;
}
.linfodv > span:last-child {
  color: #000;
}
.dvth,
.dvto {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  border-bottom: 1px solid #cecece;
  margin-bottom: 30px;
}
.dvth > div,
.dvfr > div,
.dvto > div {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  & span:first-child {
    color: #000;
  }
  & span:last-child {
    color: #959595;
    text-align: right;
  }
}
.dvto > div:first-child {
  margin-top: 0 !important;
  & span:first-child {
    font-weight: 600;
    font-family: 'Stardos Stencil', cursive;
  }
}

.dvth > div:last-child,
.dvto > div:last-child {
  margin-bottom: 0 !important;
}
.dvth > div:first-child {
  margin-top: 0 !important;
}
.dvth > div > span:first-child {
  font-weight: 600;
  color: #000;
}
.dvth {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 20px !important;
}
.tamp {
  & > span {
    font-weight: 700 !important;
    color: #000 !important;
  }
}
.dvfr {
  background-color: #cecece;
  margin: 0 -15px 30px;
  padding: 8px 15px;
}
.dvfr > div > span:first-child {
  color: #000;
  font-weight: 700;
}
.dvfr > div > span:last-child {
  color: #000;
  font-weight: 700;
}
.dvfv {
  border-top: 1px solid #cecece;
  padding-top: 20px;
}
.dvfv p {
  margin: 0;
}
.dvfv p:first-child {
  font-family: 'Stardos Stencil', cursive;
  color: #000;
  font-weight: 600;
  font-size: 20px;
}
.btndv {
  background: #002435;
  display: flex;
  justify-content: space-between;
  padding: 15px 50px;

  @media (max-width: 575px) {
    padding: 15px 10px;
  }
}
.btndv > button:first-child {
  border: 0;
  text-decoration: underline;
  color: #fff;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
.btndv > div {
  display: flex;
  gap: 10px;
}
.btndv > div > button {
  border-radius: 6px;
  border: 0px;
  color: #fff;
  font-size: 15px;
  padding: 5px 20px;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  margin: auto 0;
  display: inline-block;
  cursor: pointer;
  width: 130px;
  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  }
  @media (max-width: 575px) {
    width: unset;
    min-width: 100px;
  }
}

.txInfo {
  margin-bottom: 210px;

  @media (--viewportLarge) {
    flex-basis: 538px;
    flex-grow: 0;
    flex-shrink: 1;
    margin-right: 56px;
    margin-bottom: 0;
  }
}
.bookedFor {
  margin: 0 48px;
  display: flex;
  flex-direction: column;
  & p:first-child {
    font-size: 14px;
    margin: 20px 0 10px 0;
  }
  & p {
    font-size: 14px;
    margin: 0;
    height: 20px;
  }
}
.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}
.genericError {
  color: var(--failColor);
  margin: 24px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 32px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 32px 0 0 0;
  }
}

/* DetailCardImage subcomponent */
.detailCardImageWrapper {
  /* Layout */
  display: none;
  width: 100%;
  position: relative;

  @media (--viewportLarge) {
    display: block;
    max-height: 268px;
    overflow-y: hidden;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -30px;

  /* Rendering context to the same lavel as listing image */
  position: relative;
  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    margin-top: -32px;
    padding: 2px 0;
  }
  @media (--viewportLarge) {
    margin-left: 48px;
    width: unset;
    padding: 2px 0;
  }
}

/* Passed-in props for DetailCardImage subcomponent */
.imageWrapperMobile {
  position: relative;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperMobile {
  composes: avatarWrapper;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperDesktop {
  composes: avatarWrapper;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.avatarWrapperProviderDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: 119px;
  }
}
.avatarDesktop {
  @media (--viewportLarge) {
    display: flex;
  }
}

/* PanelHeadings subcomponent */
.headingOrder {
  margin: 29px 24px 0 24px;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    margin: 152px 0 0 0;
    padding: 0;
  }
}

.headingSale {
  margin: 18px 24px 0 24px;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    margin: 42px 0 0 0;
    padding: 0;
  }
}

.mainTitle {
  display: block;
}

.transactionInfoMessage {
  margin: 18px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 23px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 23px 0 0 0;
  }
}

/* Container for booking details in mobile layout */
.bookingDetailsMobile {
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 40px;
    padding: 4px 0 0px 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

/* "aside" section in desktop layout */
.asideDesktop {
  margin: 1px 0 0 0;

  /**
   * Aside is visible on mobile layout too, since it includes BookingPanel component.
   * It might get rendered as a Modal in mobile layout.
   */

  @media (--viewportLarge) {
    margin: 123px 0 0 0;
  }
}

.detailCard {
  @media (--viewportLarge) {
    position: sticky;
    top: -200px; /* This is a hack to showcase how the component would look when the image isn't sticky */
    width: 409px;
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 2px;
    z-index: 1;
  }
}

/* DetailCardHeadingsMaybe subcomponent */
.detailCardHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 48px 33px 48px;
  }
}

.detailCardTitle {
  margin-bottom: 10px;

  @media (--viewportLarge) {
    margin-top: 14px;
    margin-bottom: 0;
  }
}

.detailCardSubtitle {
  @apply --marketplaceH5FontStyles;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 9px;
    margin-bottom: 0;
  }
}

/* AddressLinkMaybe subcomponent */
.address {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0;
}

/* Passed-in props for AddressLinkMaybe subcomponent */
.addressMobile {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0 24px 24px 24px;
}

/* BreakdownMaybe subcomponent */
.breakdownMaybe {
  /* default "root" class for breakdown container */
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin: 0 24px 0 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 26px 48px;
    margin: 32px 48px 24px 48px;
    padding: 4px 0 4px 0;
  }
}

.breakdown {
  margin: 14px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 18px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 24px 48px 47px 48px;
    padding: 6px 0 2px 0;
  }
}

.breakdownContainer {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* FeedSection subcomponent */
.feedHeading {
  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.feed {
  margin-top: 20px;
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

/* Passed-in props for FeedSection subcomponent */
.feedContainer {
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 40px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 43px 0 0 0;
  }
}

/* Prop to be passed to SendMessageForm component */
.sendMessageForm {
  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

.sendingMessageNotAllowed {
  color: var(--matterColorAnti);

  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

/* SaleActionButtonsMaybe subcomponent */
.actionButtons {
  /* Position action button row above the footer */
  z-index: 9;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 18px 24px 18px 24px;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  box-shadow: var(--boxShadowTop);
  background-color: white;

  @media (--viewportLarge) {
    z-index: unset;
    position: static;
    box-shadow: none;
    width: auto;
    margin: 0 48px 0 48px;
    padding: 0;
  }
}

.actionButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (--viewportLarge) {
    flex-direction: column;
  }

  & button:first-child {
    margin: 0 12px 0 0;

    @media (--viewportLarge) {
      margin: 8px 0 0 0;

      /* Switch order in desktop layout with accept button being on the top */
      order: 1;
    }
  }
}

.actionError {
  @apply --marketplaceH5FontStyles;
  color: var(--failColor);
  margin: 0 0 11px 0;

  @media (--viewportMedium) {
    margin: 0 0 10px 0;
  }
  @media (--viewportLarge) {
    margin: 0;
  }
}

.actionErrors {
  width: 100%;
  text-align: center;

  @media (--viewportLarge) {
    position: absolute;
    top: 151px;
  }
}

/* Container for SaleActionButtonsMaybe subcomponent */
.mobileActionButtons {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopActionButtons {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 48px;
  }
}

/* BookingPanel subcompnent */
.bookingPanel {
  margin: 16px 48px 48px 48px;
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 1px;
}
.rvdv {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #cecece;
}
.rvdv h2 {
  font-weight: 600;
  font-family: 'Stardos Stencil', cursive;
  color: #000;
  margin-top: 0;
  & span {
    color: #c93b54;
  }
  & span:last-child {
    color: #6f6f6f;
    font-size: 16px;
  }
}
.echrv {
}
.echrv > div {
  display: flex;
  align-items: center;
  & img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
  & div > p {
    margin: 0;
  }
}
.echrv > p {
  margin: 10px 0 0;
}
.strdv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  & span {
    display: block;
    line-height: 20px;
  }
  & span:first-child {
    margin-left: 2px;
  }
}
.authrev {
  & > div {
    justify-content: flex-end;
  }
  & > p {
    text-align: right;
  }
}
.tooltipInfo {
  display: none;
}
.tooltip {
  position: absolute;
  top: -7px;
  left: 5px;
  cursor: pointer;
  &:hover + .tooltipInfo,
  &:focus + .tooltipInfo {
    display: block;
  }
}
.tooltipInfo {
  display: none;
  position: absolute;
  min-width: 180px;
  top: 0px;
  left: 5px;
  transform: translate(-50%, -100%);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  /* background-color: rgb(227 227 227 / 75%); */
  background-color: #002435;
  color: #fff !important;
  font-weight: 600 !important;
  border: none;
  font-size: 13px !important;
  pointer-events: none;
  padding: 10px 10px;
  text-align: left !important;
  &::after {
    content: '';
    position: absolute;
    bottom: -0.375rem;
    left: 50%;
    top: 100%;
    width: 12px;
    height: 12px;
    background-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg) translate(-50%, -50%);
    border-color: #002435;
    /* border-color: rgb(227 227 227 / 75%); */
    border-style: solid;
    border-width: 6px;
    z-index: 0;
  }
}
.tooltipContainer {
  position: relative;
  flex-grow: 1;
}
.typ {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  color: #c93b54;
  font-weight: 700;
  margin: 0;
  background: #fff;
  padding: 15px 0 0;
}
