.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}
.allowedAgeOptions {
  display: flex;
  flex-direction: row;
}
.ageButton {
  /* margin: 10px 10px; */
  width: 50px;
  height: 50px;
  border-radius: 30px;
  border: 1px solid #000;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.minAgeContainer {
  margin-bottom: 50px;
}
.minAgeContainer,
.maxAgeContainer {
  text-align: center;
  display: flex;
  color: #000;
  align-items: center;
}
.minAgeContainer label,
.maxAgeContainer label {
  padding: 0;
  font-size: 16px;
  margin: 0 25px 0 0;
}
.age {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.exitButton {
  border-radius: 50px;
  font-weight: 500;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 7px 20px;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  line-height: 18px;
  margin: 0 0 0 auto;
  min-height: 42px;
  width: 115px;
  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  }
  &:disabled {
    cursor: not-allowed;
  }
}
.svexbtnsec {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.formlistbtmsec {
  margin: auto 0 0;
  @media (max-width: 767px) {
    margin: 0;
    padding: 0 15px 15px;
    position: sticky;
    bottom: 0;
  }
}
.buttonDiv {
  display: flex;
  align-items: flex-end;
  padding-bottom: 25px;
}
.backButton {
  display: inline-flex;
  align-items: flex-end;
  font-weight: 900;
  font-size: 16px;
  text-decoration: underline;
  color: #676767 !important;
  background: none !important;
  text-align: left;
  max-width: 50px;
  &:hover {
    box-shadow: none;
  }
}

.submitButton {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  border-radius: 8px;
  min-height: auto;
  padding: 10px 15px;
  max-width: 150px;
  margin: 0 0 0 auto;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}
.agefigure {
  margin: 0 25px;
  font-size: 18px;
}
.type {
  padding: 0 20px 0 0;
}
.noRestrictionMsg {
  color: var(--successColor);
}
.error {
  color: red;
  /* color: var(--failColor); */
}

.formseclist {
  min-height: calc(100vh - 250px);
  overflow-y: auto;
  margin: 20px 0 30px;
  padding: 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
  @media (max-width: 575px) {
    min-height: unset;
  }
}
.agecon {
  margin: auto;
}
.bborow {
  display: flex;
  min-width: 100%;
  align-items: center;
  flex-direction: column;
  margin: 10px 0;
}
.bborow input {
  width: 24px;
  margin: 0 0 0 0;
  display: none;
}
.bborow label {
  flex-basis: 80%;
  color: #000;
  padding: 0;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.4);
  position: relative;
  margin: 0 0 0 0;
  width: 100%;
  max-width: 520px;
  cursor: pointer;
  border-radius: 10px;
  & > div > div {
    border-radius: 10px;

    background: #fff;
    padding: 20px;
    border-radius: 10px;
  }
  @media (max-width: 1199px) {
    margin: 0 0 0 0;
  }

  @media (max-width: 575px) {
    margin: 0 0 0 0;
    flex-basis: 90%;
  }
}
.bborow .checkicon svg {
  width: 24px;
  margin: 0 30px 0 0;
  height: 40px;
}
.checkicon {
  position: absolute;
  left: -60px;
  top: 43px;
  @media (max-width: 575px) {
    top: auto;
    margin: 10px 0 0;
    left: -40px;
  }
}
.bborow p {
  color: #808080;
  margin: 0;
  line-height: 26px;
  @media (max-width: 575px) {
    line-height: 30px;
    font-size: 17px;
  }
}
.bborow h2 {
  text-decoration: underline;
  margin: 0;
}
.ibbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.ibbox h2 {
  font-size: 14px;
}

.lmbtn {
  color: #676767;
  margin: 20px 0;
  display: block;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
}
.bookingTypeDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bookingTypeDiv > h2 {
  color: #232323;
  margin-top: 0;
  font-size: 22px !important;
  line-height: 36px;
  font-weight: 700;
}
.bookingTypeDiv h2 span {
  color: #c93b54;
}
.tooltip {
  position: absolute;
  top: -7px;
  left: 5px;
  cursor: pointer;
  padding: 0;
  border: 0;
}

.tooltipInfo {
  border: none;
  color: black;
  display: block;
  padding: 10px 10px;
  text-align: center;
  & > span {
    display: flex;
    text-align: left;
  }
  & svg {
    /* position: absolute; */
    flex-shrink: 0;
    top: 5px;
    width: 25px;
    height: 25px;
  }
  /* &::after {
    content: '';
    position: absolute;
    bottom: -0.375rem;
    left: 50%;
    top: 100%;
    width: 12px;
    height: 12px;
    background-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg) translate(-50%, -50%);
    border-color: transparent #faf9f7 #faf9f7;
    border-style: solid;
    border-width: 6px;
    z-index: 0;
  } */
}
.xprstool {
  top: 260px;
  left: 11px;
  /* &::after {
    display: none;
  }
  &::before {
    content: '';
    position: absolute;
    left: 46%;
    top: -7px;
    width: 12px;
    height: 12px;
    background-color: transparent;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg) translate(-50%, -50%);
    border-color: transparent #faf9f7 #faf9f7;
    border-style: solid;
    border-width: 6px;
    z-index: 0;
  } */
}
.prospn {
  margin: 5px 0;
  position: relative;
  & > span {
    display: block;
    margin-left: 14px;
    line-height: 24px;
    @media (max-width: 575px) {
      font-size: 13px;
    }
  }
}
.tooltipContainer {
  position: relative;
  flex-grow: 1;
}
.headspn {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 5px 5px;
  display: inline-block !important;
  @media (max-width: 575px) {
    font-size: 20px;
  }
  & > span:first-child {
    color: #c93b54;
  }
  & > span:last-child {
  }
}
.referalLink {
  width: 80%;
  margin: 0 0 0 0;
  @media (max-width: 1199px) {
    margin: 0 0 0 0;
  }
}
.referalLink input {
  display: block;
  width: 100%;
  margin: 0;
  border: 2px dashed #d1d1d1 !important;
  padding: 6px 15px;
  color: #232323;
}
.referalLink::-webkit-input-placeholder {
  opacity: 1;
  color: #000;
}
.urlerr input {
  border: 3px dashed red !important;
}
.invspn {
  color: red;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: block;
}
.termsWrapper {
  color: #232323;
  background: #fff;
}
.mainContent {
  margin: auto 0;
}
.mainContent h2 {
  font-size: 18px;
  color: #000;
  font-weight: 700;
}
.bborowbtmm {
  /* margin-bottom: 40px; */
}
.selectedCheckboxLabel {
  background: #002435;
}
.selectedCheckboxLabel {
  & > div {
    background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
    border-radius: 10px;

    border: 3px solid transparent;
    & > div {
      background: #002435 !important;
      & .tckspn {
        border: 2px solid #c93b54 !important;
      }
      & .ibbox h2 {
        color: #fff !important;
      }
      & p {
        color: #fff !important;
      }
    }
  }
}
.mustinc {
  color: #a49f9f;
}
.hiderflnk {
  visibility: hidden;
}
.decoyDiv {
  background: #fff;
  border-radius: 10px;

  /* border: 3px solid transparent; */
  & > div {
    position: relative;
  }
}
.tckspn {
  position: absolute;
  display: flex;
  width: 35px;
  height: 30px;
  align-items: center;
  justify-content: center;
  top: 5px;
  right: 5px;
  background: #fff;
  border-radius: 5px;
  border: 2px solid #000;
  & svg {
    width: 27px;
    height: 24px;
    fill: #000;
  }
  .subInfo {
    display: none;
    background-color: #cecece;
    width: 420px !important;
    position: absolute;
    right: 27px;
    top: 27px;
    border-radius: 15px;
    padding: 15px 15px 25px 25px;
    box-shadow: 7px 5px 17px 0px #cecece;
    border-top-right-radius: 0px;
    z-index: 1;
    & > span:first-child {
      color: #c93b54;
      display: block;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 15px;
    }
    & span:last-child {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 575px) {
      width: calc(100vw - 60px) !important;
    }
  }
  .subInfoArro {
    display: none;
    clip-path: polygon(50% 0%, 25% 100%, 75% 100%);
    position: absolute;
    top: 13px;
    width: 30px;
    height: 24px;
    background: #cecece;
    rotate: 45deg;
    right: 10px;
  }
  &:hover {
    .subInfo {
      display: block !important;
    }
    .subInfoArro {
      display: block;
    }
  }
}
.colWhite {
  color: #fff !important;
}
.modalcontainer {
  padding: 0 !important;
  background: linear-gradient(
    90deg,
    rgba(146, 27, 152, 1) 27%,
    rgba(201, 59, 84, 1) 72%
  ) !important;
  margin: auto;
  border: 4px solid transparent;
  border-bottom-width: 0px !important;
  position: relative;
  & > button {
    position: absolute;
  }
  @media (max-width: 575px) {
    max-width: 90%;
  }
}
.trmbtm {
  background: linear-gradient(270deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  width: 100%;
  & label {
    font-size: 19px;
    text-align: center;
    line-height: 26px;
    color: #fff;
    margin: auto;
    max-width: 80%;
    padding: 13px 0;
  }
}
.trmtp {
  padding: 30px;
  @media (max-width: 575px) {
    padding: 20px 10px 5px;
  }
}
.bulletspan {
  display: flex;
  flex-direction: column;
  padding-left: 65px;
  & > span {
    line-height: 24px;
  }
}
