@import '../../styles/propertySets.css';

.coverEverything {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  position: relative;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}
.dv3 {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 22px;
  color: #fff;
  background: #002435;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  font-size: 14px;
  & button {
    background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
    border-radius: 8px;
    padding: 2px 30px;
    color: #fff;
    cursor: pointer;
    border: none;
    margin-top: 5px;
    margin-bottom: 5px;
    &:hover {
      background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
    }
    @media (max-width: 575px) {
    }
  }
}
.dots {
  position: absolute;
  top: -5px;
  right: -1px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.dv1 {
  display: flex;
  flex-direction: row;
  /* border-top: 2px solid #cecece;
  border-left: 2px solid #cecece;
  border-right: 2px solid #cecece; */
  border-top-right-radius: 5px;
  @media (max-width: 575px) {
    flex-direction: column;
  }
}
/* .dv1 > div{
  flex-basis: 50%;
  max-width: 50%;
} */
.dv2 {
  display: flex;
  justify-content: space-evenly;
  background: #d6d6d6;
  @media (max-width: 575px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.dv2 > div {
  background: #fff;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0px 5px;
  border: 1px solid black;
  font-size: 14px;
  width: 105px;
  display: flex;
  justify-content: center;
  & > span:first-child {
    margin-right: 4px;
  }
  @media (max-width: 575px) {
    flex-basis: 40%;
  }
}
.imgdiv {
  width: 180px;
  height: 180px;
  flex-shrink: 0;
  flex-basis: 50%;
  max-width: 50%;
  @media (max-width: 575px) {
    flex-basis: unset;
    max-width: 100%;
    width: 100%;
    height: 170px;
    border-top-right-radius: 5px;
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 5px;
    @media (max-width: 575px) {
      border-top-right-radius: 5px;
    }
  }
}
.trdv {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 0;
  height: 0;
  border-top: 25px solid rgb(6, 181, 6);
  border-left: 25px solid rgb(6, 181, 6);
  border-right: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-top-left-radius: 5px;
}
.drfttr {
  border-top: 25px solid rgb(217, 130, 17) !important;
  border-left: 25px solid rgb(217, 130, 17) !important;
  border-right: 25px solid transparent;
  border-bottom: 25px solid transparent;
}
.redtr {
  border-top: 25px solid red !important;
  border-left: 25px solid red !important;
  border-right: 25px solid transparent;
  border-bottom: 25px solid transparent;
}

.listingdetdiv {
  font-size: 13px;
  line-height: 19px;
  padding: 10px 25px;
  color: #000;
  flex-basis: 50%;
  max-width: 50%;
  border-top-right-radius: 5px;
  border-top: 2px solid #cecece;
  border-right: 2px solid #cecece;
  @media (max-width: 575px) {
    flex-basis: unset;
    max-width: 100%;
    border-top: 0;
    border-top-right-radius: 0px;
    border-left: 2px solid #cecece;
    padding: 25px 25px 10px;
    min-height: 160px;
  }
}
.listingdetdiv > div:first-child {
  & > div {
    color: #c93b54 !important;
    text-align: center;
    & > span:last-child {
      font-weight: 700;
    }
  }
}
.listingdetdiv > div:first-child p {
  margin: 0;
  line-height: 19px;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  position: relative;
  &:hover {
    text-decoration: underline;
  }
}
.listingdetdiv > div:last-child {
  @media (max-width: 575px) {
    width: fit-content;
    /* margin: 10px auto 0; */
  }
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > span:first-child {
      margin-right: 5px;
      color: #cecece;
    }
  }
}
.durspn {
  margin-left: 2px;
  color: #cecece;
}
.menuOverlayWrapper {
  /* Positioning */
  composes: coverEverything;
}

.menuOverlay {
  /* Positioning */
  composes: coverEverything;

  /* Overlay */
  background-color: var(--matterColorAnti);
  mix-blend-mode: multiply;
  opacity: 0;
  transition: var(--transitionStyleButton);
}

.menuOverlayContent {
  /* Positioning */
  composes: coverEverything;
  /* Fonts */
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorLight);

  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
  margin-bottom: 1px; /* Fix 1px bug */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  composes: coverEverything;
  width: 100%;
  border-radius: 2px;
}

.menubarWrapper {
  /* Position */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 42px;
  @media (max-width: 575px) {
    bottom: 216px;
    right: 3px;
    width: unset;
    top: unset;
    left: unset;
  }
}

.menubarGradient {
  /* Position */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Gradient */
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAqCAYAAACUV/o4AAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAALEwAACxMBAJqcGAAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KTMInWQAAAGhJREFUKBVjZGBgKANibiAGga8sQAKEf4N4QMAC4vwH4r8gHgiABOAcmMAfEAMGsKrA0DIqAAsvHGFKkwACRRQjzGJQRP2DcUA0LOZAEQ5S9R8mAJIEA6wCyIYSqYVSz4FcxwR1E5gCAD7HLabS/HFUAAAAAElFTkSuQmCC');
  background-repeat: repeat;
  opacity: 0.5;
}

.menubar {
  position: absolute;
  top: -12px;
  left: 3px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.menuLabel {
  padding: 0px 9px 0 8px;
  position: relative;
  &:hover {
    & svg:first-child {
      fill: #002435 !important;
    }
    & svg:last-child {
      fill: #c93b54 !important;
    }
  }
}
.menuLabel svg {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 8px;
  pointer-events: all;
}
.menuLabel svg:nth-child(2) {
  left: -18px;
  fill: #921b98;
}
.menuLabel svg:first-child {
  left: -9px;
  fill: #c93b54;
}
.menuLabel svg:last-child {
  left: -27px;
  fill: #002435;
}
.iconWrapper {
  padding: 0px 10px 1px 10px;
  color: var(--matterColorLight);
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.listingMenuIsOpen {
  & .iconWrapper {
    background-color: rgba(0, 0, 0, 0.7);
    color: var(--matterColorLight);
  }
}

.menuContent {
  position: absolute;
  right: 4px !important;
  z-index: 1;

  background-color: var(--matterColor);
  border-radius: 4px;
  box-shadow: var(--boxShadowPopup);
  top: 35px;
}

.menuItem {
  &:hover {
    background-color: var(--failColor);
  }
}
.menuItem {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
  white-space: nowrap;

  border-radius: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  transition: var(--transitionStyleButton);
  position: relative;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.lockedMenuItem {
  & img {
    width: 19px;
    height: 19px;
    margin-left: 5px;
    display: inline;
  }
  &:hover {
    background-color: var(--matterColor) !important;
  }
}
.menuItemButton {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 8px 20px;
  width: 100%;
  min-height: 40px;
  border: none;
  cursor: pointer;
}
.lockedMenuButton {
  cursor: not-allowed;
}
.menuItemDisabled {
  color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorNegative);
  }
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 8px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-shrink: 0;

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    padding-top: 5px;
    padding-bottom: 3px;
  }
}

.priceValue {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);

  margin-right: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

@media (--viewportMedium) {
  .perUnit {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.noPrice {
  composes: perUnit;
  padding: 5px 0 3px 0;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.titleWrapper {
}

.title {
  @apply --marketplaceLinkStyles;
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);
  text-align: left;

  padding-top: 3px;
  padding-bottom: 3px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    padding-top: 4px;
    padding-bottom: 4px;

    margin-top: 0;
    margin-bottom: 0;
  }
}

.titlePending {
  color: var(--attentionColor);
}

.titleDraft {
  margin-right: 8px;
}

/* Solid gray background for draft listings without image */
.draftNoImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--matterColor);
}

.edit {
  @apply --marketplaceButtonStylesSecondary;

  /* TODO: Odd font-size */
  font-size: 14px;

  /* Reset min-height from button styles */
  min-height: 0;

  /* Reserve space for button */
  flex-shrink: 0;
  width: auto;
  height: 41px;
  padding: 7px 14px 7px 35px;

  /* Add edit icon as a background image */
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 15px center;
}

.openListingButton,
.finishListingDraftLink {
  @apply --marketplaceButtonStylesPrimary;
  @apply --marketplaceH5FontStyles;

  /* Reset min-height from button styles */
  min-height: 0;

  width: 114px;
  padding: 8px;
  border-radius: 4px;
}

.menu {
  display: none;
}

.cardIsOpen {
  display: block;
}

.manageLinks {
  @apply --marketplaceH5FontStyles;
  line-height: 18px;
  padding-top: 2px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    line-height: 24px;
    padding-top: 0;
    padding-bottom: 0;

    margin-top: 0;
    margin-bottom: 0;
  }
}

.manageLink {
  text-decoration: none;
  color: var(--matterColor);
  white-space: pre;

  &:hover {
    text-decoration: underline;
    color: var(--matterColorDark);
  }
}

.manageLinksSeparator {
  margin: 0 3px 0 3px;
}
.tncmodalcname {
  z-index: 9999;
  & > div:first-child {
    padding: 65px 20px;
  }
}
.modalContent {
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  border-radius: 30px;
  padding: 3px;
  @media (max-width: 767px) {
    border-radius: 0px;
    padding: 0px;
    background: #fff;
  }
}
.modalContainer {
  border: 0;
  margin: auto 50px auto;
  position: relative;
  width: 100%;
  @media (max-width: 767px) {
    margin: 0;
  }
  & > button:first-child {
    position: absolute;

    & > span:first-child {
      display: none;
    }
  }
}

.avimg {
  position: absolute;
  height: 110px;
  left: 0;
  right: 0;
  display: table;
  margin: 0 auto;
  top: -55px;
  @media (max-width: 767px) {
    display: none;
  }
}
.tsns {
}

.tsns tbody {
  display: block;
  overflow: auto;
  /* max-height: 250px; */
  width: 100%;
}

.smcrd {
  max-width: 471px;
  background: #fff;
  margin: 0 auto;
  border: 2px solid #000;
  border-radius: 7px;
  position: relative;
  @media (max-width: 767px) {
    display: none;
  }
}
.smcrd > div:first-child > div:first-child {
  display: none;
}
.smcrd > div:first-child {
}
.smcrd > div:last-child {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.mmdiv {
  border-radius: 30px;
  background: #fff;
  @media (max-width: 767px) {
    border-radius: 0px;
    padding: 0px;
    background: #fff;
  }
}

.btndv button:first-child {
}
.btndv button:last-child {
}

.datetimeinfocol {
  margin: 1px 0;
}
.datetimeinfocol > span:first-child {
  color: #616161b0;
  font-weight: 600;
  margin-right: 3px;
}

.emgspn {
  display: flex;
  flex-direction: column;
}
.cnfDuplicamodal {
  & p {
    text-align: center;
    font-size: 20px;
    color: #000;
  }
  & p:first-child {
    font-size: 24px;
    font-weight: 700;
  }
  & span {
    color: #c93b54;
    font-weight: 700;
  }
  & p:nth-child(2) {
    max-width: 550px;
  }
  & p:last-child span {
    margin: 0 5px;
  }
  & button {
    background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
    max-width: 350px;
    margin: 30px auto 0;
    border-radius: 30px;
    min-height: 55px;
    &:hover {
      background: linear-gradient(270deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
    }
  }
}
