@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}
.searchLink {
  /* background-color: white; */
  border-radius: 5px;
  width: 100%;
  /* max-width: 45vw; */

  /* @media (max-width: 1199px) {
    max-width: 60vw;
  }
  @media (max-width: 991px) {
    max-width: 100%;
  } */
}
.searchBoxContainer {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  padding: 15px;
  background: rgba(0, 36, 53, 0.7);
  border-radius: 10px;
  min-width: 700px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 767px) {
    width: 84vw;
    min-width: unset;
    padding: 15px 5px;
  }
}
.getStartedContainer {
  display: flex;
  flex-direction: row;
  /* max-width: 100%; */
  justify-content: flex-start;
  padding: 0px;
  /* background: rgba(0, 0, 0, 0.5); */
  border-radius: 10px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 767px) {
    justify-content: center;
  }
}
.root {
  @apply --backgroundImage;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @media (max-width: 767px) {
    background-position: right center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  /* Text positioning is done with paddings */
}
.rootAbout {
  background: url('../../assets/about-us-first-img.png') !important;
  background-color: #f2f1ef !important;
  background-size: cover !important;
  background-position: center center !important;
  @media (max-width: 767px) {
    background-position: center left -135px !important;
  }
}
.rootlmb {
  background: url('../../assets/lmbhero.png') !important;
  background-color: #f2f1ef !important;
  background-size: cover !important;
  background-position: center center !important;
  @media (max-width: 1440px) {
    background-position: center right -50px !important;
  }
  @media (max-width: 767px) {
    background-position: center right -144px !important;
  }
}
.rootTrust {
  background: url('../../assets/Archery-boy.png') !important;
  background-color: #f2f1ef !important;
  background-size: cover !important;
  background-position: center center !important;
  @media (max-width: 1440px) {
    background-position: center left 0px !important;
  }
  @media (max-width: 767px) {
    background-position: center left -5px !important;
  }
}
.rootCont {
  background: url('../../assets/Contact_us_header_picture.png') !important;
  background-color: #f2f1ef !important;
  background-size: cover !important;
  background-position: center center !important;
  @media (max-width: 767px) {
    background-position: center left -330px !important;
  }
  @media (max-width: 575px) {
    background-position: center left -200px !important;
  }
  @media (max-width: 512px) {
    background-position: center left -235px !important;
  }
  @media (max-width: 440px) {
    background-position: center left -280px !important;
  }
  @media (max-width: 375px) {
    background-position: center left -316px !important;
  }
}

.heroContent {
  /* top: 0px; */
  margin: 0 18px 50px 18px;
  padding: 0 36px 0 36px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  /* text-align: center; */
  margin-bottom: 0;
  bottom: -60px;
  /* top: unset; */
  @media (max-width: 767px) {
    padding: 0;
    margin: auto;
    bottom: -160px;
  }

  /* @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  } */

  /* Special viewport for adjusting the heroContent's placement */

  /* @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  } */

  /* @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  } */
}

.lmbContent {
  margin: 0 18px 50px 18px;
  padding: 0 36px 0 36px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  /* align-items: center; */
  /* text-align: center; */
  @media (max-width: 767px) {
    padding: 0;
  }
  @media (max-width: 575px) {
    margin: 0 0 50px;
    padding: 0 15px;
  }
}
.lmbMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);
  font-family: 'Stardos Stencil', cursive;
  font-size: 46px;
  line-height: 60px;
  letter-spacing: 1px;
  composes: animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }

  @media (max-width: 575px) {
    font-size: 32px;
    line-height: 40px;
  }
}
.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);
  font-family: 'Stardos Stencil', cursive;
  font-size: 46px;
  line-height: 60px;
  letter-spacing: 1px;
  composes: animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }

  @media (max-width: 575px) {
    font-size: 32px;
    line-height: 40px;
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  padding: 0;
  font-weight: 400;
  line-height: 30px;
  animation-delay: 0.65s;

  composes: animation;
  animation-delay: 0.65s;

  @media (max-width: 575px) {
    font-size: 18px;
    padding: 0;
    line-height: 24px;
  }

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}
.lmbSubTitle {
  @apply --marketplaceH4FontStyles;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  padding: 0;
  font-weight: 400;
  line-height: 30px;
  animation-delay: 0.65s;

  composes: animation;
  animation-delay: 0.65s;

  @media (max-width: 575px) {
    font-size: 18px;
    padding: 0;
    line-height: 24px;
  }

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}
.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;
  /* line-break: 0; */
  /* line-height: 50px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 10px 10px; */
  /* padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px; */
  animation-delay: 0.8s;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);

  /* background-image: linear-gradient(to bottom, rgb(103, 28, 242) 0%, rgb(103, 28, 242) 100%),
    linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%);
  background-clip: content-box, padding-box; */
  @media (--viewportMedium) {
    width: 150px;
  }
}
.serchinput {
  flex-basis: 30%;
  position: relative;
  @media (max-width: 1199px) {
    flex-basis: 50%;
    margin: 0 0 20px;
  }
  @media (max-width: 767px) {
    flex-basis: 100%;
  }
}
.serchinput input {
  background: #fff;
  border: 1px solid #d7d7d7;
  padding: 4px 15px 4px 38px;
  font-size: 14px;
}
.serchinput svg {
  position: absolute;
  top: 6px;
  left: 13px;
  height: 30px;
  width: 18px;
  color: #bbb;
}

.getStartedLink {
  @apply --marketplaceButtonStyles;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  padding: 8px 8px;
  min-height: 34px;
  width: 280px;

  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);

    /* color: var(--marketplaceColorDark); */
  }
}
.aboutHero {
  top: 290px;
  position: relative;
  z-index: 1;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  @media (max-width: 1024px) {
    padding: 0;
    top: 110px;
    font-size: 40px;
    margin: auto;
  }
  @media (max-width: 767px) {
    padding: 0;
    top: 133px;
    font-size: 30px;
    margin: auto;
  }
  @media (max-width: 575px) {
    top: 104px !important;
    font-size: 28px;
    padding: 0 15px;
    top: 0px;
  }
}
.aboutHero span {
  position: relative;
  padding: 35px 200px;
  font-family: 'Stardos Stencil', cursive;
  letter-spacing: 1px;
  &:after {
    content: '';
    background-color: #002435;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.7;
    border-radius: 5px;
  }
  @media (max-width: 991px) {
    padding: 35px 130px;
  }
  @media (max-width: 767px) {
    display: none;
  }
}
.opap {
  position: absolute;
  top: 30px;
  right: 203px;
  width: 500px;
  padding: 20px 35px;
  @media (min-width: 1921px) {
    top: 146px;
    right: 305px;
  }
  @media (max-width: 1920px) {
    top: 240px;
    right: 266px;
  }
  @media (max-width: 1920px) {
    top: 135px;
    right: 193px;
  }
  @media (max-width: 1199px) {
    top: 27px;
    right: -322px;
  }
  @media (max-width: 767px) {
    display: none;
  }

  &:after {
    content: '';
    background-color: #002435;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.7;
    border-radius: 5px;
    @media (max-width: 575px) {
      z-index: -1;
    }
  }
}
.opap > p:first-child {
  color: #c93b54;
  font-size: 36px;
  font-weight: 600;
  line-height: 46px;
}
.opap > p:last-child {
  font-size: 22px;
  line-height: 30px;
}
.trustHero {
  position: relative;
}
.cpap {
  position: absolute;
  top: 30px;
  right: 203px;
  width: 500px;
  padding: 20px 35px;
  @media (min-width: 1920px) {
    top: 146px;
    right: 738px;
  }
  @media (max-width: 1919px) {
    top: 98px;
    right: 690px;
  }
  @media (max-width: 1439px) {
    top: 15px;
    right: 493px;
  }
  @media (max-width: 1199px) {
    top: 27px;
    right: 322px;
  }
  @media (max-width: 1023px) {
    top: 52px;
    right: -238px;
  }
  @media (max-width: 767px) {
    display: none;
  }

  &:after {
    content: '';
    background-color: #002435;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.7;
    border-radius: 5px;
    @media (max-width: 575px) {
      z-index: -1;
    }
  }
}
.cpap > p:first-child {
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  line-height: 46px;
}
.cpap > p:last-child {
  font-size: 22px;
  line-height: 30px;
  color: #c93b54;
}
