@import '../../styles/propertySets.css';

.root {
  /* Adding empty .root class to enforce className overwrite */
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
}

.helperText {
  @apply --marketplaceModalHelperText;
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
}

.reminderModalLinkButton {
  @apply --marketplaceButtonStylesPrimary;
}
.modcont {
  position: relative;
  @media (min-width: 575px) and (max-width: 767px) {
    padding-top: 20px;
  }
  @media (min-width: 767px) {
    border-radius: 10px;
  }
  @media (max-width: 575px) {
    padding: 20px 20px 40px;
  }
}
.modcont img {
  position: absolute;
  top: -71px;
  left: 223px;
  width: 130px;
  height: 130px;
  @media (max-width: 767px) {
    left: 110px;
    top: -105px;
  }
}
.modcntnr {
  @apply --marketplaceModalBaseStyles;
  /* min-height: 100vh; */
  height: 100%;
  padding: 0;
  margin: auto;
  @media (max-width: 767px) {
    max-width: 340px;
  }
  @media (min-width: 768px) {
    margin: auto;
    border: 4px solid transparent;
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  }
  @media (--viewportMedium) {
    flex-basis: 576px;
    min-height: auto;
    height: auto;
    margin: auto;
  }
}
.modcntnr > button:first-child {
  /* display: none; */
  @media (max-width: 767px) {
    display: block;
    position: relative;
    margin-left: auto;
  }
}
.cstmmsg {
  margin-top: 0px;
}
.cstmmsg p:first-child {
  text-align: center;
  font-size: 18px;
  & span {
    font-weight: 700;
    color: #c93b54;
  }
}
.cstmmsg p:nth-child(2) {
  text-align: center;
  font-size: 16px;
  line-height: 22px;
}
.cstmmsg p:nth-child(3) {
  text-align: center;
  font-size: 16px;
  line-height: 22px;
}
.cstmmsg button {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  border-radius: 8px;
  min-height: auto;
  padding: 10px 15px;
  width: 270px;
  cursor: pointer;
  border: 0;
  color: #fff;
  margin: 30px auto 0px;
  display: block;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
}
.welcomeMessage {
  display: flex;
  flex-direction: column;
  & h3 {
    color: #c93b54;
    width: 100%;
    text-align: center;
    font-size: 42px;
    font-family: 'Stardos Stencil', cursive;
    font-weight: 500;
    line-height: 45px;
    margin-bottom: 0px;
    @media (max-width: 767px) {
      margin-bottom: 0px;
    }
  }
  & > p {
    margin: 15px 0 !important;
    line-height: 42px !important;
    cursor: default !important;
  }
  & p {
    color: #000;
    font-weight: 700;
    text-align: center;
    margin: 0;
    font-size: 19px;
    line-height: 28px !important;
    cursor: pointer;
  }
  & span {
    color: #787878;
    text-align: center;
    max-width: 78%;
    font-size: 17px;
    line-height: 25px;
    font-weight: 600;
    margin: 3px auto;
    @media (max-width: 575px) {
      max-width: 90%;
    }
  }
  & button {
    border: none;
    background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
    width: 230px;
    padding: 10px 15px;
    border-radius: 8px;
    max-height: 45px;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    font-size: 17px;
    margin: 40px auto 0;
    justify-content: center;
    &:hover {
      background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
    }
  }
}
.redspn {
  color: #c93b54 !important;
  font-weight: 700 !important;
  font-size: 19px !important;
}
.redmsg {
  color: #c93b54;
  font-weight: 700;
}
