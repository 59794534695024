.buttonWrapper {
  display: flex;
  flex-direction: column;
  width: 100px;
  flex-basis: 100px;
  flex-shrink: 0;
  align-items: center;
  gap: 0px;
  cursor: pointer;
  & > button,
  .cpybtn {
    all: unset;
    & > svg {
      fill: #002435;
      width: 50px;
      height: 50px;
    }
  }
  & > div {
    font-size: 13px;
    font-weight: 400;
    line-height: 30px;
    color: #000;
  }
}
.linkCopyBox {
  display: none;
}
.shareIconDiv {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  column-gap: 10px;
  @media (max-width: 767px) {
  }
}
.additionalSecondDivClassname {
  position: fixed;
  width: 440px;
  transform: translate(-100%, -100%);
  background: #fff !important;
  @media (max-width: 767px) {
    transform: translate(-75%, -100%);
    width: 95%;
  }
  /* border-bottom-right-radius: 0px !important; */
  & > div {
    padding: 13px 0px 0px !important;
    & > button {
      display: none;
    }
    &::after {
      content: '';
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      width: 0;
      height: 0;
      border-top: 20px solid #fff;
      position: absolute;
      bottom: 0; /* Default to 0 if not set */
      right: 0;
      transform: translate(-50%, 100%);
    }
  }
}
.shareButton {
}
