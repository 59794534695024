.starRating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.starContainer {
  position: relative;
  display: flex;
}

.star {
  font-size: 16px; /* Adjust as needed */
}

.starOverlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
  display: grid;
}

.full {
  color: gold; /* Color for filled stars */
}

.partial {
  color: gold; /* Color for partially filled stars */
}

.empty {
  color: #3f3f3f; /* Color for empty stars */
}
.underlay {
  color: #3f3f3f !important; /* Color for empty stars */
}
