@import '../../styles/propertySets.css';

/* ModalInMobile is visible by default */
.root {
  width: 100%;
}

/* Content is hidden in Mobile layout */
.modalHidden {
  display: none;
}

.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;
  padding: 20px 15px;
  background-color: #fff;
  border-radius: 20px;
  flex-grow: 1;
  height: auto;
  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}
.noLogo {
  padding-top: 75px;
}

.modalContent {
  width: 100%;

  @media (--viewportMedium) {
    height: 100%;

    /* ModalInMobile content should work with box-shadows etc. */
    overflow: visible;
  }
}
