/*
If you need to add css to the Logo you can do it here.

.logoMobile {
}
*/
.logo {
  width: 100%;
  /* max-width: 358px; */
  height: 60px;
  object-fit: contain;

  @media (max-width: 991px) {
    height: 45px;
  }
}
