.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}
.allowedAgeOptions {
  display: flex;
  flex-direction: row;
}
.ageButton {
  /* margin: 10px 10px; */
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #000;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.flexprice {
  text-align: center;
  display: flex;
  color: #000;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.age {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.exitButton {
  border-radius: 50px;
  font-weight: 500;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 7px 20px;
  cursor: pointer;
  background: linear-gradient(
    90deg,
    rgba(201, 59, 84, 1) 27%,
    rgba(146, 27, 152, 1) 72%
  );
  line-height: 18px;
  margin: 0 0 0 auto;
  min-height: 42px;
  width: 115px;
  &:hover {
    background: linear-gradient(
      90deg,
      rgba(146, 27, 152, 1) 27%,
      rgba(201, 59, 84, 1) 72%
    );
  }
  &:disabled {
    cursor: not-allowed;
  }
}
.svexbtnsec {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.formlistbtmsec {
  margin: auto 0 0;
  @media (max-width: 767px) {
    margin: 0;
    padding: 0 15px;
    position: sticky;
    bottom: 0;
  }
}
.buttonDiv {
  display: flex;
  align-items: flex-end;
  padding-bottom: 25px;
}
.backButton {
  display: inline-flex;
  align-items: flex-end;
  font-weight: 900;
  font-size: 16px;
  text-decoration: underline;
  color: #676767 !important;
  background: none !important;
  text-align: left;
  max-width: 50px;
  &:hover {
    box-shadow: none;
  }
}

.submitButton {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  border-radius: 8px;
  min-height: auto;
  padding: 10px 15px;
  max-width: 150px;
  margin: 0 0 0 auto;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}
.agefigure {
  margin: 0 25px;
  font-size: 18px;
}
.type {
  padding: 0 20px 0 0;
}
.noRestrictionMsg {
  color: var(--successColor);
}
.error {
  color: red;
  /* color: var(--failColor); */
}

.formseclist {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  margin: 20px 0 30px;
  padding: 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    max-height: unset;
    padding: 0 15px;
  }
}
.agecon {
  margin: auto;
}
.rowprice {
  margin: 0 0 30px;
}
.mainoutrow label {
  color: #c93b54;
  text-align: center;
  font-size: 18px;
  margin: 0 0 20px;
}
.mainoutrow label span {
  color: #000;
}
.mainPrice {
  display: flex;
  position: relative;
  margin: 0 20px;
  max-width: 150px;
  justify-content: center;
  align-items: center;
}
.mainPrice input {
  border: 2px solid #cecece !important;
  border-radius: 8px;
  min-height: 50px;
  padding: 5px 15px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.mainPrice span {
  position: absolute;
  left: auto;
  right: auto;
  margin: 0 0 0 -30px;
  top: 9px;
  font-size: 24px;
  font-weight: 600;
  padding: 0 8px 0 0;
  @media (max-width: 767px) {
    top: 12px;
  }
}
.siblingDiscountCheck {
  display: flex;
  align-items: center;
  margin: 0 0 50px;
  justify-content: center;

  @media (max-width: 575px) {
    align-items: flex-start;
  }
}
.siblingDiscountCheck input {
  width: 20px;
  margin: 0 15px 0 0;
  height: 20px;
  opacity: 0;
}
.siblingDiscountCheck label {
  margin: 0;
  padding: 0;
  position: relative;
  @media (max-width: 575px) {
    text-align: left;
    font-size: 14px;
  }
}
.siblingDiscountCheck .checkicon {
  position: absolute;
  left: -40px;
  top: -4px;
  @media (max-width: 991px) {
    left: -12px;
  }
  @media (max-width: 767px) {
    left: -28px;
    top: 0;
  }
}
.siblingDiscountCheck .checkicon svg {
  width: 25px;
  height: 25px;
  @media (max-width: 575px) {
    width: 20px;
    height: 20px;
  }
}
.bookingTypeDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bookingTypeDiv h2 {
  color: #232323;
  @media (max-width: 575px) {
    text-align: center;
  }
}
.bookingTypeDiv h2 span {
  color: #c93b54;
}
.bborow {
  display: flex;
  min-width: 100%;
  align-items: center;
  margin: 0 0 20px;
}
.bborow input {
  width: 24px;
  margin: 0 30px 0 0;
  display: none;
}
.bborow label {
  border: 1px solid #cecece;
  flex-basis: 80%;
  padding: 30px;
  color: #000;
  position: relative;
  margin: 0 30px 0 auto;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  @media (max-width: 991px) {
    font-size: 14px;
    line-height: 20px;
    padding: 15px;
  }
  @media (max-width: 575px) {
    margin: 0 0 0 auto;
    flex-basis: 90%;
    padding: 8px;
  }
}
.bborow .checkicon svg {
  width: 24px;
  margin: 0 30px 0 0;
  height: 40px;
  @media (max-width: 991px) {
    width: 18px;
    height: 28px;
  }
}
.bborow .checkicon {
  position: absolute;
  left: -60px;
  top: auto;
  margin: 15px 0 0;
  @media (max-width: 1199px) {
    left: -30px;
  }
}
.bborow p {
  color: #808080;
  margin: 0;

  @media (max-width: 575px) {
    line-height: 18px;
  }
}
.bborow h2 {
  text-decoration: underline;
  margin: 0;
}
.labelDiv {
  color: #232323;
  font-size: 20px;
  line-height: 30px;
  @media (max-width: 575px) {
    font-size: 18px;
  }
}
.labelDiv span {
  color: #c93b54;
}
.someImp {
  margin: 0 0 20px;
}
.someImp p {
  color: #232323;
  text-decoration: underline;
  font-weight: 600;
  font-size: 23px;
}
.someImp li {
  color: #232323;
  font-weight: 600;
  font-size: 17px;
}
.someImp li span {
  color: #c93b54;
}
.gvhed {
  color: #c93b54;
  text-decoration: underline;
  font-size: 18px;
}
.inlinechck {
  margin: 0 0 15px;
}
.inlinechck label {
  display: flex;
  font-size: 16px;
  width: 100%;
  max-width: 80%;
  margin: 0 31px 0 auto;
  color: #232323;
  flex-wrap: wrap;
  align-items: center;
  line-height: 24px;

  @media (max-width: 1199px) {
    max-width: 95%;
    margin: 0;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
}
.inlinechck label span {
  color: #c93b54;
  margin: 0 5px;
}
.inlinechck input {
  display: none;
}
.checkicon {
  margin: 0 0 0 auto !important;
}
.checkicon svg {
  width: 24px;
  height: 24px;
}
.pctxt {
  width: calc(100% - 40px);
  font-size: 18px;
}
.lnk {
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
