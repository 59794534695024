@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 15px;
  min-height: 100%;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  /* transition: var(--transitionStyleButton); */
  max-width: 100%;

  /* &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  } */
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 0;
  min-height: 240px;
  /* Loading BG color */
  /* @media (max-width: 767px) {
    min-height: 178px;
  } */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 15px;
  height: 100%;
  object-fit: contain;
  background-color: #000;
}

.info {
  /* Layout */
  padding: 15px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.addinfo {
  font-size: 16px;
  color: #c93a53;
  line-height: 26px;
  font-weight: 600;
  margin: 2px 0 0;
  & span {
    font-weight: 700;
  }
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 22px;
  }
}
.datetimeinfo {
  margin: 20px 0;
  width: 100%;
  padding: 0 15px 0 20px;
  @media (max-width: 767px) {
    padding: 0;
    margin: 15px 0;
  }
}
.datetimeinfocol {
  text-align: left;
  font-size: 15px;
}
.datetimeinfocol span {
  font-weight: 700;
  padding-right: 10px;
}
.price {
  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 12px auto;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 800;
  margin: 0 10px 0 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: #000;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  color: #232323;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  /* min-height: 60px; */
  /* @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  } */
  @media (max-width: 767px) {
    font-size: 18px;
  }
}

.certificateInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.htisgn {
  width: 52px;
  height: 52px;
  padding: 5px;
  background: rgb(217 217 217 / 70%);
  position: absolute;
  right: 10px;
  z-index: 9;
  top: 10px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  @media (max-width: 767px) {
    width: 40px;
    height: 40px;
  }
}
.htisgn svg {
  width: 28px;
  height: 28px;
  & path {
    fill: #232323 !important;
  }
  @media (max-width: 767px) {
    width: 20px;
    height: 20px;
  }
}
.ratingstar {
  position: absolute;
  right: 15px;
  z-index: 9;
  bottom: 15px;
  background: rgba(255, 255, 255, 0.7);
  padding: 5px;
  display: flex;
  border-radius: 5px;
}
.dispn {
  display: none;
}
.spdv {
  z-index: 1;
  position: relative;
}
.spcspn {
  position: absolute;
  top: 4px;
  left: 4px;
  color: #fff;
  padding: 1px 15px;
  font-size: 14px;
  line-height: 25px;
  &:after {
    content: '';
    background-color: #002435;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.7;
    border-radius: 5px;
    @media (max-width: 575px) {
      z-index: -1;
    }
  }
}
