@import '../../styles/propertySets.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
  padding: 30px 5px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.authenticationGreeting {
  /* Font */
  @apply --marketplaceH1FontStyles;

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--matterColorDark);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}
.link {
  color: #000;
  font-weight: 600;
  font-size: 19px;
}
.linkslabel {
  color: #c93b54;
  font-weight: 700;
  font-size: 22px;
}
.list {
}
.listItem {
  padding: 5px 0 5px 0px;
}
.btndv {
  border-radius: 6px;
  text-align: center;
  font-weight: 600;
  font-size: 19px;
  margin: 10px auto 10px;
  border: 0px;
  color: #fff;
  padding: 10px 20px;
  background: linear-gradient(
    90deg,
    rgba(201, 59, 84, 1) 27%,
    rgba(146, 27, 152, 1) 72%
  );
  &:hover {
    background: linear-gradient(
      90deg,
      rgba(146, 27, 152, 1) 27%,
      rgba(201, 59, 84, 1) 72%
    );
  }
}
.searches:first-child {
  margin-top: 0px;
}
.searches {
  margin-top: 30px;
}
