@import '../../styles/propertySets.css';

/* Content is visible as modal layer */
.isOpen {
  width: 100%;
  z-index: 100;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    @apply --marketplaceModalRootStyles;

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    background-color: var(--matterColorLight);

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background-color: rgba(0, 0, 0, 0.4);
    @media (--viewportMedium) {
      padding: 0;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none;
    }
  }

  & .container {
    @apply --marketplaceModalBaseStyles;
    flex-grow: 1;
    height: 100%;
    position: relative;
    margin: auto;
    border-radius: 25px;
    background-color: #fff;
    border: 0;
    width: 100%;
    @media (--viewportMedium) {
      min-height: 100vh;

      min-height: auto;
      height: auto;
    }
  }
}
.noLogo {
  padding-top: 60px !important;
}
.logoImg {
  height: 130px;
  width: 130px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
  top: -71px;
}
.bgwtdecoy {
  position: relative;
  border-radius: 25px;
  margin: auto;
  display: flex;
  /* padding-top: 100px; */
  @media (min-width: 768px) {
    flex-basis: 576px;
  }
  @media (max-width: 575px) {
    width: 97%;
  }
}
.bgtdecoy2 {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%) border-box;
  border: 4px solid transparent;
  border-radius: 25px;
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 50px;
}
.addLogoPadding {
  padding-top: 65px;
}
.hideMarginWhenNoLogo {
  margin-bottom: 0px;
}
.isOpenInPlace {
  composes: isOpen;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.isOpenInPortal {
  composes: isOpen;
  display: block;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.content {
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
}

.close {
  @apply --marketplaceModalCloseStyles;
  position: absolute;

  @media (max-width: 767px) {
    padding: 10px;
    margin: 0 0 0 auto;
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.closeText {
  @apply --marketplaceModalCloseText;
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
}

.closeLight {
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--matterColorLight);
  }
}

.focusedDiv {
  &:focus {
    outline: none;
  }
}
