.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
}

.error {
  color: var(--failColor);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.reviewRating {
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.reviewContent {
  flex-shrink: 0;
  margin-bottom: 5px;
  & textarea {
    border: 2px solid rgb(0, 36, 53);
    padding: 10px;
    border-radius: 10px;
    min-height: 123px;
    height: 130px;
    box-sizing: border-box;
  }
}

.submitButton {
  flex-shrink: 0;
  display: inline-block;
  width: 150px;
  margin-bottom: 0;
  margin-left: auto;
  min-height: 40px;
  border-radius: 5px;
  cursor: pointer;
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
  &:disabled {
    opacity: 0.7;
  }
}
