@import '../../styles/propertySets.css';

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.uploadbtn {
  width: 147px;
  min-height: 45px;
  border: 1px solid #c93b54;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  display: flex;
  padding: 0 auto;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  background: #fff;
  box-shadow: rgb(163 163 163) 1.95px 1.95px 2.6px;
  color: #232323;
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 14px;
    min-height: 33px;
    width: 129px;
  }
  @media (max-width: 575px) {
    font-size: 17px;
    min-height: 60px;
    width: 170px;
  }
}
.atlstone {
  min-height: 50px !important;
}
.addMar {
  @media (max-width: 575px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.svexbtnsec {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.progressSpan {
  color: #cecece;
}
.exitButton {
  border-radius: 50px;
  font-weight: 500;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 7px 20px;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  line-height: 18px;
  margin: 0 0 0 auto;
  min-height: 42px;
  width: 115px;
  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  }
  &:disabled {
    cursor: not-allowed;
  }
}
.formlistbtmsec {
  background-color: #fff;
  margin: auto 0 0;
  @media (max-width: 767px) {
    margin: 0;
    padding: 0 15px;
    /* position: sticky; */
    bottom: 0;
  }
}
.buttonDiv {
  display: flex;
  align-items: flex-end;
  padding-bottom: 25px;
}
.backButton {
  display: inline-flex;
  align-items: flex-end;
  font-weight: 900;
  font-size: 16px;
  text-decoration: underline;
  color: #676767 !important;
  background: none !important;
  text-align: left;
  max-width: 50px;
  &:hover {
    box-shadow: none;
  }
}

.submitButton {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  border-radius: 8px;
  min-height: auto;
  padding: 10px 15px;
  max-width: 150px;
  margin: 0 0 0 auto;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}
.imagesField {
  flex-shrink: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 50%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  /* padding-bottom: calc(100% * (3 / 4)); */
  min-height: 330px;
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  cursor: auto !important;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  gap: 10px;
}
.atlstonesvg svg {
  width: 25px !important;
  height: 25px !important;
  /* top: 12px !important; */
}
.chooseImageText svg {
  width: 30px;
  height: 30px;
  /* top: 76px; */
  fill: black;
  @media (max-width: 991px) {
    /* top: 60px; */
  }
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.formseclist {
  min-height: calc(100vh - 348px);
  overflow-y: auto;
  margin: 0 0 30px;
  padding: 0 15px 0 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    padding: 0 15px;
    max-height: unset;
    overflow-y: unset;
  }
}
.noimg {
  overflow-y: auto;
  @media (max-width: 767px) {
    overflow-y: unset;
  }
}
.title {
  color: #232323;
  margin-bottom: 8px;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
}
.lblp {
  color: #808080;
  margin: 0 0 20px;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
}
.addImageWrapperfst {
  width: 100%;
  margin-bottom: 0;
  margin-top: 0 !important;
}

.modalContainer {
  margin-top: auto;
  margin-bottom: auto;
  background-color: #fff;
  padding: 30px 50px;
  position: relative;
  @media (max-width: 575px) {
    padding: 0px 20px;
    margin-top: 0;
  }
  & > div {
    @media (max-width: 575px) {
      max-height: 95vh;
      overflow: auto;
    }
  }
}

.consentDiv {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 7px;
  color: #000;
  display: flex;
}
.consentDiv p {
  line-height: 20px;
  font-size: 12px;
  margin: 0 !important;
  flex-basis: 80%;
  max-width: 80%;
}
.consentDiv input {
  display: none;
}
.consentDiv label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
  padding: 0;
}
.consentDecoy {
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  padding: 1px;
  border-radius: 7px;
  margin: 10px auto 10px;
}
.consentSpan {
  width: 25px;
  height: 25px;
  max-width: 25px;
  max-height: 25px;
  border-radius: 50%;
  display: block;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  flex-grow: 1;
  padding: 2px;
  cursor: pointer;
  & span {
    display: block;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 50%;
    padding: 2px;
  }
}
.inspan {
  background: linear-gradient(
    90deg,
    rgba(201, 59, 84, 1) 27%,
    rgba(146, 27, 152, 1) 72%
  ) !important;
}
