@import '../../styles/propertySets.css';

.mobileInputRoot {
  width: 100%;
}

.desktopInputRoot {
  height: 40px;
}
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}
.mobileIcon {
  margin-left: 24px;
  padding: 11px 0 17px 0;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);
  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.mobileInput {
  flex-grow: 1;

  /* Font */
  @apply --marketplaceH1FontStyles;

  /* Layout */
  margin: 0 24px 0 0;
  padding: 4px 13px 10px 13px;
  height: var(--TopbarSearchForm_inputHeight);
  line-height: unset;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
}

.desktopIcon {
  /* height: var(--topbarHeightDesktop); */
  border: none;
  padding: 3px 4px;
  display: flex;
  width: 45px;
  align-self: stretch;
  /* backgr ound-color: var(--matterColorLight); */
  &:hover {
    stroke: var(--matterColorDark);
  }
}

.desktopInput {
  flex-grow: 1;
  /* Font */
  @apply --marketplaceH4FontStyles;
  /* height: var(--topbarHeightDesktop); */
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightMedium);
    transition: var(--transitionStyleButton);
  }

  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: var(--fontWeightSemiBold);
  }
}

.mobilePredictions {
  position: absolute;
  top: calc(var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder));
  left: 0;
  min-height: calc(
    100vh -
      calc(
        var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) -
          var(--TopbarSearchForm_bottomBorder)
      )
  );
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 50px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: calc(
    var(--topbarHeightDesktop) - var(--TopbarSearchForm_inputHeight) +
      var(--TopbarSearchForm_bottomBorder)
  );
  max-width: 434px;
}
.srch {
  position: relative;

  /* background: rgba(0, 0, 0, 0.4);
  padding: 20px 20px;
  border-radius: 5px; */

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* margin: 4px; */
  /* border-radius: 3px; */
  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
  }

  /* @media (max-width: 575px) {
    flex-direction: column;
  } */
}
.secondRow {
  display: flex;
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }
}
.selectSrch {
  display: flex;

  @media (max-width: 575px) {
    flex-wrap: wrap;
  }
}
.srch button {
  cursor: pointer;
}
.srch svg {
  height: 30px;
  width: 18px;
  margin: 0 5px 0 0;
}

.srchButton {
  @apply --marketplaceButtonStyles;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0 5px;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  padding: 8px 8px;
  min-height: 34px;
  width: 150px;

  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);

    /* color: var(--marketplaceColorDark); */
  }

  @media (max-width: 767px) {
    /* margin: 14px auto 0; */
  }

  @media (max-width: 575px) {
    /* width: 48%; */
    margin: 0 3px;
  }
}
.srchBtn {
  border-radius: 3px;
  border: 1px solid var(--matterColorDark);
}
.countyOptns {
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  position: absolute;
  top: 130px;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0px);
  background-color: rgba(255, 255, 255, 1);
  z-index: 999;
  /* opacity: 0;
  visibility: hidden;
  pointer-events: none; */
  /* padding: 15px; */

  @media (max-width: 575px) {
    top: 154px;
    max-height: 320px;
    overflow-y: auto;
  }
}
.cateexp {
  @media (max-width: 575px) {
    top: 185px;
  }
}
.showCountyOptns {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.countyOptns input {
  width: auto;
  margin: 0 5px 0 0;
  height: 18px;
}

.countySec {
  /* flex-direction: row; */
  padding-top: 54px;
  @media (min-width: 768px) {
    /* min-height: 390px; */
    padding-top: 80px;

    flex-direction: column;
    height: 420px;
  }
}
.activitySec {
  /* flex-direction: row; */
  padding-top: 45px;
  @media (min-width: 576px) {
    height: 320px;
    flex-direction: column;
  }
}
.ageoptns {
  @media (max-width: 575px) {
    top: 154px !important;
  }
}
.categorySec {
  /* flex-direction: row; */
  padding-top: 45px;
  @media (min-width: 576px) {
    height: 330px;
    flex-direction: column;
  }
}
.optionSec {
  display: flex;
  /* flex-basis: 28%; */
  flex-basis: 28px;

  margin: 8px 12px;
  cursor: pointer;
  max-width: 28%;
  @media (max-width: 767px) {
    flex-basis: 150px;
  }
  @media (max-width: 575px) {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.optionSec label {
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  word-break: break-word;
  text-align: left;
  line-height: 16px;
  white-space: nowrap;
}
.countySrch,
.activitySrch,
.categorySrch {
  @media (max-width: 575px) {
    margin: 0 2px 6px;
    flex-basis: 48%;
    max-width: 48%;
  }
}
.categorySrch {
  @media (max-width: 575px) {
    max-width: 100%;
    flex-basis: 100%;
    /* margin: 0 0 6px; */
    margin: 0px 6px 6px 2px;
  }
}
.countySrch button,
.activitySrch button,
.cls,
.srchBtn {
  /* background: rgba(255, 255, 255, 0.5); */
  background: rgba(255, 255, 255);

  border: 0;
  padding: 4px 13px;
  min-width: 170px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin: 0 5px;
  align-items: center;
  display: flex;
  border-radius: 6px;
  @media (max-width: 991px) {
    min-width: 140px;
  }
  @media (max-width: 575px) {
    min-width: 150px;

    margin: 0;
  }
}

/* .activitySrch {
  margin: 0 20px 0 0;
} */
.browseBy {
  color: #fff;
  margin: auto;
  text-align: center;
}
.bestWay {
  @apply --marketplaceH4FontStyles;

  color: var(--matterColorLight);
  margin: 4px;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  padding: 0;
  font-weight: 400;
  line-height: 24px;
  animation-delay: 0.65s;

  /* composes: animation;
  animation-delay: 0.65s; */

  @media (max-width: 575px) {
    font-size: 17px;
    font-weight: 600;
    padding: 0;
    line-height: 24px;
  }

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 4px 0;
  }
}
.findKids {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);
  font-family: 'Stardos Stencil', cursive;
  font-size: 36px;
  line-height: 60px;
  letter-spacing: 1px;
  margin: 0;
  /* composes: animation; */
  /* animation-delay: 0.5s; */

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }

  @media (max-width: 575px) {
    font-size: 21px;
    line-height: 28px;
    margin: 0 0 10px;
  }
}
.listingCount {
  color: var(--matterColorAnti);
  margin: 0 0 0 10px;
  @media (max-width: 767px) {
    /* display: block; */
    margin: 0 0 0 4px;
  }
}
.popdv {
  position: absolute;
  top: 0px;
  width: 100%;
  padding: 15px 14px;
  @media (max-width: 767px) {
    padding: 10px 14px;
  }
  & span:first-child {
    color: #9b9898;
    font-weight: 600;
    font-size: 15px;
  }
  & span:last-child {
    color: #000;
    font-weight: 400;
    font-size: 15px;
    padding-left: 10px;
  }
  &::after {
    content: '';
    height: 2px;
    background-color: #cecece;
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.countyOptns {
  & .cls {
    border: 0;
    padding: 0;
    cursor: pointer;
    position: absolute;

    right: 0px;
    top: 22px;
    min-width: unset;
    & svg {
      width: 15px !important;
      height: 15px !important;
    }
    @media (max-width: 767px) {
      top: 13px;
    }
  }
}
.activitySec,
.categorySec {
  @media (max-width: 575px) {
    padding-top: 25px;
    & .cls {
      top: 8px;
    }
  }
}
