.modaldv {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: calc(100vw - 200px);
  max-width: 1200px;
  @media (max-width: 575px) {
    width: 100%;
  }
}

.modaldv > div > img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin: 0 auto;
  border: 2px solid #002435;
  border-radius: 15px;
  object-fit: contain;
  background: #000;
}
.modaldv .imgContainer {
  display: flex;
  gap: 20px;
  padding: 0 15px 40px;
  position: relative;
}
.infiniteScroll {
  height: 65vh !important;
  /* width: 100vh; */
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-evenly;
  row-gap: 30px;
}
.gallp {
  color: #000;
  text-align: center;
  padding: 0 100px;
  line-height: 33px;
  font-size: 20px;
  @media (max-width: 991px) {
    padding: 0;
  }
  @media (max-width: 575px) {
    font-size: 17px;
    line-height: 23px;

    & > span:first-child {
      display: block;
      font-size: 18px !important;
    }
  }
}
.infiLoader {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 99;
  bottom: 0;
}
.endOfResMsg {
  color: #c93b54;
  margin-bottom: 0;
}
.imggl {
  height: 200px;
  width: 300px;
  position: relative;
  border-radius: 10px;
  border: 3px solid transparent;
  /* flex-basis: 30%; */
  @media (max-width: 991px) {
    width: 255px;
    height: 165px;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 360px;
    height: 220px;
  }
  &:hover {
    /* background: #002435; */
    background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  }
  & > span {
    /* display: none; */
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(243, 243, 243, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > img {
    width: 100%;
    border-radius: inherit;
    height: 100%;
    object-fit: cover;
    background-color: #fff;
  }
}
.gallp > span:first-child {
  font-weight: 700;
}
.gallp > span:last-child {
  color: #c93b54;
  font-weight: 600;
}
.errspn {
  color: red;
  font-size: 18px;
  font-weight: 700;
}
.pagination {
  display: flex;
  justify-content: center;
  & button {
    color: #c93b54;
    font-size: 23px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    &:hover {
      font-weight: 700;
    }
  }
}
.infiLoader {
}
