.dummyLogoutBtn {
  border: 0;
  padding: 0;
  cursor: pointer;
  margin: 0;
}
.headp {
  color: #c93b54;
  font-size: 34px;
  line-height: 54px;
  font-weight: 700;
  text-align: center;
  margin: 15px auto 0;
}
.secp {
  color: #979797;
  margin: 0px auto 25px;
  max-width: 60%;
  text-align: center;
  line-height: 20px;
}
.stepdv {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  & span {
    color: #c93b54;
    font-weight: 500;
    text-decoration: underline;
    font-size: 17px;
  }
  & p {
    color: #000;
    font-weight: 600;
    margin: 0;
    line-height: 25px;
    font-size: 18px;
    margin-bottom: 5px;
  }
}
.notespn {
  color: #979797;
  display: block;
  margin: 30px auto 20px;
  max-width: 60%;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}
.logoutbtn {
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  cursor: pointer;
  color: #fff;
  width: 200px;
  border: 0;
  margin: 0 auto;
  border-radius: 20px;
  display: block;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  &:hover {
    background: linear-gradient(270deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  }
}
.logoutModal {
  z-index: 99999;
}
.maindv {
  position: relative;
  background-color: #fff;
  border-radius: 25px;
  height: 100%;
  width: 100%;
  padding: 40px 0;
  & img {
    position: absolute;
    top: -70px;
    left: 37%;
    width: 130px;
    height: 130px;
  }
}
