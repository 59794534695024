.root {
  width: 100%;
  min-height: 120px;
  padding-left: 0;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.thumbnail {
  display: block;
  float: left;
  position: relative;
  width: 100%;
  margin: 0 0 10px;
  overflow: hidden;
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.threeToTwoWrapper {
  position: relative;
}

.removeImage {
  position: absolute;
  top: 0;
  right: 0;
  width: 33px;
  height: 33px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 0;
  border-bottom-left-radius: 2px;
  cursor: pointer;

  & svg {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 10px;
    height: 10px;
    fill: var(--matterColorAnti);
    stroke: var(--matterColorAnti);
  }

  &:hover svg {
    fill: var(--matterColorLight);
    stroke: var(--matterColorLight);
  }
}

.aspectWrapper {
  padding-bottom: calc(100% * (3 / 4));
  background: #000;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: var(--borderRadius);
}

.thumbnailLoading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(211, 211, 211, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .imgInp0 {
  margin-bottom: 10px;
}
.imgInp1 {
  display: inline-block;
  max-width: 49%;
  width: 100%;
}
.imgInp2 {
  display: inline-block;
  max-width: 49%;
  width: 100%;
  margin: 0 0 0 10px;
}
.imgInp3 {
  display: inline-block;
  max-width: 49%;
  width: 100%;
}
.imgInp4 {
  display: inline-block;
  max-width: 49%;
  width: 100%;
  margin: 0 0 0 10px;
}
.one{
  background-color: red;
  border: 20px solid black;
} */
.adders {
  display: inline-block;
  max-width: 49%;
  /* width: 100%; */
  /* margin: 0 0 0 10px; */
}
.adderCont {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0 0 0;
  & .adders {
    width: 49%;
    flex-basis: 49%;
    margin: 0px 0 10px 0;
  }
}
.thumbnail {
}
.addImg {
  flex-basis: 48%;
  max-width: 48%;
  margin: 5px 5px;
  @media (max-width: 991px) {
    flex-basis: 100%;
    max-width: 100%;
  }
  @media (max-width: 767px) {
    flex-basis: 48%;
    max-width: 48%;
  }
  @media (max-width: 575px) {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.small {
  flex-basis: 48%;
  max-width: 48%;
  margin: 5px 5px;
  @media (max-width: 991px) {
    flex-basis: 100%;
    max-width: 100%;
  }
  @media (max-width: 767px) {
    flex-basis: 48%;
    max-width: 48%;
  }
  @media (max-width: 575px) {
    flex-basis: 100%;
    max-width: 100%;
    margin: 5px 0px;
  }
}
.singleUpload {
  width: 100%;
}
