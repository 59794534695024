.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}
.map {
  width: 100% !important;
  height: 100% !important;
  position: relative;
  @media (max-width: 767px) {
    height: 50vh !important;
    position: relative;
  }
}
.anyClass {
  /* z-index: 9999; */
  position: relative;
  /* top: 600px; */
  width: 100% !important;
  height: 100% !important;
  /* margin-top: 20px; */
  @media (max-width: 767px) {
    height: 50vh !important;
  }
}
.anyClass h2 {
  text-align: center;
  color: #000;
}
.toughClass {
  position: relative;
  width: 100% !important;
  height: 100% !important;
  @media (max-width: 767px) {
    height: unset !important;
    top: unset !important;
    position: relative !important;
  }
}
.toughClass .locationText {
  top: 60px;
}
.showMap {
  position: relative;
  top: 400px;
  width: 100% !important;
  height: 100% !important;
  @media (max-width: 767px) {
    height: 50vh !important;
  }
}
.hidePin {
  display: none;
}
.mapPanel {
  overflow: hidden;
  height: 100%;

  & * {
    overflow: hidden;
  }

  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }

  & .modalScrollLayer {
    background-color: var(--matterColorLight);
    -webkit-overflow-scrolling: touch;
    height: 100%;
  }

  & .modalContainer {
    height: inherit;
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);

    & > div:last-child {
      height: 100%;
    }
    & > button:first-child {
      background-color: rgb(255, 255, 255);
      top: 10px;
      right: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px;
      border-radius: 8px;

      & span {
        font-size: 14px;
        color: var(--marketplaceColor);
      }
      & svg {
        fill: var(--marketplaceColor);
      }
    }
  }
}
.progressBarDiv {
  z-index: 99;
}
.buttonDiv {
  display: flex;
  align-items: flex-end;
  padding-bottom: 25px;
}
.backButton {
  display: inline-flex;
  align-items: flex-end;
  font-weight: 900;
  font-size: 16px;
  text-decoration: underline;
  color: #676767 !important;
  background: none !important;
  text-align: left;
  max-width: 50px;
  &:hover {
    box-shadow: none;
  }
}

.submitButton {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  border-radius: 8px;
  min-height: auto;
  padding: 10px 15px;
  max-width: 150px;
  margin: 0 0 0 auto;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}
.garageAddress {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  display: inline-block !important;
  border: 1px solid #c4385a;
  max-width: 50%;

  @media (max-width: 1199px) {
    max-width: 100%;
  }

  & img {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    margin-right: 0.5rem;
    display: none;
  }

  & span {
    line-height: 1.25rem;
    color: #191528;
    font-size: 18px;
  }
}
.formlistbtmsec {
  margin: auto 0 0;
  @media (max-width: 767px) {
    margin: 0;
    padding: 0 15px;
    /* position: sticky; */
    bottom: 0;
  }
}
.svexbtnsec {
  display: flex;
  align-items: center;

  padding: 0 15px;
  @media (max-width: 767px) {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.mapButton {
  margin-top: 0.5rem;
  color: var(--marketplaceColor);
  text-align: start;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border: 0;
  outline: none;
  margin-left: -0.5rem;

  & span {
    margin-left: 0.25rem;
    line-height: 1.25rem;
  }
}
.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

/* .county div {
  border-radius: 30px;
  background-color: #191528;
} */
.exactLocationCheck {
  /* background-color: #191528; */
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
}
.chooseLocationLabel {
  padding-top: 0px;
}
.chooseLocation {
  width: auto;
  margin: 0 0 0 10px;
  /* top: 50px; */
}
.firstDiv {
  border: 2px solid gray;
  margin: 0 0 0 0;
  padding: 0 0 0 15px;
  & h3 {
    color: red;
    margin-bottom: 2px;
  }
  & span {
    margin-bottom: 10px;
  }
}
.mapContainer {
  width: 100%;
  height: 100%;
}
.county {
  width: 100%;
  height: 600px;
  position: relative;
}
.cafd {
  background-color: #fff !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin: 0 auto 15px;
  max-width: 75%;
  border: 1px solid #aeaeaeed;
  border-radius: 10px;
  justify-content: center;
  color: #000;
  font-size: 18px;

  @media (max-width: 1199px) {
    max-width: 100%;
  }
  @media (max-width: 767px) {
    max-width: 60%;
  }
  @media (max-width: 575px) {
    max-width: 100%;
  }
  & input {
    border: none;
    margin: 0 0 0 10px;
  }
}
.searchResultContainer {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  margin-bottom: 16px;
  padding: 0.75rem;
  border-radius: 8px;
  box-shadow: var(--boxShadowBlur2);

  & img {
    width: 28px;
    height: 28px;
    margin-right: 0.5rem;
  }

  & > span:last-child {
    display: flex;
    flex-direction: column;

    & > span:first-child {
      font-size: 15px;
      color: #191528;
      line-height: 1.25rem;
    }

    & > span:last-child {
      margin-top: 4px;
      font-size: 13px;
      color: #666;
      line-height: 1.125rem;
      font-weight: 400;
    }
  }
}
.casd {
  background-color: #fff !important;
  padding: 10px;
  margin: 0 auto;
  text-decoration: underline;
  border: 1px solid #c4385a;
  max-width: 60%;
  color: #000;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  @media (max-width: 1199px) {
    max-width: 80%;
  }
  @media (max-width: 767px) {
    max-width: 40%;
  }
  @media (max-width: 575px) {
    max-width: 80%;
  }
}
.countyOption {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media (min-width: 476px) {
    max-height: 550px;
  }
  & > div {
    /* flex-basis: 25%; */
    min-width: 164px;
    display: flex;
    flex-direction: row;
    align-items: center;
    & label {
      white-space: nowrap;
      flex-grow: 1;
    }
    & input {
      max-width: 40px;
    }
  }
}
.secondDiv {
  border: 2px solid gray;
  padding: 0 0 0 15px;
  margin: 0 0 20px 0;
}
.addressPresent {
  display: none;
}

.searchResults {
  width: 400px;
  height: 240px;
  overflow-y: auto;
  background: white;
}
.locationSearchContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;

  & .heading {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1.25rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    & img {
      width: 24px;
      height: 24px;
    }
    & h3 {
      flex-grow: 1;
      text-align: center;
      color: #191528;
      font-size: 16px;
      /* margin-right: 24px; */
    }

    & button {
      font-size: 24px;
      color: rgb(189, 189, 189);
      background-color: transparent;
      border: 0;
      padding: 0px 6px;
      opacity: 1;
    }

    & button:disabled {
      opacity: 0;
    }
  }

  & .content {
    padding-bottom: 0px;
    margin: 68px auto 0px;
    flex-grow: 1;
    width: 100%;
    padding: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;

    & .locationSearchForm {
      display: flex;
      flex-direction: column;
      background-color: var(--matterColorBright);
      padding: 8px 16px;

      & .inputContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-grow: 1;
        align-items: center;
        padding: 0.75rem 1rem;
        border-radius: 8px;
        color: #191528;
        margin: 0.5rem 0rem;
        background-color: #fff;
        box-shadow: var(--boxShadowBlur2);

        & .input {
          flex-grow: 1;
          margin: 0rem 0.5rem;

          & input {
            border: 0;
          }
        }

        & img {
          width: 28px;
          height: 28px;
        }

        & button {
          font-size: 1.25rem;
          background-color: var(--matterColorTransBlue);
          border: 0;
          border-radius: 4px;
          color: #5a41a6;
        }
        & button:disabled {
          opacity: 0;
        }
      }

      & .focusedInputContainer {
        border-color: #5a41a6 !important;
      }

      & .minCharSearchMsgContainer {
        text-align: center;
        margin-top: 8px;
        font-weight: 500;
        line-height: 1.125rem;
        color: var(--matterColorBlack);
      }
      & .charSearchStatus {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 8px;
        color: var(--matterColorBlack);

        & .searchResultContainer {
          display: flex;
          flex-direction: row;
          background-color: #fff;
          margin-bottom: 16px;
          padding: 0.75rem;
          border-radius: 8px;
          box-shadow: var(--boxShadowBlur2);

          & img {
            width: 28px;
            height: 28px;
            margin-right: 0.5rem;
          }

          & > span:last-child {
            display: flex;
            flex-direction: column;

            & > span:first-child {
              font-size: 15px;
              color: #191528;
              line-height: 1.25rem;
            }

            & > span:last-child {
              margin-top: 4px;
              font-size: 13px;
              color: #666;
              line-height: 1.125rem;
              font-weight: 400;
            }
          }
        }
      }
    }

    & .hideForm {
      display: none !important;
    }

    & .mapContainer {
      display: none;
      flex-direction: column;
      height: 100%;

      & .map {
        width: 100%;
        height: 100%;
      }

      & .addressContainer {
        position: fixed;
        top: 78px;
        left: 54px;
        width: calc(100vw - 68px);
        z-index: 20;
        background-color: #fff;
        padding: 0.625rem;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        border-radius: 8px;

        & img {
          width: 30px;
          height: 30px;
          flex-shrink: 0;
          margin-right: 0.5rem;
        }

        & span {
          line-height: 1.125rem;
          font-size: 13px;
          color: #191528;
          align-self: center;
        }
      }
      & .addressConfirmBtn {
        position: fixed;
        bottom: 16px;
        width: 90vw;
        left: 50%;
        transform: translateX(-50%);
        max-width: 360px;
        z-index: 2;
        font-weight: 500;
      }
    }
    & .showMapContainer {
      display: flex;
    }
  }
}
.exitButton {
  border-radius: 50px;
  font-weight: 500;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 7px 20px;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  line-height: 18px;
  margin: 0 0 0 auto;
  min-height: 42px;
  width: 115px;
  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  }
  &:disabled {
    cursor: not-allowed;
  }
}
.formseclist {
  height: calc(100vh - 140px);
  overflow-y: auto;
  margin: 20px 0 30px;
  padding: 0 15px 0 0;
  position: relative;
  @media (max-width: 767px) {
    padding: 0;
    margin: 20px 30px 20px;
    height: unset;
    overflow-y: unset;
    max-height: unset;
  }
  @media (max-width: 375px) {
    padding: 0;
    margin: 0 0px 20px;
  }
}
.formlistbtmsec {
  margin: auto 0 0;
  @media (max-width: 767px) {
    margin: 0;
    padding: 0 15px;
    /* position: sticky; */
    bottom: 0;
  }
}
.winputpin {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;

  /* @media (max-width: 1199px) {
    top: calc(100vh - 28vh);
  }
  @media (max-width: 767px) {
    top: 0;
  } */
}

.winputpin h2 {
  color: #232323;
  margin-top: 0;
  font-size: 28px;
  font-weight: 800;
  @media (max-width: 575px) {
    font-size: 20px;
  }
}
.locationinputmodal {
  background: #fff;
  /* z-index: 99999; */
  width: 100%;
  margin: 0 auto;
  /* height: calc(100vh - 140px); */
  top: 0;
  /* overflow-y: auto; */
  /* padding: 70px 60px 15px; */
  @media (max-width: 991px) {
    padding: 70px 15px;
  }
  @media (max-width: 767px) {
    padding: 15px;
    height: auto;
    position: unset;
  }
  @media (max-width: 575px) {
    padding: 15px 0;
  }
}
.locationinputmodal input {
  border: 1px solid #cecece !important;
  color: #000;
  min-height: 60px;
  border-radius: 6px;
  margin: 0 0 8px;
  padding: 0 25px;
  font-size: 16px;
  text-transform: capitalize;
}
.locationinputmodal label {
  display: none;
}
.locationinputmodal input::-moz-placeholder,
.locationinputmodal input::-webkit-placeholder {
  color: #000;
  opacity: 0.6;
}
.inlineinputeri {
  display: flex;
  justify-content: space-between;
}
.inlineinputeri .mfb {
  flex-basis: 49%;
}
.wpact {
  display: block;
}
.innermapvalue {
  width: 100%;
  height: 300px;
  margin: 20px auto 0;
  @media (max-width: 1199px) {
    width: 100%;
  }
}
.mainoutrow label {
  color: #c93b54;
  text-align: center;
  font-size: 18px;
  margin: 0 0 20px;
  align-items: center;
}
.mainoutrow label span {
  font-size: 14px;
  color: #000;
  display: block;
  margin: 10px 0 0 20px;
}
.chkbtngrp {
  /* background: #f7f7f7; */
  padding: 15px;
}
.bckgrn {
  background: #f7f7f7;
}
.frmchkwctds {
  flex-basis: 33%;
  max-width: 33%;
  margin-bottom: 15px;
}
.frmchkwctds label {
  font-size: 14px;
  color: #000;
  margin: 0;
  text-align: left;
  padding: 0;
  display: flex;
  align-items: center;
  & svg {
    height: 23px;
    width: 23px;
    margin-right: 10px;
  }
}
.frmchkwctds input {
  display: none;
}
.mainoutrowmod {
  justify-content: flex-start;
  padding: 15px 15px;
}
.bpcbx input {
  display: none;
}
.bpcbx label {
  display: flex;
  background: #f7f7f7;
  padding: 15px;
}
.mycls {
  width: 100%;
}
.pdg {
  padding: 20px;
}
.isplbl {
  margin-bottom: 20px;
  & > label {
    margin-bottom: 0 !important;
  }
}
.zindx {
  position: relative;
  z-index: 10;
}
.locationText {
  position: absolute;
  top: 10px;
  z-index: 9;
  background: #fff;
  width: calc(100% - 10px);
  left: 5px;
  padding: 10px;
  border: 2px solid #002435;
  border-radius: 20px;
  & input {
    border-bottom: 0;
    padding: 0;
    border-radius: 30px;
    color: #000;
    padding-left: 3px;
  }
}
.locationAutocompleteInputIcon {
  display: flex;
  margin-right: 10px;
}
.predictionsRoot {
  top: 80px;
  padding-bottom: 25px;
  & > div:last-child {
    bottom: 10px;
  }
}
.validLocation {
}
.mobilePredictionsAttribution {
}
.customControl {
  border-color: #cecece !important;
  box-shadow: none !important;
  padding-left: 15px;
}
.select input {
  border: none !important;
  padding: initial;
  border-radius: initial;
  margin-top: initial;
  min-height: 46px;
}
.customMenu {
  background: #002435 !important;
  z-index: 10 !important;
  & > div:first-child {
    padding-left: 10px;
    padding-right: 10px;
    min-height: 250px;
  }
}
.customOption {
  padding-left: 25px;
  background: #002435 !important;
  color: #fff !important;
  font-weight: 600;
  cursor: pointer;
  border-radius: 30px;
  &:hover {
    background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
    color: #fff !important;
  }
}
.bggmaps {
  position: absolute;
  width: calc(100% - 30px);
  height: 100%;
  min-height: 760px;
  z-index: 0;
  @media (max-width: 575px) {
    height: 1280px;
  }
}
.selchkd {
  color: #c93b54 !important;
}
.pinningTextPresentAgainstFiled {
  @media (max-width: 767px) {
    top: 54px;
  }
}
