.leftSection {
  flex-basis: 50%;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  position: relative;

  @media (max-width: 767px) {
    flex-basis: auto;
    width: 100%;
    min-height: auto;
  }
}
.lstlogo {
  position: absolute;
  top: 20px;
  left: 30px;
  z-index: 9;
  @media (max-width: 767px) {
    left: 20px;
  }
  @media (max-width: 575px) {
    top: 10px;
    height: 50px;
  }
}

.leftSection p {
  position: absolute;
  bottom: 95px;
  left: 30px;
  color: var(--matterColorLight);
  font-family: 'Stardos Stencil', cursive;
  font-size: 52px;
  line-height: 50px;
  letter-spacing: 1px;
  font-weight: 500;
  max-width: 70%;
  text-shadow: 5px 3px 4px rgba(0, 0, 0, 0.8);
  @media (max-width: 991px) {
    font-size: 52px;
    line-height: 50px;
  }
  @media (max-width: 767px) {
    bottom: 100px;
    font-size: 42px;
  }
  @media (max-width: 575px) {
    max-width: 100%;
    left: 0;
    padding: 0 15px;
    bottom: 50px;
    font-size: 36px;
    font-size: 38px;
    line-height: 42px;
  }
  @media (max-width: 375px) {
    font-size: 30px;
    line-height: 40px;
  }
}
.imgSection {
  width: 100%;
  object-fit: cover;
  height: 100vh;
  /* object-position: 0% 50%; */
  object-position: top right;
  @media (max-width: 767px) {
    height: auto;
    object-position: center;
    display: none;
  }
}
.imgSectionmb {
  display: none;
  @media (max-width: 767px) {
    display: block;
    width: 100%;
  }
}
.screenicon {
  height: 110px;
  position: relative;
  @media (max-width: 575px) {
    height: 80px;
  }
}
.bgColorscreen {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bgColorscreen .imgSection {
  position: absolute;
}
.locdv p {
  text-align: left;
}
.bgColorscreen p {
  bottom: auto;
  text-align: center;
  text-shadow: none;
  position: relative;
  display: block;
  max-width: 68%;
  left: 0;
  margin-bottom: 30px;
  @media (max-width: 991px) {
    font-size: 36px;
  }
  @media (max-width: 767px) {
    font-size: 32px;
    max-width: 100%;
    padding: 0;
  }
  @media (max-width: 575px) {
    font-size: 28px;
    margin-bottom: 10px;
    line-height: 40px;
  }
}
.midcontain {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  left: -40px;
  flex-basis: 100%;

  @media (max-width: 767px) {
    left: 0;
    position: absolute;
    width: 100%;
    padding: 0 20px;
  }
}
.bgColorscreen .imgSectionmb {
  @media (max-width: 767px) {
    min-height: 480px;
  }
}
.localign {
  text-align: left !important;
  max-width: 70% !important;
}
.pubtxt {
  color: #fff;
  font-size: 16px;
  text-align: center;
  max-width: 57%;
  margin: 0 0 30px;
  @media (max-width: 991px) {
    font-size: 13px;
    max-width: 80%;
    margin: 0 0 20px;
    padding: 0 15px;
    line-height: 18px;
  }
  @media (max-width: 575px) {
    max-width: 100%;
    padding: 0;
  }
}
.pubtxt {
  & span:first-child {
    margin-bottom: 20px !important;
    display: block;
    font-size: 14px;
    line-height: 23px;
  }
  & span {
    font-size: 14px;
    line-height: 23px;
  }
}
.fromProvCates {
  font-family: unset !important;
  font-size: 16px !important;
  bottom: 35px !important;
  line-height: 25px !important;
  text-shadow: none !important;
  max-width: 80% !important;
  margin-top: 0;
  font-weight: 400 !important;
}
.fromPublishSuccess {
  background-color: #002435 !important;
}
