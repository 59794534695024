@import '../../styles/propertySets.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  /* height: 100vh; */
  margin: auto 15px;
  height: unset;
  max-height: 80vh;
  @media (min-width: 1024px) {
    margin: auto;
    padding: 0 !important;
  }
  @media (--viewportMedium) {
    padding: 0 20px 20px;
  }
  /* @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  } */
  background-color: #fff;

  @media (--viewportLarge) {
    /* padding: 0;
    background-color: #fff;
    margin: auto; */
    /* height: auto; */
  }
}
.modalContainer > button {
  position: relative;
  margin-left: auto;
}
.hideCross > button {
  /* display: none; */
}
.modalContainer > div {
  @media (max-width: 767px) {
    /* margin-top: 60px !important; */
    /* padding-top: 60px; */
    overflow: auto;
    /* min-height: calc(100% - 300px); */
    /* max-height: calc(100% - 235px); */
    padding: 20px 15px;
  }
  @media (max-width: 575px) {
    /* margin-top: 0px !important; */
    padding: 20px 15px;
  }
}
.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 30px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
    margin-left: 6px;
  }
}

.bookingTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 2px;
}

.bookingHelp {
  display: none;
  @apply --marketplaceH5FontStyles;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  @media (max-width: 1023px) {
    padding: 20px 15px;
  }
  @media (max-width: 575px) {
    padding: 0 0;
  }
  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  /* padding: 0 24px 24px 24px; */
  width: 100%;
  /* position: fixed; */
  bottom: 0;
  background-color: var(--matterColorLight);
  @media (max-width: 767px) {
    /* margin-left: -15px; */
  }

  @media (min-width: 768px) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
    padding-bottom: 20px;
  }
}
.submitButtonWrapper button {
  color: #fff;
  font-size: 15px;
  padding: 12px 20px;
  margin-top: 0 !important;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  font-size: 18px;
  font-weight: 400;
  &:disabled {
    opacity: 0.7;
  }
  @media (max-width: 767px) {
    min-height: 50px;
    font-size: 18px;
  }
}
.submitButtonWrapper svg {
  height: 25px;
  width: 20px;
  margin-right: 10px;
}
.submitButtonWrapper button:disabled {
  opacity: 0.6;
}
.submitButtonWrapper button:hover {
  background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
}
.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 99;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;
  background: #002435;
  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (max-width: 991px) {
    padding: 18px 25px;
  }

  /* @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  } */

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
  line-height: 23px;
}

.priceValue {
  /* Font */
  font-size: 19px;
  line-height: 24px;
  color: #fff;

  margin-top: 0;
  margin-bottom: 0px;
  & span {
    margin-left: 6px;
    font-weight: 300;
  }
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  font-size: 16px;
  color: #fff;

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookButton {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  border-radius: 8px;
  min-height: auto;
  padding: 5px 15px;
  cursor: pointer;
  border: 0;
  color: #fff;
  margin: auto 20px auto auto;
  display: block;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}
.BookingPanelmodified {
  position: sticky;
  top: 148px;
  overflow-y: auto;
  border-radius: 10px;
  z-index: 1;
}
.noUser {
  color: #000;
  margin-top: 30px 20px;
}
.noUser p {
  text-align: center;
}
.noUser p span a {
  color: #c93b54 !important;
  font-weight: 700;
}
.noUser p > span:last-child {
  font-weight: 700;
}
.avimg {
  position: absolute;
  height: 110px;
  left: 0;
  right: 0;
  display: table;
  margin: 0 auto;
  top: -55px;
}
.createListingButton {
  display: block;
  border-radius: 6px;
  font-weight: 600;
  margin: 0 0 20px auto;
  border: 0px;
  color: #fff;
  font-size: 15px;
  padding: 5px 20px;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  display: block;
  cursor: pointer;
  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  }
  &:disabled {
    opacity: 0.7;
  }
}
.cntspn {
  justify-content: center;
  min-height: 45px;
  font-size: 22px;
  color: #fff;
  background: #002435;
  display: flex;
  align-items: center;
}
.offplt {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 300px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 1px 1px 8px rgb(0 0 0 / 20%);
  overflow-y: auto;
  max-height: 560px;
  position: sticky;
  z-index: 99;
  @media (max-width: 991px) {
    box-shadow: none;
    border-radius: 0;
    max-width: 480px;
    margin: auto;
  }
}
.offplt p {
  color: #c93b54;
}
.ordv {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  & p {
    text-align: center;
    margin: 15px 0;
    position: relative;
  }
  & span {
    flex-basis: calc(100% - 30px);
    height: 2px;
    background: #cecece;
  }
}
.contprov {
  width: 100%;
}
.iebdvs {
  margin-top: 40px !important;
}
.bdvs {
  width: 80%;
  margin: 20px auto 10px;
  & p {
    margin: 0;

    font-size: 22px;
    font-weight: 600;
  }
  & span {
    display: block;
    color: #000;
    line-height: 23px;
  }
}
.extlnk {
  display: flex;
  justify-content: center;
}

.bookdet {
  @media (max-width: 1024px) {
    padding: 0px 15px 0;
  }
  @media (max-width: 575px) {
    padding: 0px 15px 0;
  }
  & h4 {
    text-align: center;
    margin: 0;
    font-size: 28px;
    font-weight: 600;
    color: #c93b54;
    font-family: 'Open Sans', sans-serif;
  }
  & h6 {
    line-height: 22px;
    margin-top: 15px;
    margin-bottom: 6px;
    font-weight: 600;
    text-align: center;
    font-size: 18px;
    color: #000;
    text-transform: none;
    letter-spacing: 0;
    & > span {
      text-decoration: none;
      color: #ed1c24;
    }
  }
  & p {
    font-size: 12px;
    line-height: 19px;
    margin-top: 9px;
    margin-bottom: 16px;
    text-align: center;
  }
  & span {
    text-decoration: underline;
  }
}
.modalContents {
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  /* border-radius: 30px; */
  padding: 3px;
}
.modalContainers {
  border: 0;
  margin: auto;
  position: relative;

  & > button:first-child {
    position: absolute;

    & > span:first-child {
      display: none;
    }
  }
}
.contactProviderModal {
  /* border-radius: 30px; */
  padding: 60px 30px 20px;
  background-color: #fff;
  border: none;
}

.avimg {
  position: absolute;
  height: 110px;
  left: 0;
  right: 0;
  display: table;
  margin: 0 auto;
  top: -55px;
  z-index: 9;
}
.formdiv {
  margin-left: auto;
  margin-right: auto;
}
.formdiv p {
  color: #c93b54;
  font-size: 32px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
}
.formdiv label {
  font-size: 18px;
  & span {
    color: #c93b54;
  }
}
.formdiv textarea {
  border: 2px solid #cecece;
  margin-top: 5px;
  min-height: 150px;
  font-size: 17px;
  padding: 5px 15px;
  box-sizing: border-box;
}
.rowed {
  display: flex;
  justify-content: space-between;
  @media (max-width: 575px) {
    flex-direction: column;
  }
}
.rowed > div {
  flex-basis: 45%;
  max-width: 45%;
  margin: 10px 0;
  @media (max-width: 575px) {
    flex-basis: 100%;
    max-width: 100%;
  }
  & input {
    border: 2px solid #cecece;
    margin-top: 5px;
    min-height: 40px;
    font-size: 17px;
    padding: 5px 15px;
  }
}
.emaildv {
  margin: 20px 0 30px;
  & input {
    border: 2px solid #cecece;
    margin-top: 5px;
    min-height: 40px;
    font-size: 17px;
    padding: 5px 15px;
  }
}
.contactProviderInfo {
  color: #000 !important;
  font-size: 16px !important;
  max-width: 576px;
  line-height: 26px;
  margin-top: 20px !important;
  text-align: left !important;
}
.formdiv > div:nth-child(4) {
  margin-top: 10px;
}
.enqBtn {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%);
  border-radius: 8px;
  min-height: auto;
  padding: 10px 15px;
  width: 100%;
  cursor: pointer;
  border: 0;
  color: #fff;
  margin: 30px auto 20px;
  display: block;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}
.err {
  color: red;
  font-weight: 700;
}
.redtomodal p:first-child {
  font-size: 24px;
  color: #000;
  text-align: center;
}
.redtomodal p:nth-child(2) {
  max-width: 60%;
  margin: 0 auto 20px;
  text-align: center;
  line-height: 20px;
  font-size: 15px;
  @media (max-width: 575px) {
    width: 100%;
    max-width: unset;
  }
}
.redtomodal > div {
  /* padding-bottom: 10px; */
  border-bottom: 1px solid #cecece;
}
.redtomodal {
  font-size: 13px;
  text-align: center;
  display: block;
  color: #727272;
}
.jwlform > p:first-child {
  font-size: 22px;
  line-height: 34px;
  text-align: center;
}
.jwlmsg > p:first-child,
.jwlmsg > p:nth-child(2) {
  margin: 0 0 15px;
}
.titlep {
  color: #c93b54;
  font-size: 16px;
  margin: 15px 0 0px;
  line-height: 30px;
  text-align: center;
  margin-right: 15px;
}
.listingatts {
  color: #c93b54;
  font-size: 16px;
  & > span:first-child {
    margin: 0 15px;
  }
  & > span:last-child {
    margin-left: 15px;
  }
  & span {
    margin: 0 15px;
  }
}
.jwluserdet {
  margin: 30px 0 0;
}
.jwluserdet > div {
  display: flex;
  align-items: center;

  & label {
    margin: 0 8px 0 0;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
  }
  & input {
    border: 0;
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #000 !important;
  }
  & span {
    font-size: 14px;
    color: #000 !important;
  }
}
.jwluserdet p {
  margin: 0;
}
.jwluserdet > * {
  max-height: 22px;
  display: flex;
  align-items: center;
  font-size: 14px !important;
}
.jwlseats {
  width: 50px;
  display: inline-block;
  border: 2px solid #cecece;
  border-radius: 5px;
  margin: 0 7px;
  text-align: center;
}
.jwlseats:hover,
.jwlseats:focus {
  border: 2px solid #cecece;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.bokmsg {
  margin: 10px 0 15px;
  color: #818181 !important;
  font-weight: 700;
}
.contactProviderModal {
}
.tnxyp {
  color: #000;
  font-weight: 600;
  & p {
    margin: 0;
    line-height: 26px;
    color: rgb(74, 74, 74);
  }
}
.isSuccessBtn {
  background: var(--successColor) !important;
}
.formDiv {
  max-height: calc(100vh - 230px);
  overflow: auto;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 1023px) {
    min-height: 70vh;
    max-height: calc(var(--vh, 1vh) * 100 - 120px); /* Modern browsers */
    /* max-height: calc(100vh - 120px); Fallback for older browsers */
  }
}
.modalContainerForBookingPanel {
  padding-right: 5px;
  padding-left: 5px;
}
