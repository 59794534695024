@import '../../styles/propertySets.css';
.howItWorks {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-family: 'Stardos Stencil', cursive;
  text-align: center;
  font-size: 45px;
  color: #232323;
  line-height: 60px;
  font-weight: 500;
  margin: 30px auto 60px;
  @media (max-width: 767px) {
    font-size: 35px;
    line-height: 40px;
  }
}

.steps {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1128px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}
.step12 {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  position: relative;
  @media (max-width: 575px) {
    flex-direction: column;
  }
}
.step1 {
  padding: 30px;
  flex-basis: 45%;
  margin: 0 84px 130px 0;
  position: relative;
  border: 2px solid rgba(201, 59, 84, 1);
  border-radius: 30px;
  @media (max-width: 575px) {
    margin: 0 0 50px;
  }
}
.step1:after {
  background: url(../../assets/aftarrow.png) no-repeat;
  position: absolute;
  top: auto;
  width: 80px;
  height: 160px;
  right: -90px;
  bottom: 0;
  content: '';
  @media (max-width: 575px) {
    display: none;
  }
}
.step1 p {
  color: #232323;
  font-size: 20px;
  font-weight: 500;
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 26px;
  }
}
.step2 {
  position: relative;
  top: 110px;
  margin-right: 0;
  @media (max-width: 575px) {
    top: 0;
  }
}

.step4 {
  top: 180px;
  @media (max-width: 575px) {
    top: 0;
  }
}
.step2:after {
  left: -90px;
  right: auto;
  transform: scaleX(-1);
  bottom: -90px;
}
.step3:after {
  bottom: -40px;
}
.step4::after {
  display: none;
}

.stepCount {
  padding: 6px 30px;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  color: var(--matterColorLight);
  font-family: 'Stardos Stencil', cursive;
  font-size: 20px;
  display: inline-flex;
}
/* .step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
} */

.stepTitle {
  margin-top: 21px;
  margin-bottom: 18px;
  color: #232323;
  font-size: 28px;
  font-weight: 800;
  line-height: 40px;
  @media (max-width: 991px) {
    font-size: 20px;
    line-height: 32px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}
.myarrow2 {
  position: absolute;
  /* margin: 100px 0 0 0; */
  /* top: 0;
  bottom: 0; */
  transform: rotate(180deg);
}
.myarrow {
  /* top: 0;
  bottom: 0; */
  /* transform: rotate(90deg); */
}
.line {
  border-right: 0.2rem dashed rgba(201, 59, 84, 1);
  display: inline-block;
  height: 5rem;
}
.arrow_right {
  position: absolute;
  top: -0.3rem;
  bottom: 0;
  height: 1rem;
  border-right: 0.2rem solid rgba(201, 59, 84, 1);
  display: inline-block;

  left: 0.3rem;
  transform: rotate(-45deg);
}

.arrow_left {
  position: absolute;
  top: -0.3rem;
  bottom: 0;
  height: 1rem;
  border-right: 0.2rem solid rgba(201, 59, 84, 1);
  display: inline-block;
  right: 0.3rem;
  transform: rotate(45deg);
}
