@import '../../styles/propertySets.css';

.root {
  position: relative;
  /* border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative); */

  width: 100%;
  /* padding: 24px 24px 42px 24px; */
  background-color: var(--matterColorBright);

  @media (--viewportMedium) {
    /* padding: 0 24px 33px 24px; */
    padding: 0 0px 0px 0px;
  }

  @media (--viewportLarge) {
    /* padding: 0 0 81px 0; */
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 41px;
  }
  background-color: #002435;
  color: var(--matterColorLight);
  @media (--viewportLarge) {
    padding-top: 25px;

    /* border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative); */
  }
}

.bottomDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media (max-width: 1199px) {
    padding: 0 20px;
  }
  @media (max-width: 767px) {
    padding: 0 15px;
  }
  @media (max-width: 575px) {
    flex-direction: column;
  }
}
.footerBottom {
  /* display: flex;
  justify-content: center; */
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 22px 0;
  @media (max-width: 767px) {
    padding: 20px 15px;
  }
}
.footerBottom span {
  font-size: 14px;
  letter-spacing: 1px;
}
.footerBottom a {
  padding: 0 15px;
  font-size: 14px;
  color: #fff;
  @media (max-width: 767px) {
    padding: 0 10px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top-color: var(--matterColorNegative);
  padding-top: 10px;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    padding-top: 60px;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    padding: 0;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;
  display: none;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  width: 50px;
  height: 50px;
  margin-right: 12px;
  text-align: center;
  border: 3px solid #fff;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    margin: 0 0 0 auto;
  }
  @media (max-width: 575px) {
    margin: 0 auto;
  }
}
.icon svg {
  height: 50px;
  width: 14px;
}
.icon svg path {
  fill: #fff !important;
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 18px;
  @media (min-width: 1024px) {
    padding: 0 0 0 0px;
    justify-content: space-evenly !important;
  }
  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-evenly;
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    padding: 15px;
  }
  @media (max-width: 575px) {
    padding: 0 15px;
    flex-direction: column;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  flex-basis: 220px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (max-width: 1199px) {
    flex-basis: 280px;
  }
  @media (max-width: 575px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    flex-basis: 250px;
  }

  @media (--viewportMedium) {
    display: block;
    order: 1;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    flex-basis: 240px;
    margin-right: 57px;
  }
  @media (--viewportLargeWithPaddings) {
    flex-basis: 250px;
    margin-right: 12px;
  }
}

.logoLink {
  display: block;
  margin-bottom: 14px;
}

.logo {
  object-fit: contain;
  object-position: left center;
}
.logo img {
  height: 200px;
  @media (max-width: 575px) {
    height: 150px;
  }
}
.organizationInfo {
}

.organizationDescription,
.organizationCopyright {
  display: flex;
  flex-direction: column;
  @apply --marketplaceTinyFontStyles;

  @media (max-width: 575px) {
    text-align: center;
  }
}
.organizationInfo span {
  font-size: 14px;
  margin: 5px 0;
}
.organizationCopyright {
  margin-top: 24px;
}

.copyrightLink {
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
  @media (max-width: 575px) {
    margin-right: auto;
    margin-left: auto;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 575px) {
    text-align: center;
  }
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.link {
  /* Font */
  @apply --marketplaceH5FontStyles;
  line-height: 20px;
  /* color: var(--matterColor); */
  color: var(--matterColorLight);
  font-size: 16px;
  transition: var(--transitionStyleButton);
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    /* color: var(--marketplaceColor); */
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
  @media (max-width: 575px) {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 5px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  align-items: flex-end;
  flex-basis: 45%;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
  @media (max-width: 575px) {
    margin: 0 0 30px;
  }
}
.extraLinks p {
  text-align: right;
  @media (max-width: 575px) {
    text-align: center;
  }
}
.extraLinks h1 {
  text-align: right;
  font-family: 'Stardos Stencil', cursive;
  font-size: 60px;
  font-weight: 500;
  margin: 40px 0 20px;

  @media (max-width: 991px) {
    font-size: 45px;
  }
  @media (max-width: 767px) {
    font-size: 34px;
  }
  @media (max-width: 575px) {
    text-align: center;
    font-size: 28px;
    margin: 28px 0 20px;
  }
}
.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.legalLink,
.privacy,
.terms {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  height: 60px;
  padding: 0 24px 18px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: var(--matterColorLight);
  display: none;

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
}

.privacy {
  margin-right: 24px;
}
.linkslabel {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
  text-decoration: underline;
  @media (max-width: 575px) {
    text-align: center;
  }
}
.dummyLogoutBtn {
  border: 0;
  padding: 0;
  cursor: pointer;
  margin: 0;
}
.headp {
  color: #c93b54;
  font-size: 34px;
  line-height: 54px;
  font-weight: 700;
  text-align: center;
  margin: 5px auto 0;
  @media (max-width: 575px) {
    font-size: 30px;
  }
}
.secp {
  color: #979797;
  margin: 0px auto 25px;
  max-width: 60%;
  text-align: center;
  line-height: 20px;
}
.stepdv {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  & span {
    color: #c93b54;
    font-weight: 500;
    text-decoration: underline;
    font-size: 17px;
  }
  & p {
    color: #000;
    font-weight: 600;
    margin: 0;
    line-height: 25px;
    font-size: 18px;
    margin-bottom: 5px;
  }
}
.notespn {
  color: #979797;
  display: block;
  margin: 30px auto 20px;
  max-width: 60%;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}
.logoutbtn {
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  cursor: pointer;
  color: #fff;
  width: 200px;
  border: 0;
  margin: 0 auto;
  border-radius: 20px;
  display: block;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  &:hover {
    background: linear-gradient(270deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  }
}
.logoutModal {
  z-index: 99999;
}
.maindv {
  position: relative;
  background-color: #fff;
  border-radius: 25px;
  height: 100%;
  width: 100%;
  padding: 40px 0;
  & img {
    position: absolute;
    top: -70px;
    left: 37%;
    width: 130px;
    height: 130px;
  }
}
.modalScrollLayer {
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  z-index: 100;

  /* scrollLayer is the .root for the Modal */
  @apply --marketplaceModalRootStyles;

  /* Add default background color to avoid bouncing scroll showing the
 page contents from behind the modal. */

  /* Additional styles for the modal window, dimming the background and positioning the modal */
  min-height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @media (--viewportMedium) {
    padding: 0;
    background-image: none;
  }
}
.modcont {
  flex-basis: 576px;
  max-width: 486px;
  min-height: auto;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  margin: auto;
  border-radius: 25px;
  & > div {
    background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
    border-radius: 25px;
    border: 3px solid transparent;
    display: flex;
  }
  @media (max-width: 767px) {
    & > button {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  @media (max-width: 575px) {
    max-width: 95%;
  }
}
