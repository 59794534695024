@import '../../styles/propertySets.css';

.root {
}

.verificiationBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 15px 32px 23px 32px;

  border-radius: 4px;
}

@media (--viewportMedium) {
  .verificiationBox {
    flex-wrap: nowrap;
  }
}

.verificiationBoxTextWrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 24px 3px 0px;
  margin-bottom: 16px;
}

@media (--viewportMedium) {
  .verificiationBoxTextWrapper {
    margin-bottom: 0px;
  }
}

.verificationBoxTitle {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);

  align-self: center;

  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.verificationBoxText {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightRegular);
  margin-top: 0;
  margin-bottom: 0;
}

.getVerifiedButton {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  /* background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  } */

  &:disabled {
    opacity: 0.7;
  }
  border-radius: 4px;
  min-height: 38px;
  width: 300px;
  max-width: 110px;
}

/* Verification required box */
.verficiationNeededBox {
  /* border: 2px solid var(--attentionColor); */
  background: var(--attentionColorLight);
}

/* Verification error box */
.verficiationErrorBox {
  background: var(--failColorLight);
  /* border: 2px solid var(--failColor); */
}

/* Verification success box */
.verficiationSuccessBox {
  background: var(--successColorLight);
  /* border: 2px solid #002435; */
  padding: 8px 24px;
}
.boxdecoy {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%);
  border: 2px solid transparent;
  border-radius: 4px;
  margin-bottom: 24px;
}
.verificationBoxSuccessTextWrapper {
  margin-bottom: 0;
}
.editVerificationButton {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  font-size: 16px;
  border-radius: 8px;
  min-height: 40px;
  padding: 3px 15px;
  color: #fff;
  cursor: pointer;
  border: none;
  width: 50%;
  font-weight: 600;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }

  &:hover,
  &:focus {
    outline: none;
    color: var(--matterColorDark);
  }
}

.icon {
  margin-right: 10px;
}

.iconEditPencil {
  stroke: var(--matterColor);
}

.spinner {
  width: 24px;
  height: 24px;
  stroke: var(--successColor);
  stroke-width: 3px;
}
