.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}
.descriptionContainer {
  margin: 0;
}
.descriptionContainer label {
  color: #232323;
  font-size: 20px;
  margin: 0 0 25px;
}

.descriptionContainer span {
  color: #808080;
  font-size: 14px;
  margin: 5px 0 0;
}
.descriptionContainer textarea,
.textdiv {
  min-height: 300px;
  line-height: 20px;
  border: 1px solid #cecece !important;
  padding: 20px;
  box-sizing: border-box;
  color: #000;
  border-radius: 8px;
  resize: none;
  @media (max-width: 991px) {
    min-height: 200px;
  }
}
.textdiv h2 {
  font-size: 18px;
  font-weight: 600;
  color: #232323;
  margin-top: 0;
}
.textdiv p {
  color: #808080;
}

.reactSelect {
  border-bottom: 2px solid #38394e;
}
.reactSelect > div {
  border-radius: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.reactSelect > div > div {
  padding: 0;
}
.error {
  color: var(--failColor);
}
.svexbtnsec {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.exitButton {
  border-radius: 50px;
  font-weight: 500;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 7px 20px;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  line-height: 18px;
  margin: 0 0 0 auto;
  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  }
  &:disabled{
    cursor: not-allowed;
  }
}
.backButton {
  text-decoration: underline;
  color: #676767 !important;
  background: none !important;
  text-align: left;
  max-width: 50px;
  &:hover {
    box-shadow: none;
  }
}

.submitButton {
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  border-radius: 8px;
  min-height: auto;
  padding: 10px 15px;
  max-width: 150px;
  margin: 0 0 0 auto;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
}
.buttonDiv {
  display: flex;
  align-items: center;
}
.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.title {
  composes: formMargins;
}
.reactSelect {
  composes: formMargins;
}
.description {
  /* composes: formMargins;
  flex-shrink: 0; */
  min-height: 150px;
  line-height: 20px;
  border: none;
  padding: 15px 20px;
  box-sizing: border-box;
}

.certificate {
  composes: formMargins;
}

.chooseAllcheck {
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
}
.chooseAllcheck label {
  /* width: auto; */
  padding-top: 3px;
}
.chooseAllcheck input {
  width: auto;
  margin: 0 0 0 10px;
}

.formlistbtmsec {
  margin: auto 0 0;
  @media (max-width: 767px) {
    margin: 0;
    padding: 0 15px;
    position: sticky;
    bottom: 0;
  }
}
.formseclist {
  min-height: calc(100vh - 210px);
  overflow-y: auto;
  margin: 20px 0 30px;
  padding: 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
}
