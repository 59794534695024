@import '../../styles/propertySets.css';

.root {
  margin-top: 24px;
  position: relative;
  @media (--viewportMedium) {
    margin-top: 32px;
  }
}
.categoryItems {
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.sectionContainer {
  padding: 0;
  /* margin-bottom: 36px; */

  @media (--viewportMedium) {
    padding: 0;
    /* margin-bottom: 56px; */
  }
}
.childCard {
  border: 1px solid black;
  border-radius: 10px;
  margin: 0 0 2px 0;
  display: flex;
  flex-direction: column;
  & span {
    & p {
      font-weight: 1000;
    }
  }
}
.errorMessage {
  margin-top: 6px;
  margin-bottom: 10px;
  color: var(--failColor);
  overflow: hidden;
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
}
.categoryList {
  display: flex;
  flex-direction: row;
  list-style-type: square;
}
.sectionTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;
  padding-top: 3px;
  padding-bottom: 3px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.lastSection {
  margin-bottom: 69px;

  @media (--viewportMedium) {
    margin-bottom: 111px;

    & .sectionTitle {
      margin-bottom: 16px;
    }
  }
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  /* margin-top: 18px; */
  margin-bottom: 18px;

  @media (--viewportMedium) {
    /* margin-top: 44px; */
    margin-bottom: 20px;
  }
}

.label {
  width: 180px !important;
  height: 180px !important;
  margin: 0 auto;
  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
}

.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: var(--matterColorBright);
  border-radius: 50%;
  cursor: pointer;

  @media (--viewportMedium) {
    /* width: 180px;
    height: 180px; */
    margin: 0 auto;
    border-radius: 50%;
  }
}
.profimglbl {
  width: 180px;
  height: 180px;
}
.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;

  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}
.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  width: 100%;
  height: 100%;
}

.changeAvatar {
  /* Font */
  @apply --marketplaceH5FontStyles;

  font-weight: var(--fontWeightMedium);

  /* Positioning: right */
  position: absolute;
  bottom: -10px;
  right: auto;
  /* Dimensions */
  width: 105px;
  height: 41px;
  padding: 11px 10px 7px 35px;

  /* Look and feel (buttonish) */
  background-color: var(--matterColorLight);
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 15px 12px;
  border: solid 1px var(--matterColorNegative);
  border-radius: 2px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    /* Position: under */
    bottom: -10px;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
    padding: 11px 10px 7px 35px;
  }

  &:hover {
    border: solid 1px var(--matterColorAnti);
  }
}

.uploadingImage {
  /* Dimensions */
  width: 180px;
  height: 180px;

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative); /* Loading BG color */
  border-radius: 50%;
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: 180px;
    height: 180px;
    border-radius: 50%;
  }
}
.modalAddButton {
  margin: 10px 0 0 auto;
  border: none;
  border-radius: 10px;
  background-color: lightskyblue;
  color: black;
}
.addChildButton {
  border: none;
  border-radius: 10px;
  background-color: lightskyblue;
  color: black;
}
.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.fileInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}
.contactContainer {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}
.firstName {
  width: calc(34% - 9px);
}

.lastName {
  width: calc(66% - 9px);
}

.contact {
  padding: 10px 0;
}
.bioInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

/* .submitButton {
  margin-top: 24px;
} */
.childCardFirstRow {
  display: flex;
  & button {
    margin: 0 0 0 auto;
    border: none;
    cursor: pointer;
  }
}
.editBtn {
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  color: #fff;
  border: none;
  margin: 5px 10px 5px auto;
  cursor: pointer;
  border-radius: 6px;
  width: 85px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -21px;
  right: 15px;
  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  }
}
.errorSpan {
  display: block;
  color: red;
  font-size: 11px;
  line-height: 19px;
  margin-left: 5px;
}
.ifError input {
  margin: 0;
}
.verif {
  display: block;
  font-size: 11px;
  line-height: 19px;
  margin-left: 5px;
  color: #000;
  & span {
    font-weight: 700;
  }
}
.verifLink {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 400 !important;
  &:hover {
    text-decoration: none;
  }
}
.modalDiv {
  color: #000;
  & span {
    font-weight: 700;
  }
  & p {
    line-height: 26px;
  }
}
.decoy {
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  padding: 2px;
  border-radius: 5px;
}
.formDiv {
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  position: relative;
  padding: 10px 10px 30px;
  flex-direction: column;
  & .ooo {
    display: flex;
    justify-content: space-between;
    @media (max-width: 575px) {
      flex-direction: column;
    }
  }
  & .ooo input {
    border: 1px solid #cecece !important;
    color: #000;
    min-height: 50px;
    border-radius: 6px;
    margin: 0 0 8px;
    padding: 0 25px;
    font-size: 16px;
    &:disabled {
      color: #6b6b6b;
    }
  }
  & .mfb input {
    border: 1px solid #cecece !important;
    color: #000;
    min-height: 50px;
    border-radius: 6px;
    margin: 0 0 8px;
    padding: 0 25px;
    font-size: 16px;
    &:disabled {
      color: #6b6b6b;
    }
  }
  & .mfbq {
    flex-basis: 49%;
  }
}
.formDiv input::-moz-placeholder,
.formDiv input::-webkit-placeholder {
  color: #000;
  opacity: 0.6;
}
.mainDiv {
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.scdv {
  flex-basis: 47%;
  max-width: 47%;
  box-shadow: 0px 0px 10px 4px #cecece;
  padding: 20px;
  border-radius: 5px;
  @media (max-width: 767px) {
    flex-basis: unset;
    max-width: unset;
    margin-bottom: 20px;
  }
  /* & > div:first-child{
    min-height: 248px;
  } */
}
.submitButton {
  position: absolute;
  border: none;
  background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
  &:hover {
    background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
  }
  padding: 10px 15px;
  max-width: 200px;
  border-radius: 8px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  right: 9px;
  bottom: 9px;
  &:disabled {
    opacity: 0.8;
  }
  @media (max-width: 767px) {
    right: 36px;
    bottom: 0px;
  }
  @media (max-width: 575px) {
    right: 36px;
    bottom: 0px;
  }
}
.fmlydiv {
  margin: 30px 0;
  & button {
    border: none;
    background: linear-gradient(90deg, #921b98 27%, #c93b54 72%) !important;
    &:hover {
      background: linear-gradient(90deg, #c93b54 27%, #921b98 72%) !important;
    }
    padding: 10px 15px;
    max-width: 151px;
    border-radius: 8px;
    max-height: 35px;
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    margin-top: 10px;
  }
  & > span:first-child {
    color: #c93b54;
    display: flex;
    width: 70%;
    border-bottom: 2px solid #cecece;
    margin: 10px auto;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
  }
  & label {
    display: flex;
    justify-content: space-between;
  }
}
.cspn {
  text-decoration: underline;
  font-size: 16px;
  margin-bottom: 3px;
  margin-left: 2px;
  color: #c93b54;
  font-weight: 700;
}
.xpsn {
  text-decoration: none !important;
  cursor: pointer;
}
.flnmdiv {
  display: flex;
  justify-content: space-between;
  & > div {
    max-width: 48%;
    flex-basis: 48%;
  }
  & input {
    /* max-width: 48%;
    flex-basis: 48%; */
    border: 1px solid #cecece;
    font-size: 15px;
    padding: 2px 16px 2px;
  }
}
.dob {
  border: none !important;
  border-bottom: 1px solid transparent !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-bottom: 2px !important;
  padding-top: 2px !important;
  & input {
    /* padding-top: 2px; */
    font-size: 15px;
  }
  &:hover {
    border-bottom: 1px solid #000 !important;
  }
}
.dob input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.bnsdate {
  position: relative;
  box-sizing: border-box;
  border-radius: var(--borderRadius8);
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  & div::before {
    border: none !important;
    border-bottom: none !important;
    &:hover {
      border-bottom: 0px !important;
    }
  }
  & div::after {
    border: none;
    border-bottom: none;
    &:hover {
      border-bottom: none !important;
    }
  }
  & div {
    &:hover {
      /* border-bottom: none; */
    }
  }
}
.mdnts {
  & span {
    background-color: #002435;
    width: 100%;
    display: flex;
    color: #fff;
    font-size: 15px;
    justify-content: center;
  }
  & textarea {
    border: 1px solid #cecece;
    resize: none;
    max-width: 100%;
    box-sizing: border-box;
    font-size: 15px;
    margin-bottom: 20px;
    min-height: 90px;
    padding: 2px 16px 2px;
  }
}
.cmgcntphn {
  border: 1px solid #cecece;
  max-width: 100%;
  margin-top: 5px;
  /* box-sizing: border-box; */
  font-size: 15px;
  margin-bottom: 20px;
  padding: 2px 16px 2px;
  & > input {
    border-bottom: 0 !important;
  }
}
.profpic {
  color: #c93b54;
  display: flex;
  width: 70%;
  border-bottom: 2px solid #cecece;
  margin: 10px auto;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
}
.removepp {
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  height: auto;
  line-height: 22px;
  &:hover {
    text-decoration: none;
  }
}
.customControl {
  box-shadow: unset;
  border-color: #cecece !important;
  min-height: 50px !important;
}
.customvalcont {
  padding: 0 25px !important;
  font-size: 15px;
}
.selmenu {
  margin-top: 0 !important;
  font-size: 14px;
}
.selOption {
  padding: 2px 25px;
}
.ctrldisabled {
  background-color: #fff !important;
}
.ctrlfocussed {
  border-color: #cecece !important;
  box-shadow: unset;
}
.nopp {
  visibility: hidden;
}
