@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}
.linkError {
  color: red;
}
.root input {
  border: 1px solid #cecece !important;
  padding: 5px 10px;
  border-radius: 6px !important;
  margin-top: 10px;
  min-height: 45px !important;
}
.county input {
  border: none !important;
  padding: initial;
  border-radius: initial;
  margin-top: initial;
  min-height: initial;
}
.county label {
  margin-bottom: 10px;
}
.county {
  margin-top: 24px;
}
.name {
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
}
.phnErrMsg {
  margin-top: 6px;
  margin-bottom: 10px;
  color: var(--failColor);
  overflow: hidden;
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
}
.phnWithErr {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}
.firstNameRoot {
  width: calc(50% - 9px);
}

.lastNameRoot {
  width: calc(50% - 9px);
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.termsText {
  @apply --marketplaceModalHelperText;
}

.termsLink {
  @apply --marketplaceModalHelperLink;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.infoBtnDiv {
  margin: 40px 0 0 0;
}
.infoBtn {
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  border: none;
  min-height: 42px;
  color: #fff !important;
  border-radius: 30px;
  padding: 6px 25px;
  text-align: center;
  width: 100%;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);
  }
}
.bycrt {
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 16px;
  & span {
    text-decoration: underline;
    cursor: pointer;
    color: #c93b54;
    &:hover {
      text-decoration: none;
    }
  }
}
.bname {
  margin-bottom: 24px;
}
.rphnin {
  background-color: transparent !important;
  border-bottom: 0 !important;
  border-right: 1px solid #cecece;
  margin: 7px 0;
}
.rphnin > div:first-child {
  background-color: transparent !important;
  background: transparent !important;
}
.rphnindd {
}
.customControl {
  border-color: #cecece !important;
  box-shadow: none !important;
  min-height: 45px !important;
}
.customOption {
  text-align: left;
}
.bnsEmailTitle {
  & > span {
    color: #b5b5b5;
    font-size: 13px;
    font-weight: 400;
    @media (max-width: 575px) {
      font-size: 12px;
    }
  }
}
